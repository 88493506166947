import API_SERVICE from '../../../service/api/index'

const VALIDATE_SECRET_EMAIL_URL = (params, onSuccess, onError) => {
	const apiDetail = API_SERVICE.API_URL.VALIDATE_SECRET_EMAIL_URL
	const apiUrl = `${apiDetail.API}/${params.token}`
	return API_SERVICE.GET({
	  apiUrl: apiUrl,
	  params : {},
	  onSuccess,
	  onError,
	})
  }
const RESET_PASSWORD = (params, onSuccess, onError) => {
	const apiDetail = API_SERVICE.API_URL.RESET_PASSWORD
	return API_SERVICE.POST({
	  apiUrl: apiDetail.API,
	  params : params,
	  onSuccess,
	  onError,
	})
  }

export default {VALIDATE_SECRET_EMAIL_URL, RESET_PASSWORD}
