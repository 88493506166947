<template>
  <div class="container mt-7 py-3 max-w-full">
    <hr class="mx-5" />
    <div class="w-full px-5 md:px-10 lg:px-20 text-sm md:text-lg">
      <div class="flex flex-col mt-3 md:mt-8 lg:flex-row">
        <div class="w-full p-5 lg:pr-20 lg:w-1/2">
          <img
            class="logo my-2 md:mt-7 md:mb-3"
            src="@assets/images/kudata_logotype.png"
            alt="logo"
          />
          <p class="font-extrabold text-xl mb-2">
            Platform Survey #1 untuk Mahasiswa Indonesia
          </p>
          <p class="subtitle text-base text-justify w-full md:w-3/4">
            Kudata hadir sejak 2021 sebagai platform survey terpusat bagi
            mahasiswa Indonesia
          </p>
        </div>
        <div class="footer-right w-full p-5 flex flex-col lg:w-1/4">
          <h1 class="text-2xl font-semibold my-2 md:my-7">Kudata</h1>
          <div
            class="mb-2 subtitle"
            tabindex="-1"
            @click="aboutDialogVisible = true"
          >
            Tentang Kami
          </div>
          <a class="mb-2 subtitle" :href="tncLink" target="_blank" tabindex="-1"
            >Syarat dan Ketentuan</a
          >
          <a class="mb-2 subtitle" href="#" tabindex="-1">Kebijakan Privasi</a>
        </div>
        <div class="w-full p-5 flex flex-col lg:w-1/4">
          <h1 class="text-2xl font-semibold my-2 md:my-7">Hubungi Kami</h1>
          <a href="mailto:support@kudata.id" tabindex="-1">
            <div class="w-full flex flex-row">
              <unicon name="envelope" fill="gray"></unicon>
              <p class="ml-3 mb-2 subtitle">support@kudata.id</p>
            </div>
          </a>
          <!-- <a href="#" tabindex="-1">
          <div class="w-full flex flex-row">
            <unicon name="phone" fill="black"></unicon>
            <p class="ml-3 mb-2">+62 1234 5678 910</p>
          </div>
        </a> -->
          <a
            href="https://www.instagram.com/kudata.id/"
            target="_blank"
            tabindex="-1"
          >
            <div class="w-full flex flex-row">
              <unicon name="instagram" fill="gray"></unicon>
              <p class="ml-3 mb-2 subtitle">@kudata.id</p>
            </div>
          </a>

          <a href="https://twitter.com/kudataid" target="_blank" tabindex="-1">
            <div class="w-full flex flex-row">
              <unicon name="twitter" fill="gray"></unicon>
              <p class="ml-3 mb-2 subtitle">@kudataid</p>
            </div>
          </a>
          <a
            href="https://t.me/+Nhq6HMXWDfgyOGVl"
            target="_blank"
            tabindex="-1"
          >
            <div class="w-full flex flex-row">
              <unicon name="telegram-alt" fill="gray"></unicon>
              <p class="ml-3 mb-2 subtitle">Kudata Community</p>
            </div>
          </a>
        </div>
      </div>
      <div class="w-full text-sm text-center mt-12 mb-5 subtitle">
        Copyright © {{ new Date().getFullYear() }} Kudata. All rights reserved.
      </div>
    </div>
    <el-dialog
      v-model="aboutDialogVisible"
      title="Tentang Kami"
      width="60%"
      center
    >
      <span>
        Kudata hadir sejak 2021 sebagai platform survey terpusat bagi mahasiswa
        Indonesia. Kudata menyediakan layanan untuk penawaran dan pengisian
        survey yang dilengkapi dengan jaminan insentif terautomasi, serta basis
        responden yang besar dan terverifikasi.
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      aboutDialogVisible: false,
      tncLink:
        'https://docs.google.com/document/d/144YojRZ_jR-G460FEZqMip7WB5DPC7bOHARvTDem50Y/edit?usp=sharing',
    }
  },
}
</script>

<style scoped lang="scss">
.container {
  background: $grey-light;
  color: $black;

  .logo {
    width: 60px;
  }
}
.footer-right {
  .subtitle {
    cursor: pointer;
  }
}
.el-overlay {
  @media (max-width: 576px) {
    display: none !important;
  }
}
</style>
