const defaultState = () => {
  const cooldownTime = parseInt(localStorage.getItem('cooldownTime'), 10) || 0
  const endTime = parseInt(localStorage.getItem('endTime'), 10) || Date.now()
  const isCooldown = localStorage.getItem('isCooldown') === 'true' || false

  // Calculate remaining cooldown time
  const remainingTime = Math.max(Math.floor((endTime - Date.now()) / 1000), 0)

  return {
    form: {
      email: '',
    },
    token: '',
    cooldownTime: remainingTime,
    isCooldown: remainingTime > 0 && isCooldown,
    loading: false,
    isSuccess: false,
    redirectTime: 10,
  }
}

export default defaultState
