<template>
  <div class="w-full flex flex-col items-center">
    <profile-banner> </profile-banner>
    <div class="w-full px-2 md:px-0 md:w-3/4 flex flex-col items-start">
      <el-menu :default-active="'1'" mode="horizontal">
        <el-menu-item index="1" @click="currentMenu = 1"
          >Survey yang diisi</el-menu-item
        >
        <el-menu-item index="2" @click="currentMenu = 2"
          >Survey yang dibuat</el-menu-item
        >
      </el-menu>
      <div v-if="currentMenu == 1" class="mt-5">
        <!-- <el-alert
          title="Halo! Fitur ini masih dalam pengembangan. Nantinya, survey yang kamu isi akan
        ditampilkan di sini :)"
          type="info"
          :closable="false"
        /> -->
        
        <profile-filler-table />
       
      </div>
      <div v-if="currentMenu == 2" class="mt-5">
        <!-- <el-alert
          title="Halo! Fitur ini masih dalam pengembangan. Nantinya, survey yang pernah kamu buat akan
        ditampilkan di sini :)"
          type="info"
          :closable="false"
        /> -->
        <profile-maker-table />
         
      </div>
      <!-- <profile-filler-table
        v-if="currentMenu == 1"
        :table-data="tableData"
      ></profile-filler-table>
      <profile-maker-table
        v-if="currentMenu == 2"
        :table-data="tableData"
      ></profile-maker-table> -->
    </div>
  </div>
</template>

<script>
import ProfileBanner from '../Component/ProfileBanner.vue'
import ProfileFillerTable from '../Component/ProfileFillerTable.vue'
import ProfileMakerTable from '../Component/ProfileMakerTable.vue'


export default {
  components: {
    ProfileBanner,
    ProfileFillerTable,
    ProfileMakerTable,
  },
  data() {
    return {
      currentMenu: 1,
      tableData: [
        {
          id: 1,
          title: 'Survey Produk Kudata',
          maker: 'Bobby Bob',
          filledAt: '12 December 2021',
        },
        {
          id: 3,
          title: 'Program Mentoring TELADAN',
          maker: 'Johnny John',
          filledAt: '14 December 2021',
        },
      ],
    }
  },
  computed: {},
    mounted(){
    // this.$store.state.profile.email = localStorage.getItem('user-email')
    this.$store.dispatch('profile/GET_CREATED_SURVEYS')
    this.$store.dispatch('profile/GET_FILLED_SURVEYS')
    this.$store.dispatch('profile/GET_DASHBOARD_PROFILE')
  },
  
  methods: {},

}
</script>

<style lang="scss" scoped>
.table-filler {
  margin: 30px 0;
}
</style>
>
