const defaultState = () => {
  return {
    form: {
      email: '',
      password: '',
      warning: '',
    },
    loading: false,
    success: false,
    redirect: '',
	token : ','
  }
}

export default defaultState
