import API_SERVICE from '../../../service/api/index'

const RESEND_EMAIL_VERIFICATION  = (params, onSuccess, onError) => {
	const apiDetail = API_SERVICE.API_URL.RESEND_EMAIL_VERIFICATION
	return API_SERVICE.POST({
	  apiUrl: apiDetail.API,
	  params,
	  onSuccess,
	  onError,
	})
}
const VERIFY_EMAIL_FROM_URL  = (params, onSuccess, onError) => {
	const apiDetail = API_SERVICE.API_URL.VERIFY_EMAIL_FROM_URL 
	return API_SERVICE.GET({
	  apiUrl: `${apiDetail.API}/${params.token}`,
	  params: {},
	  onSuccess,
	  onError,
	})
}


export default {RESEND_EMAIL_VERIFICATION, VERIFY_EMAIL_FROM_URL }
