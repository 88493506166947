import Create from '../Page/Create'
// import Build from '../Page/Build'
import CreateSheet from '../Page/CreateSheet'
import LandingPage from '../Page/LandingPage'
import SurveyDetails from '../Page/SurveyDetails'
import Finished from '../Page/Finished'
import CreateGForm from '../Page/CreateGForm'
import API_SERVICE from '../../../service/api/index'


const {
  constant: { MODULE_ROUTE_NAME },
} = require('../Service')


export default [
  {
    name: `${MODULE_ROUTE_NAME}-finished`,
    path: `${MODULE_ROUTE_NAME}-finished`,
    component: Finished,
    meta: { requireAuth: true },
  },
  {
    name: MODULE_ROUTE_NAME,
    path: MODULE_ROUTE_NAME,
    component: LandingPage,
    meta: { requireAuth: true },
  },
  {
    name: `${MODULE_ROUTE_NAME}-details`,
    path: `${MODULE_ROUTE_NAME}-details`,
    component: SurveyDetails,
    meta: { requireAuth: true },
  },
  // {
  //   name: `${MODULE_ROUTE_NAME}-build`,
  //   path: `${MODULE_ROUTE_NAME}-build`,
  //   component: Build,
  //   meta: { requireAuth: true },
  // },
  {
    name: `${MODULE_ROUTE_NAME}-sheet`,
    path: `${MODULE_ROUTE_NAME}-sheet`,
    component: CreateSheet,
    meta: { requireAuth: true },
  },
  {
    name: `${MODULE_ROUTE_NAME}-gform`,
    path: `${MODULE_ROUTE_NAME}-gform`,
    component: CreateGForm,
    meta: { requireAuth: true },
  },
  {
    path: '/payment/:id',
    beforeEnter(to, from, next) {
      // Put the full page URL including the protocol http(s) below

      const url = API_SERVICE.BASE_URL + '/payment/' + to.params.id
      window.location.replace(url)
    }
  }

]
