<template>
<div>
<div class="survey-nav-title">
    <a @click="goTo('profile')"> &#8592; Dashboard profil</a>
    <h2>Edit Survey</h2>
  </div>
  <survey-creator :btn-message="`Simpan`" @nextSlide="toggleMessage"></survey-creator>
   <el-dialog
      v-model="$store.state.edit.isToggleMessage"
      title="Berhasil"
      width="70%"
      :show-close="false"
    >
      <span class="text-base">
        Anda yakin untuk melanjutkan? Data responden survey lama akan hilang.
      </span>
      <template #footer>
        <span class="dialog-footer">
            <el-button type="cancel" @click="$store.state.edit.isToggleMessage = false"> Kembali </el-button>
            <el-button type="success" @click="submit"> Lanjutkan </el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      v-model="$store.state.edit.isUpdateSuccess"
      title="Pembaruan form berhasil!"
      width="70%"
    >
      <span>
        form yang diperbarui akan menggunakan durasi dan insentif survey yang sama
      </span>
    </el-dialog>
    </div>
</template>

<script>
import SurveyCreator from '../../Create/Component/SurveyCreator.vue'

export default {

  components: {
    SurveyCreator
  },
  mounted(){
    let currPath = window.location.href.split('/')
   
    this.$store.state.create.form.formLink = currPath[currPath.length - 1]
    // console.log(this.$store.state.create.form.formLink)
    this.$store.dispatch('create/GET_SURVEYJS_DETAIL')
  },
  methods: {
    submit(){
      this.$store.dispatch('create/UPDATE_SURVEYJS_DETAIL')
      this.$store.state.edit.isToggleMessage = false;
      this.$store.state.edit.isUpdateSuccess = true;
    },
    goTo(path) {
        this.$router.push({ name: `/${path}` })
      },
      // isFormOneValid(isFormValid) {
      //   if(isFormValid) {
      //     this.isFilledOne = true
      //   }
      // },
    toggleMessage(){
      this.$store.state.edit.isToggleMessage = true;
      // console.log(this.$store.state.edit.isUpdateSuccess)
    },
    
  }
}
</script>

<style lang="scss" scoped>
.survey-nav-title {
        padding-top: 2em;
        padding-left: 2em;
        display: flex;
        flex-direction: column;
        gap: 0.5em;
      }
      .survey-nav-title a {
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        color: gray;
        width: 100%;
        cursor: pointer;
        
      }
      .survey-nav-title h2 {
        font-family: 'Inter', sans-serif;
        font-weight: 600;
        font-size: 1.3rem;
      }
      .survey-nav {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1em;
        
      }
      </style>
