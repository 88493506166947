<template>  
  <div id="survey" />
</template>

<script>
import "survey-core/defaultV2.min.css";
import { StylesManager } from "survey-core";
import {Survey} from 'survey-knockout-ui';

StylesManager.applyTheme("defaultV2");


export default {
props: ['form-fields'],
  mounted() {
    console.log(this.formFields)
    this.formFields.pages.forEach(a=>{
      a.elements.forEach(e=>{
        // console.log(e)
        if(e.type=='imagepicker'){
          e.choices = e.choices.map(f=>f.flatMap(g=>({[g.Key]:g.Value}))).map(g=>{g[0]['imageLink']=g[1]['imageLink']; return g[0];})
          
        }
      })
      
    })
    const survey = new Survey(this.formFields);
    survey.onComplete.add(this.surveyComplete);
    survey.render("survey");
  },
  methods: {
  surveyComplete (sender) {
    
    let formatted_data = {
      respond_elements : []
    }

    Object.keys(sender.data).forEach(function(key) {
     
      formatted_data.respond_elements.push({
        question : key,
        answer : (Array.isArray(sender.data[key])) ? 
        sender.data[key].join(", ") : String(sender.data[key])
      })
    })
   
    this.$store.state.fill.respond = formatted_data
    this.$emit('isCompleted');
    // this.$store.state.fill.isFilled = true;
    
  }
},

};
</script>

