import API_SERVICE from '../../../service/api/index'

const GENERATE_SECRET_EMAIL_URL = (params, onSuccess, onError) => {
  const apiDetail = API_SERVICE.API_URL.GENERATE_SECRET_EMAIL_URL
  return API_SERVICE.POST({
    apiUrl: apiDetail.API,
    params,
    onSuccess,
    onError,
  })
}
export default { GENERATE_SECRET_EMAIL_URL }
