import state from './state'
import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const resetPassword = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {},
}

export default resetPassword
