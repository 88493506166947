import API from '../Service/api'

const GENERATE_SECRET_EMAIL_URL = (context) => {
  context.state.cooldownTime = 30
  let params = {
    email: context.state.form.email.toLowerCase(),
  }

  API.GENERATE_SECRET_EMAIL_URL(params)
    .then(() => {
      context.state.success = true
      context.state.isCooldown = true
      COUNTDOWN_TIMER(context)
    })
    .catch((err) => {
      context.state.form.emailWarning = 'Email kamu tidak terdaftar, Coba Lagi!'
      console.error(err)
    })
}

const COUNTDOWN_TIMER = (context) => {
  if (context.state.cooldownTime > 0) {
    setTimeout(() => {
      context.state.cooldownTime -= 1
      COUNTDOWN_TIMER(context)
    }, 1000)
  } else {
    context.state.isCooldown = false
  }
}
export default { GENERATE_SECRET_EMAIL_URL }
