<template>
  <div
    class="flex relative flex-col items-center justify-center mt-24 mb-36 mx-6"
  >
    <div
      class="relative flex flex-col items-center"
      v-if="
        $store.state.verifyEmail.isSuccess && !$store.state.verifyEmail.loading
      "
    >
      <img
        class="absolute top-0"
        src="@assets/images/quirky-checkmark-with-graphic-elements.svg"
        alt="quirky-letter-with-a-checkmark"
      />
      <div
        class="relative border bg-white rounded-md p-6 z-50 mt-48 font-inter text-center"
        style="max-width: 26.25rem"
      >
        <h1 class="font-semibold text-2xl">Verifikasi email berhasil!</h1>
        <p class="helptext my-6">
          Anda dapat masuk ke akun Anda sekarang. Silakan melanjutkan dengan
          melakukan login.
        </p>
        <button
          class="font-medium text-sm underline hover:text-gray-600"
          @click="redirectToLogin"
        >
          Menuju Halaman Login
        </button>
        <p class="helptext mt-6">
          Anda akan dialihkan ke Hal. Login dalam
          {{ $store.state.verifyEmail.redirectTime }} detik.
        </p>
      </div>
    </div>
    <div
      v-if="$store.state.verifyEmail.loading"
      class="flex flex-col items-center justify-center py-60"
    >
      <span>Loading...</span>
    </div>
    <div
      class="relative flex flex-col items-center"
      v-if="
        !$store.state.verifyEmail.isSuccess && !$store.state.verifyEmail.loading
      "
    >
      <img
        class="absolute top-0"
        src="@assets/images/quirky-cosmonaut-in-blue.svg"
        alt="quirky-letter-with-a-checkmark"
      />
      <div
        class="relative border bg-white rounded-md p-6 z-50 mt-48 font-inter text-center"
        style="max-width: 26.25rem"
      >
        <h1 class="font-semibold text-2xl">Verifikasi email gagal!</h1>
        <p class="helptext my-6">
          Tautan tidak valid atau telah kedaluwarsa. Silakan lakukan verifikasi
          ulang.
        </p>
        <button
          @click="goTo('/users/verify-email')"
          class="font-medium text-sm underline hover:text-gray-600"
        >
          Menuju Halaman Verifikasi
        </button>
        <p class="helptext mt-6">
          Anda akan dialihkan ke Hal. Verifikasi dalam
          {{ $store.state.verifyEmail.redirectTime }} detik.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    let currPath = window.location.href.split('/')
    this.$store.state.verifyEmail.token = currPath[currPath.length - 1]
    this.$store.dispatch('verifyEmail/VERIFY_EMAIL_FROM_URL')
  },
  methods: {
    goTo(path) {
      this.$router.push({ path: path })
    },
    redirectToLogin() {
      localStorage.clear()
      window.location.href = '/login'
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../Style//main.scss';
</style>
