<template>
  <div>
    <div
      v-if="$store.state.resetPassword.verified && !$store.state.resetPassword.loading"
      class="flex flex-col items-center justify-center pt-24 pb-36"
    >
      <h1 class="py-5 text-3xl text-semibold">Perbarui Password</h1>
      <div class="w-3/4 py-3 md:w-1/3">
        <span class="flex items-center">Masukkan Password Baru</span>
        <el-input
          v-model="$store.state.resetPassword.form.newPassword"
          class="mt-2"
          placeholder="Masukkan Password Baru"
          show-password
        >
        </el-input>
        <span class="h-3 text-xs warning">{{
          $store.state.resetPassword.form.newPasswordWarning
        }}</span>
      </div>
      <div class="w-3/4 py-3 md:w-1/3">
        <span class="flex items-center">Konfirmasi Password Baru</span>
        <el-input
          v-model="$store.state.resetPassword.form.confirmationPassword"
          class="mt-2"
          placeholder="Konfirmasi Password Baru"
          show-password
          @keyup.enter="updatePassword"
        ></el-input>
        <span class="h-3 text-xs warning">{{
          $store.state.resetPassword.form.confirmationPasswordWarning
        }}</span>
        <span class="h-3 text-xs warning">{{
          $store.state.resetPassword.form.resetPasswordWarning
        }}</span>
      </div>
      <el-button
        class="w-3/4 mt-5 md:w-1/3"
        type="primary"
        :loading="$store.state.resetPassword.buttonLoading"
        @click="updatePassword"
        >Ganti Password</el-button
      >
    </div>
    <div v-if="$store.state.resetPassword.loading" class="flex flex-col items-center justify-center py-60">
		<span>Loading...</span>
	</div>
    <div
      class="flex flex-col items-center w-full my-60"
      v-if="!$store.state.resetPassword.verified && !$store.state.resetPassword.loading"
    >
      <h1 class="text-3xl font-bold text-center">Tautan Telah Kadaluarsa</h1>
      <el-button
        type="primary"
        class="w-10/12 mt-3 md:w-1/3 lg:w-1/4"
        @click="goTo('/forgot-password')"
      >
        REQUEST TAUTAN BARU
      </el-button>
    </div>

    <el-dialog
      v-model="$store.state.resetPassword.success"
      title="Password Berhasil Diperbarui!"
      width="70%"
      :show-close="false"
    >
      <span>Password telah berhasil diperbarui! </span>
      <template #footer>
        <el-button type="success" @click="goTo('/login')">
          Login Kembali
        </el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  methods: {
    updatePassword() {
      this.validatePassword()
      this.$store.state.resetPassword.form.resetPasswordWarning = ''
      if (
        !this.$store.state.resetPassword.form.newPasswordWarning &&
        !this.$store.state.resetPassword.form.confirmationPasswordWarning
      ) {
        this.$store.dispatch('resetPassword/RESET_PASSWORD')
      }
    },
    validatePassword() {
      if (!this.isNewPasswordValid()) {
        this.$store.state.resetPassword.form.newPasswordWarning =
          'Password minimal 8 karakter'
      } else {
        this.$store.state.resetPassword.form.newPasswordWarning = ''
      }
      if (!this.isConfirmationPasswordValid()) {
        this.$store.state.resetPassword.form.confirmationPasswordWarning =
          'Password tidak sesuai'
      } else {
        this.$store.state.resetPassword.form.confirmationPasswordWarning = ''
      }
    },
    isNewPasswordValid() {
      return this.$store.state.resetPassword.form.newPassword.length >= 8
    },
    isConfirmationPasswordValid() {
      return (
        this.$store.state.resetPassword.form.confirmationPassword ==
        this.$store.state.resetPassword.form.newPassword
      )
    },
    goTo(path) {
      this.$router.push({ path: path })
    },
  },
  beforeCreate() {
    this.$store.state.resetPassword.token = this.$route.query.data
    this.$store.dispatch('resetPassword/VALIDATE_SECRET_EMAIL_URL')
  },
}
</script>

<style lang="scss" scoped></style>
