<template>
    <div class="container">
        <div class="content">
            <div class="title">
                <h3>{{ generateSurveyTitle }}</h3>
                <h4>{{ generateSurveyDesc }}</h4>
            </div>
            <div class="survey-desc">
                <div class="kriteria-responden-1">
                    <div class="kriteria-responden">
                        <label for="">Kriteria Responden</label>
                        <input type="text" placeholder="Kriteria A" v-model="formattedKriteria" disabled>
                    </div>
                    <div class="jumlah-responden">
                        <label for="">Target Jumlah Responden (Orang)</label>
                        <input type="text" placeholder="20" v-model="$store.state.create.form.respondentTarget" disabled>
                    </div>
                </div>
                <div class="kriteria-responden-2">
                    <div v-if="!$store.state.create.isUseGForm" class="custom-url flex flex-col w-100">
                        
                        <div class="kr-text">
                            <label for="">Custom URL</label>
                            <i class="text-xs text-slate-700">*kosongkan untuk url automatis</i>
                        </div>
                        <span class="pt-2 text-xs flex flex-row items-center">{{domain_url}}
                        <input class="w-48" maxlength="50" type="text" placeholder="..." v-model="$store.state.create.form.formLink">
                        </span>
                        
                        
                    </div>
                    <div class="waktu-pengisian">
                        <label for="">Estimasi Waktu Pengisian (Menit)</label>
                        <input type="number" placeholder="10" v-model="$store.state.create.form.estFillTime">
                    </div>
                    <div class="durasi-penayangan">
                        <label for="">Durasi Penayangan Survey (Hari)</label>
                        <input type="text" placeholder="7" v-model="$store.state.create.form.duration" disabled>
                    </div>
                </div>
            </div>
        </div>
        <label class="tnc">
            <input v-model="isAgreeTnc" type="checkbox" />
            <p>Saya menyetujui 
                <a class="tnc-anchor" :href="tncLink" target="_blank">
                syarat dan ketentuan
                </a>
                dari Kudata
            </p>
        </label>
        <span v-if="isCheckedTnc != 0" id="warning" class="warning">
            {{ $store.state.create.warning.tnc }}
        </span>
        <div class="buttons">
            <button @click="prevSlide">Sebelumnya</button>
            <button @click="create" type="submit" >Selanjutnya</button>
        </div>
        <el-dialog
      v-model="$store.state.create.isBuildSuccess"
      title="Berhasil"
      width="70%"
      :show-close="false"
    >
      <span class="text-base">
        Anda yakin untuk melanjutkan?
      </span>
      <template #footer>
        <span class="dialog-footer">
            <el-button type="cancel" @click="$store.state.create.isBuildSuccess = false"> Kembali </el-button>
            <el-button type="success" :loading="$store.state.create.isLoading"  @click="submit"> Lanjutkan </el-button>
        </span>
      </template>
    </el-dialog>
    </div>
</template>

<script>
    export default {
        name: 'Tayangkan',
        props: {
            isSlideFour: Boolean,
            // surveyJson: Object,
        },
        emits: ['prevSlide'],
         data() {
            return {
                isAgreeTnc: false,
                tncLink: 'https://docs.google.com/document/d/144YojRZ_jR-G460FEZqMip7WB5DPC7bOHARvTDem50Y/edit?usp=sharing',
            }
        },
        computed: {
            domain_url: {
                get() {
                    return window.location.host.includes('localhost')
                    ? 'http://localhost:3000/fill/'
                    : 'https://kudata.id/fill/'
                
                }
            },
            isCheckedTnc() {
                return this.$store.state.create.warning.tnc.length
            },
            isFilledEstFillTime() {
                return this.$store.state.create.warning.estFillTime.length
            },  
            generateSurveyTitle() {
                return this.$store.state.create.form.title
            },
            generateSurveyDesc() {
                return this.$store.state.create.form.description
            },
            formattedKriteria: {
                get() {
                    return this.$store.state.create.form.fillerCriteria.replaceAll('\n', ', ');
                },
                set(val) {
                    this.$store.state.create.form.fillerCriteria = val
                }
            },
            estFillTime :{
                get() {
                    return this.$store.state.create.form.estFillTime.toString();
                },
                set(val) {
                    this.$store.state.create.form.estFillTime = Number(val)
                }
            }
        },
        mounted(){
            this.$store.state.create.isLoading = false;
        },
        methods: {
            goTo(path) {
                if (path === 'fill') window.location.href = '/fill'
                else this.$router.push({ path: path })
            },
            create() {
                let isFormValid = this.validation()
                if(isFormValid) {
                    if(this.$store.state.create.form.isFree){
                        this.$store.state.create.form.reward=0;
                    }
                    this.$store.state.create.form.title = this.generateSurveyTitle
                    this.$store.state.create.form.description = this.generateSurveyDesc
                    
                    this.$store.state.create.isBuildSuccess = true;
                 
                }
            },
            submit() {
                this.$store.state.create.isLoading = true;
                if(this.$store.state.create.isUseGForm) {
                    this.$store.dispatch('create/CREATE_SURVEY')
                }else {
                    this.$store.dispatch('create/CREATE_SURVEY_JS')
                }
                this.$store.state.create.onSuccess = this.goTo
                this.$store.state.create.onError = this.onError
                this.$store.state.create.isLoading = false;
            },
            onError(err){
                this.$store.state.create.isBuildSuccess = false;
                this.$store.state.create.warning.tnc = err;
            },
            prevSlide(isSlideFour) {
                this.$emit('prevSlide', isSlideFour)
            },
            isTnCValid() {
                return this.isAgreeTnc
            },
            isValidURLChar(){
                return this.$store.state.create.isUseGForm || this.$store.state.create.form.formLink == '' || /^[a-z0-9-_.~]+$/i.test(this.$store.state.create.form.formLink)
            },
            isEstFillTimeValid() {
                return this.$store.state.create.form.estFillTime
            },
            validation() {
                let isFormValid = true
                if (!this.isTnCValid()) {
                    isFormValid = false
                    this.$store.state.create.warning.tnc =
                    'Harap setujui syarat dan ketentuan'
                } else if(!this.isValidURLChar()){
                    isFormValid = false
                    this.$store.state.create.warning.tnc =
                    'Please input a valid URL character (alphanumeric,-,_,.,~)'
                }else {
                    this.$store.state.create.warning.tnc = ''
                }

                if(!this.isEstFillTimeValid()) {
                    isFormValid = false
                    this.$store.state.create.warning.estFillTime = 
                    'Masukkan estimasi waktu pengisian'
                } else {
                    this.$store.state.create.warning.estFillTime = ''
                }
                return isFormValid
            },
            
        },
        
    }
</script>

<style scoped>

      a {
      text-decoration: none;
      }

      input, textarea {
        outline: none;
      }
    .content {
        width: 70%;
        min-width: 600px;
        margin: auto;
        display: flex;
        flex-direction: column;
        gap: 2em;
        background-color: #FFFFFF;
        border-radius: 16px;
        border: 1px solid rgba(52, 54, 56, 0.24);
        padding: 2em;
    }
    .title h3, .preview h3{
        font-family: 'Inter', sans-serif;
        font-weight: 600;
        font-size: 1.2rem;
    }
    .title h4 {
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
    }
     .kr-text {
        display: flex;
        justify-content: space-between;
        pointer-events: none;
        align-items: flex-end;
    }
    .kriteria-responden p:nth-of-type(1) {
        font-family: 'Inter', sans-serif;
        font-weight: 500;
        white-space: nowrap;
    }
    .kriteria-responden p:nth-of-type(2) {
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        font-style: italic;
        color: rgba(0, 0, 0, 0.5);
        font-size: 0.8rem;
        white-space: nowrap;
    }
    .survey-desc, .title, .preview {
        display: flex;
        flex-direction: column;
        gap: 1em;
    }
    .kriteria-responden-1, .kriteria-responden-2 {
        display: flex;
        gap: 1em;
    }
    .kriteria-responden, .jumlah-responden, .waktu-pengisian, .durasi-penayangan {
        display: flex;
        flex-direction: column;
        width: 50%;
        gap: 0.5em;
    }
    .waktu-pengisian, .durasi-penayangan {
        min-width: 150px;
    }
    .pertanyaan {
        display: flex;
        flex-direction: column;
        gap: 0.5em;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    } 
    input[type='text'], input[type='number'] {
        padding: 1em;
        border: none;
        background-color: #EAEAEA;
        font-size: 0.8rem;
    }
    label {
        font-family: 'Inter', sans-serif;
        font-weight: 500;
        font-size: 1em;
    }
    .tnc {
        margin: auto;
        padding: 0 2em;
        display: flex;
        align-items: center;
        gap: 0.5em;
    }
    .tnc input[type='checkbox'] {
        width: 16px;
        height: 16px;
    }
    .tnc-anchor {
        color: #5d8bd7;
    }
    #warning {
        margin: auto;
    }
    .buttons{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 2em;
        padding-top: 2em;
        padding-bottom: 5em;
        margin: auto;
    }
    button {
        padding: 1em 2em;
        min-width: 50px;
        border: 0.5px solid #35158C;
        border-radius: 8px;
        background-color: #35158C;
        color: #FFFFFF;
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        font-size: 1rem;
    }
    
    button:nth-child(1){
        color: black;
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        background-color: white;
    }
    button:hover {
        cursor: pointer;
    }

    @media screen and (max-width: 1040px) {
        .content {
            padding: 2em 1em;
            width: 85%;
            min-width: 100px;
        }
        .kriteria-responden-1, .kriteria-responden-2 {
            flex-direction: column;
            width: auto;
        }
        .kriteria-responden, .jumlah-responden, .waktu-pengisian, .durasi-penayangan {
            width: 100%;
        }
    }



</style>