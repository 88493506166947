import App from './App.vue'
import store from './libs/store'
import router from './libs/router'
import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import VueCountdown from '@chenfengyuan/vue-countdown'
import vSelect from 'vue-select'
import VueGtag from 'vue-gtag-next'
import Unicon from 'vue-unicons'
import VueSocialSharing from 'vue-social-sharing'
import VueDebounce from 'vue-debounce'
import * as LottiePlayer from "@lottiefiles/lottie-player";
import {
  uniBars,
  uniEnvelope,
  uniInstagram,
  uniTwitter,
  uniPhone,
  uniInfoCircle,
  uniTelegramAlt,
  uniSortAmountDown,
  uniSearch,
  uniFilter,
  uniAngleDown,
  uniBriefcaseAlt,
  uniMoneyBill,
  uniTransaction,
  uniDialpadAlt,
  uniExclamationCircleMonochrome,
  uniCheckCircle,
  uniCheckCircleMonochrome,
  uniShareAlt,
  uniCheckSquareMonochrome,
  uniQuestionCircle,
  uniWhatsapp,
  uniFacebookF,
  uniLine,uniEye, uniInvoice,
  uniAngleUp,
  uniTimes
} from 'vue-unicons/dist/icons'
import 'vue-select/dist/vue-select.css'
import './styles/scss/element-variables.scss'
import './styles/index.css'

Unicon.add([
  uniBars,
  uniEnvelope,
  uniInstagram,
  uniTwitter,
  uniPhone,
  uniInfoCircle,
  uniTelegramAlt,
  uniSortAmountDown,
  uniSearch,
  uniFilter,
  uniAngleDown,
  uniBriefcaseAlt,
  uniMoneyBill,
  uniTransaction,
  uniDialpadAlt,
  uniExclamationCircleMonochrome,
  uniCheckCircle,
  uniCheckCircleMonochrome,
  uniShareAlt,
  uniCheckSquareMonochrome,
  uniQuestionCircle,
  uniWhatsapp,
  uniFacebookF,
  uniLine,uniEye, uniInvoice,
  uniAngleUp,
  uniTimes
])

const app = createApp(App)
app.use(store)
app.use(router)
app.use(VueSocialSharing)
app.use(ElementPlus)
app.use(Unicon)
app.directive('debounce', VueDebounce({ lock: true }))
app.use(VueGtag, {
  property: {
    id: 'UA-210861558-1',
  },
  isEnabled:
    process.env.NODE_ENV != 'development' &&
    !window.location.href.includes('dev.kudata.id'),
})
app.component(VueCountdown.name, VueCountdown)
// eslint-disable-next-line
app.component('v-select', vSelect)

app.config.globalProperties.$store = store

app.mount('#app')
