<template>
  <div
    class="list-box flex flex-col w-full items-center justify-center py-5 justify-center"
  >
    <div
      class="flex items-center justify-center md:flex-row flex-col w-full md:w-10/12 py-3 px-5"
    >
      <div class="search flex flex-row w-full items-center pl-2 pr-2 md:pr-5">
        <el-input
          v-model="$store.state.fillSurveyList.filter.title"
          class="search-input"
          size="large"
          placeholder="Cari judul/topik, penyelenggara, atau kriteria yang cocok untukmu"
          v-debounce:500ms="onSearch"
        />
      </div>
      <div
        class="flex flex-row flex-grow items-center gap-2 w-full pl-2 pr-2 md:pr-5 md:pt-0 pt-4"
      >
        <span class="w-full md:block hidden text-gray-400 text-right"
          >Urutkan</span
        >
        <div class="w-full text-black">
          <v-select
            v-model="$store.state.fillSurveyList.filter.orderBy2"
            :clearable="false"
            :options="$store.state.fillSurveyList.filterOption.orderBy2"
            class="text-sm rounded-md border text-black bg-white focus:border-blue-500 focus:ring focus:ring-blue-200 custom-v-select"
            @update:modelValue="onFilterChange"
          ></v-select>
        </div>

        <div class="w-full text-black">
          <v-select
            v-model="$store.state.fillSurveyList.filter.sortBy2"
            :clearable="false"
            :options="$store.state.fillSurveyList.filterOption.sortBy2"
            class="text-sm rounded-md border text-black bg-white focus:border-blue-500 focus:ring focus:ring-blue-200 custom-v-select"
            @update:modelValue="onFilterChange"
          ></v-select>
        </div>
      </div>
    </div>
    <div class="flex justify-between md:w-10/12 md:px-8 md:pr-10 w-11/12">
      <span v-if="!isLoading && keyword != ''" class="total-survey text-left"
        ><i
          >Ditemukan {{ surveyTotal }} survey yang terkait dengan "{{
            keyword
          }}"</i
        ></span
      >
      <span v-else><i></i></span>
      <div class="text-xs md:text-sm my-3">
        <el-switch
          v-model="filter.openOnly"
          active-text="Tunjukkan survey aktif saja"
          active-color="#5d8bd7"
          @update:modelValue="onFilterChange"
        ></el-switch>
      </div>
    </div>

    <survey-list v-if="surveyTotal > 0 && !isLoading"></survey-list>
    <span v-if="isLoading" class="subtitle my-48"
      ><span class="loader"></span
    ></span>
    <div
      v-if="isLoading == false && surveyTotal == 0"
      class="subtitle my-48 mt-32 flex flex-col items-center justify-center"
    >
      <lottie-player
        autoplay
        loop
        mode="normal"
        :src="require('@assets/not_found.json')"
        style="width: 320px"
      ></lottie-player>
      <span>Tidak ada survey yang ditemukan :( </span>
    </div>
    <el-pagination
      v-if="surveyTotal !== 0 && !isLoading"
      class="pagination-bottom"
      background
      layout="prev, pager, next"
      :current-page="pageNo"
      :page-size="pageSize"
      :total="surveyTotal"
      :pager-count="pagerCount"
      @update:current-page="onPageChange($event)"
      @update:page-size="onPageSizeChange()"
    ></el-pagination>

    <filter-modal v-if="isFilterModalOpen"></filter-modal>
    <additional-user-data-modal
      v-if="isAddtDataModalOpen"
    ></additional-user-data-modal>
  </div>
</template>

<script>
import SurveyList from '../Component/SurveyList.vue'
// import SponsoredSurveyList from '../Component/SponsoredSurveyList.vue'
import FilterModal from '../Component/FilterModal.vue'
import AdditionalUserDataModal from '../Component/AdditionalUserDataModal.vue'

export default {
  components: {
    SurveyList,
    FilterModal,
    // SponsoredSurveyList,
    AdditionalUserDataModal,
  },
  data() {
    return {
      keyword: '',
    }
  },
  computed: {
    isLoading() {
      return this.$store.state.fillSurveyList.loading
    },
    filter() {
      return this.$store.state.fillSurveyList.filter
    },
    filterOption() {
      return this.$store.state.fillSurveyList.filterOption
    },
    surveyTotal() {
      return this.$store.state.fillSurveyList.surveyTotal
    },
    pageNo() {
      return this.$store.state.fillSurveyList.filter.pageNo
    },
    pageSize() {
      return this.$store.state.fillSurveyList.filter.pageSize
    },
    pagerCount() {
      return this.$store.state.fillSurveyList.filter.pagerCount
    },
    isFilterModalOpen() {
      return this.$store.state.fillSurveyList.isFilterModalOpen
    },
    isAddtDataModalOpen() {
      return this.$store.state.fillSurveyList.isAddtDataModalOpen
    },
    isAuth() {
      return (
        localStorage.getItem('user-token') && localStorage.getItem('user-email') && localStorage.getItem('user-name')
      )
    },

    isFilledAdditionalData() {
      return this.$store.state.fillSurveyList.isFilledAddData
    },
  },
  mounted() {
    this.$store.dispatch('fillSurveyList/GET_SURVEY_LIST').then(() => {
      this.$store.state.fillSurveyList.loading = false
    })

    // TODO: Remove in the future, Get additional user data
     this.$store.dispatch('fillSurveyList/GET_USER_INFO').then(()=>{
      if (this.isAuth) {
      if (!this.isFilledAdditionalData) {
        this.$store.dispatch('fillSurveyList/GET_USER_INFO')
      } else {
        if (this.isFilledAdditionalData == false) {
          this.$store.state.fillSurveyList.isAddtDataModalOpen = true
        }
      }
    }
     })
    
  },
  methods: {
    loadSurveyList() {
      this.keyword = ''
      this.$store.dispatch('fillSurveyList/GET_SURVEY_LIST').then(() => {
        setTimeout(() => {
          this.keyword = this.$store.state.fillSurveyList.filter.title
          this.$store.state.fillSurveyList.loading = false
        }, 1000)
      })
    },
    onFilterChange(value) {
      // this.$store.state.fillSurveyList.filter.title = ''
      // this.$store.state.fillSurveyList.filter.pageNo = 1
      this.loadSurveyList()
      window.scrollTo(0, 0)
    },
    onPageChange(event) {
      this.currentPage = event
      this.$store.state.fillSurveyList.filter.pageNo = parseInt(event)
      this.loadSurveyList()
      // this.$store.dispatch('fillSurveyList/GET_SURVEY_LIST')
      window.scrollTo(0, 0)
    },
    onPageSizeChange() {
      // DON'T REMOVE, IT PREVENTS PAGINATION BUG
    },
    showFilterModal() {
      this.$store.state.fillSurveyList.isFilterModalOpen = true
    },
    onSearch() {
      // Back to page 1
      this.$store.state.fillSurveyList.filter.pageNo = 1
      this.loadSurveyList()

      window.scrollTo(0, 0)
    },
    isUsingFilter() {
      return (
        this.$store.state.fillSurveyList.filter.title !== '' &&
        this.$store.state.fillSurveyList.filter.orderBy !== ''
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.list-header {
  .search {
    width: 70%;
    margin-right: auto;
    @media (max-width: 768px) {
      width: 75%;
    }

    .search-input {
      width: 95%;
      @media (max-width: 768px) {
        width: 82%;
      }
    }

    .search-button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      cursor: pointer;
      background: $grey-dark;
      border-radius: 0 4px 4px 0;

      width: 5%;
      @media (max-width: 768px) {
        width: 18%;
      }
    }
  }

  .filter {
    width: 10%;
    @media (max-width: 768px) {
      width: 25%;
    }

    .filter-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      outline: 1px solid $grey-2;
      width: 100%;
      height: 40px;
      border-radius: 4px;
      background-color: #fff;
      color: #8c8c8c;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
      transition: all 0.2s ease-in-out;

      &:hover {
        background-color: #ebebeb;
      }

      span {
        margin-left: 5px;

        @media (max-width: 768px) {
          display: none;
        }
      }
    }
  }
}

.total-survey {
  font-size: 13px;
  font-weight: 300;
  color: #aaaaaa;
}
</style>

<style lang="scss">
.list-box {
  .el-input__inner {
    height: 40px;
    border-radius: 4px 0 0 4px !important;
    font-size: 12px;
  }

  .el-input__inner::-webkit-input-placeholder {
    font-style: italic;
  }

  .pagination-top,
  .pagination-bottom {
    @media (max-width: 640px) {
      display: flex;
      justify-content: center;
      width: 100% !important;
    }

    li,
    button {
      width: 48px !important;
      height: 36px !important;
      text-align: center;
      padding-top: 5px !important;

      @media (max-width: 640px) {
        width: 30px !important;
      }
    }

    li.active.number {
      background: $secondary !important;
    }

    button {
      padding-left: 18px !important;

      @media (max-width: 640px) {
        padding-left: 6px !important;
      }
    }
  }

  .pagination-top {
    margin-bottom: 10px;
  }

  .pagination-bottom {
    margin-top: 30px;

    @media (max-width: 640px) {
      margin-top: 30px;
    }
  }

  .modal-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0;

    .modal-row__label {
      color: $grey;
      font-size: 12px;
    }
  }

  .custom-v-select .vs__selected,
  .custom-v-select .vs__dropdown-menu,
  .custom-v-select .vs__dropdown-toggle {
    color: black !important;
  }
}
</style>
