export default {
  NUSADEMY1: {
    date: '30/04/2022',
    name: 'Nusademy',
    link: 'https://nusademy.id/',
    imageName: 'nusademy',
    voucherName: 'Voucher Scholarship Academy',
    voucherBenefit: 'Diskon 50%',
    voucherCode: 'NUSADATA1',
    voucherUsageLink: 'https://nusademy.id/',
  },
  NUSADEMY2: {
    date: '30/04/2022',
    name: 'Nusademy',
    link: 'https://nusademy.id/',
    imageName: 'nusademy',
    voucherName: 'Voucher Career Consultation',
    voucherBenefit: 'Diskon 15%',
    voucherCode: 'NUSADATA2',
    voucherUsageLink: 'https://nusademy.id/',
  },
  NUSADEMY3: {
    date: '30/04/2022',
    name: 'Nusademy',
    link: 'https://nusademy.id/',
    imageName: 'nusademy',
    voucherName: 'Voucher UI/UX Bootcamp',
    voucherBenefit: 'Special Price',
    voucherCode: 'NUSADATA3',
    voucherUsageLink: 'https://nusademy.id/',
  },
}
