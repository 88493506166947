<template>
  <div>
    <el-table class="table-filler" :data="getListData()" stripe style="width: 100%">
      <el-table-column prop="id" label="ID" width="80" />
      <el-table-column prop="title" label="Judul Survey" width="200">
        <template #default="scope">
          <span class="text-lg">
            {{ scope.row.title == '' ? 'Untitled' : scope.row.title }}
          </span>
          <br>
          <span class="text-gray-400">{{ "Diisi pada " + new Date(scope.row.createdAt).toDateString() }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="reward" label="Hadiah" width="120">
        <template #default="scope">
          {{ scope.row.reward == 0 ? "1 Poin" : "Rp. " + scope.row.reward + "*" }}
        </template>
      </el-table-column>
      <el-table-column prop="hostFullName" label="Pembuat" width="120" />
      <el-table-column width="200" label="Action">
        <template #default="scope">
            <unicon name="eye" fill="gray" @click="goTo(`/fill/${scope.row.isGform ? scope.row.id : scope.row.formLink.String}`)" />
         
          <!-- <el-button @click="goTo(`/fill/${scope.row.isGform ? scope.row.id : scope.row.formLink.String}`)">Lihat
            Survey</el-button> -->

        </template>
      </el-table-column>
    </el-table>
    <span class="text-gray-400 text-sm">* Hadiah berupa undian hanya bagi pemanang beruntung</span>
    <el-pagination v-if="surveyTotal !== 0" class="pagination-top pt-5" background layout="prev, pager, next"
      :current-page="pageNo" :page-size="pageSize" :total="surveyTotal" :pager-count="pagerCount"
      @update:current-page="onPageChange($event)" @update:page-size="onPageSizeChange()"></el-pagination>
  </div>
</template>

<script>
export default {

  computed: {
    surveyTotal() {
      return this.$store.state.profile.filledCount
    },
    pageNo() {
      return this.$store.state.profile.filter.pageNo
    },
    pageSize() {
      return this.$store.state.profile.filter.pageSize
    },
    pagerCount() {
      return this.$store.state.profile.filter.pagerCount
    },
  },
  mounted() {
    this.$store.state.profile.filter.pageNo = 1;
  },
  methods: {
    goTo(path) {
      this.$router.push({ path: path })
    },
    getListData() {
      return this.$store.state.profile.listFilled
    },
    onPageChange(event) {
      this.currentPage = event
      this.$store.state.profile.filter.pageNo = parseInt(event)
      this.$store.dispatch('profile/GET_FILLED_SURVEYS')
      window.scrollTo(0, 0)
    },
    onPageSizeChange() {
      // DON'T REMOVE, IT PREVENTS PAGINATION BUG
    },
  },
}
</script>

<style lang="scss" scoped></style>
