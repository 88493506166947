<template>
  <div>
    <!-- Survey header container -->
    <div
      class="relative z-50 flex items-center justify-between p-5 bg-white shadow-md cursor-pointer group hover:bg-gray-50"
      style="box-shadow: 0px 4px 16px 0px #0000000a"
      @click="displayHeaderToggle"
    >
      <div class="flex items-center gap-5">
        <!-- go back button -->
        <button
          type="button"
          class="flex hover:opacity-90 opacity-60"
          @click="goBack"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 16C0 11.7565 1.68571 7.68687 4.68629 4.68629C7.68687 1.68571 11.7565 0 16 0C18.1012 0 20.1817 0.413852 22.1229 1.21793C24.0641 2.022 25.828 3.20055 27.3137 4.68629C28.7994 6.17203 29.978 7.93586 30.7821 9.87706C31.5861 11.8183 32 13.8989 32 16C32 20.2435 30.3143 24.3131 27.3137 27.3137C24.3131 30.3143 20.2435 32 16 32C13.8989 32 11.8183 31.5861 9.87706 30.7821C7.93586 29.978 6.17203 28.7994 4.68629 27.3137C1.68571 24.3131 0 20.2435 0 16ZM25.6 14.4H12.8L18.4 8.8L16.128 6.528L6.656 16L16.128 25.472L18.4 23.2L12.8 17.6H25.6V14.4Z"
              fill="#9F7DFF"
            />
          </svg>
        </button>
        <!-- survey title -->
        <p
          class="flex flex-wrap overflow-hidden font-semibold font-roboto group-hover:underline"
          style="color: #1c4281; font-size: 1.125rem"
        >
          
          <span class="hidden md:block header-title"
            >Detail Survey #{{ surveyDetail.id }} : {{ formatText(surveyTitle) }}</span
          >
        </p>
      </div>
      <!-- toggle showHeader -->
      <button
        class="grid p-2 rounded-full place-items-center hover:bg-gray-100"
      >
        <unicon v-if="isHeaderVisible" name="angle-up" fill="black"></unicon>

        <unicon v-else name="angle-down" fill="black"></unicon>
      </button>
    </div>
    <!-- Survey detail header container -->
    <transition name="header-detail">
      <div v-if="isHeaderVisible">
        <div
          class="relative z-20 grid w-full grid-flow-row gap-10 p-6 md:grid-flow-col md:grid-cols-2"
          style="background-color: #f9f9f9"
        >
          <!-- Title , Creator & Filler criteria container -->
          <div>
            <div class="text-start">
              <div
                class="font-semibold font-roboto"
                style="color: #2c6cd6; font-size: 1.125rem"
              >
                {{ formatText(surveyTitle) }}
              </div>
            </div>
            <div class="flex gap-2 mt-2 italic text-start">
              <span class="flex text-gray-500 align-center">
                oleh {{ formatText(surveyHostFullName) }}
              </span>
              <img
                src="@assets/icons/whatsapp.svg"
                alt="Whatsapp"
                class="whatsapp"
                @click="openWhatsApp"
              />
            </div>
            <!-- START FILLER CRITERIA -->
            <div v-if="surveyDetail.fillerCriteria" class="mt-6">
              <p class="mb-1 text-sm font-semibold opacity-50 font-roboto">
                Kriteria Responden
              </p>
              <ul class="ml-4 list-disc">
                <li
                  v-for="(criteria, index) in formatFillerCriteria(
                    surveyDetail.fillerCriteria
                  )"
                  :key="index"
                  class="text-sm font-roboto"
                >
                  {{ criteria }}
                </li>
              </ul>
            </div>
            <!-- END FILLER CRITERIA -->
          </div>

          <!-- Time and Respondent container -->
          <div>
            <div
              class="flex flex-col flex-wrap items-start gap-6 md:items-center md:flex-row"
            >
              <div v-if="!isClosed" class="flex items-center gap-2">
                <img src="@assets/icons/twemoji_calendar.svg" alt="Calendar" />
                <div>
                  <vue-countdown
                    v-slot="{ days, hours, minutes }"
                    :time="timeLeft"
                  >
                    <div class="text-base font-medium font-roboto">
                      {{ days }} hari : {{ hours }} jam : {{ minutes }} menit
                    </div>
                  </vue-countdown>
                </div>
              </div>
              <svg
                width="4"
                height="5"
                viewBox="0 0 4 5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="hidden md:block"
                v-if="!isClosed"
              >
                <rect y="0.5" width="4" height="4" rx="2" fill="#2C6CD6" />
              </svg>
              <div class="flex items-center gap-2">
                <img
                  src="@assets/icons/twemoji_busts-in-silhouette.svg"
                  alt="Busts In Silhouette"
                />
                <div>
                  <span class="text-base font-medium font-roboto"
                    >{{ surveyDetail.totalFilledSurveys }}/{{
                      surveyDetail.participantNeeded
                    }}</span
                  >
                </div>
              </div>
              <svg
                v-if="surveyDetail.reward != 0"
                width="4"
                height="5"
                viewBox="0 0 4 5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="hidden md:block"
              >
                <rect y="0.5" width="4" height="4" rx="2" fill="#2C6CD6" />
              </svg>
              <div
                class="flex items-center gap-2"
                v-if="surveyDetail.reward != 0"
              >
                <img
                  src="@assets/icons/twemoji_money-bag.svg"
                  alt="Money Bag"
                />
                <div>
                  <span class="text-base font-medium font-roboto"
                    >{{ formatIDR(surveyDetail.reward) }} /
                    {{ surveyDetail.countWinner }} Orang</span
                  >
                </div>
              </div>
            </div>

            <!-- Reward Details container -->
            <div v-if="surveyWinners[0].name != ''" class="mt-6">
              <p class="mb-2 text-sm font-semibold font-roboto">Pemenang :</p>
              <div :style="generateColumnsStyle">
                <p
                  v-for="(winner, winnerIndex) in surveyWinners"
                  :key="winner.id"
                  class="text-sm italic leading-6 md:leading-4 font-roboto"
                >
                  <span>{{ winnerIndex + 1 }}.</span> User ID #{{ winner.id }} -
                  {{ winner.name }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <div>
      <div
        class="container flex justify-center max-w-full px-3 py-8 align md:px-10"
        style="background-color: #f2e8f8"
      >
        <div
          :class="
            surveyForm.loaded
              ? 'fill-left flex justify-center w-4/5 mb-8'
              : 'fill-left flex justify-center w-4/5 mb-8'
          "
        >
          <skeleton-box
            v-if="
              $store.state.fill.isGform &&
              !surveyForm.loaded &&
              !isFilled &&
              !isClosed
            "
            width="100vw"
            height="100vh"
          />

          <iframe
            v-if="
              $store.state.fill.isGform &&
              $store.state.fill.formLoaded &&
              !isFilled &&
              !isClosed
            "
            :src="surveyDetail.formLink.String"
            :width="(surveyForm.loaded ? 100 : 0) + '%'"
            height="600"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
            @load="validateGForm"
          >
            Loading…
          </iframe>

          <SurveyRunner
            v-else-if="
              $store.state.fill.detail.build != '' && !isFilled && !isClosed
            "
            @is-completed="builderCompleted"
            :form-fields="surveyBuilder"
            style="width: 100%; height: 100%"
          />

          <div v-if="isFilled || isClosed" class="w-full p-1">
            <img
              class="w-full overflow-hidden rounded"
              src="@assets/images/survey_filled.png"
              alt="Survey sudah diisi"
            />
          </div>
        </div>
        <div class="flex flex-col fill-right">
          <div class="mb-10 header">
            <!-- START SPONSOR SECTION -->
            <div
              v-if="isSponsored"
              class="flex flex-col justify-between text-sm sponsor-box row md:text-base"
            >
              <div class="flex flex-row items-center mt-2 mb-4">
                <p class="mr-3 subtitle">Sponsor:</p>
                <a :href="sponsorDetail.link" target="_blank">
                  <img
                    :src="
                      require('@assets/sponsor/' +
                        sponsorDetail.imageName +
                        '.png')
                    "
                    class="sponsor-logo"
                  />
                </a>
              </div>
            </div>
            <!-- END SPONSOR SECTION -->

            <!-- START REWARD SECTION -->
            <!-- <div
              v-if="surveyDetail.reward != 0"
              class="flex flex-col justify-between text-sm row md:text-base"
            >
              <div class="flex flex-row items-center mt-2 mb-4">
                <p class="mr-3 subtitle">Total Hadiah:</p>
                <div class="w-auto px-5 py-1 text-center reward-badge">
                  <p>{{ formatIDR(surveyDetail.reward) }}</p>
                </div>
                <span class="mx-3">Untuk</span>
                <div class="px-5 py-1 text-center count-winner-badge">
                  <p>{{ surveyDetail.countWinner }} Orang</p>
                </div>
              </div>
            </div> -->

            <div
              v-if="isSponsored"
              class="flex flex-col justify-between text-sm row md:text-base"
            >
              <div class="flex flex-row items-center mt-2 mb-4">
                <p class="mr-3 subtitle">Hadiah:</p>
                <div class="px-5 py-1 text-center voucher-badge">
                  <p>
                    {{ sponsorDetail.voucherName }}
                    ({{ sponsorDetail.voucherBenefit }})
                  </p>
                </div>
              </div>
              <div class="flex flex-row items-center mt-2 mb-4">
                <p class="mr-3 subtitle">Gunakan voucher di:</p>
                <a
                  class="font-bold"
                  :href="sponsorDetail.voucherUsageLink"
                  target="_blank"
                >
                  {{ sponsorDetail.voucherUsageLink }}
                </a>
              </div>
            </div>
            <!-- END REWARD SECTION -->

            <!-- START FILL RIGHT TOP -->
            <!-- <div class="fill-right__top">
              <div
                v-if="!isFilled && !isClosed"
                class="flex flex-row items-center mb-4 text-sm tnc md:text-base"
              >
                <label class="text-sm">
                  <input
                    v-model="isAgreeTnc"
                    type="checkbox"
                    @change="validateAll"
                  />
                  Saya menyetujui
                  <a class="tnc-anchor" :href="tncLink" target="_blank">
                    syarat dan ketentuan </a
                  >dari Kudata
                </label>
              </div>
              <div v-if="isClosed" class="flex flex-row items-center mb-4">
                <p class="mr-3 subtitle">Pemenang:</p>
                <p class="mr-3">{{ generateWinner() }}</p>
              </div>
              <div
                v-if="isFilled && isSponsored"
                class="flex flex-row items-center mb-4"
              >
                <p class="mr-3 subtitle">Kode Voucher:</p>
                <p class="mr-3 font-bold voucher-code">
                  {{ getVoucherCode() }}
                </p>
              </div>
              <button
                :class="generateButtonClass(canSubmit)"
                :disabled="!canSubmit"
                @click="fillSurvey"
              >
                {{ generateButtonText() }}
              </button>
              <div
                v-if="
                  isFilled &&
                  isClosed &&
                  generateWinner() !== 'Sedang menentukan...'
                "
                class="flex flex-row items-center my-4 close-message"
              >
                <p class="text-sm text-justify">
                  {{ generateClosedMessage() }}
                </p>
              </div>
            </div> -->
            <!-- END FILL RIGHT TOP -->

            <hr class="mt-4 mb-7" />

            <!-- START FILLER CRITERIA -->
            <!-- <div v-if="surveyDetail.fillerCriteria" class="filler-criteria">
              <p class="mb-2 mr-3 subtitle">Kriteria Responden:</p>
              <span
                v-for="(criteria, index) in formatFillerCriteria(
                  surveyDetail.fillerCriteria
                )"
                :key="index"
                class="filler-criteria__content"
              >
                <span>{{ index + 1 }}. </span> {{ criteria }}
                <br />
              </span>
            </div> -->
            <!-- END FILLER CRITERIA -->

            <!-- <hr v-if="surveyDetail.fillerCriteria" class="my-7" /> -->

            <!-- START DESCRIPTION -->
            <!-- <div class="mt-5 description">
              <p class="mb-2 mr-3 subtitle">Deskripsi:</p>
              <span
                v-if="!isReadMore"
                class="my-3 text-sm text-justify md:text-base preline"
              >
                {{
                  isLongDescription
                    ? surveyDetail.description.substring(0, 100) + '...'
                    : surveyDetail.description
                }}
              </span>
              <div
                v-if="!isReadMore && isLongDescription"
                class="mt-3 text-see-more-less"
                @click="isReadMore = !isReadMore"
              >
                Lihat Selengkapnya
              </div>
              <span
                v-if="isReadMore"
                class="my-3 text-sm text-justify md:text-base preline"
              >
                {{ surveyDetail.description }}
              </span>
              <br />
              <div
                v-if="isReadMore && isLongDescription"
                class="mt-3 text-see-more-less"
                @click="isReadMore = !isReadMore"
              >
                Lihat Lebih Sedikit
              </div>
            </div> -->
            <!-- END DESCRIPTION -->
          </div>
        </div>
      </div>
    </div>

    <footer
      class="fixed bottom-0 left-0 z-20 w-full p-4 bg-blue-600 border-t border-gray-200 shadow md:flex md:items-center md:justify-between md:p-3"
    >
      <div
        v-if="!isFilled && !isClosed"
        class="flex flex-row items-center text-sm tnc md:text-base"
      >
        <label
          class="flex flex-row ml-2 text-lg font-medium text-white prevent-select space-between"
        >
          <input
            v-model="isAgreeTnc"
            type="checkbox"
            @change="validateAll"
            class="w-6 h-6 mr-2 rounded"
          />
          Saya menyetujui&nbsp;<a
            class="font-bold tnc-anchor"
            :href="tncLink"
            target="_blank"
            >syarat dan ketentuan </a
          >&nbsp;dari Kudata
        </label>
      </div>
      <ul
        class="flex flex-wrap items-center px-5 mt-3 text-lg text-white sm:mt-0"
      >
        <el-button
          :class="generateButtonClass(canSubmit)"
          :disabled="!canSubmit"
          :loading="$store.state.fill.isLoading"
          @click="fillSurvey"
        >
          {{ generateButtonText() }}
        </el-button>
      </ul>
    </footer>
    <el-dialog
      v-model="$store.state.fill.success"
      title="Pengisian survey berhasil!"
      width="70%"
    >
      <span>
        Terima kasih sudah mengisi survey ini, {{ userFullName }}! <br />
        {{ generateFillSuccessMessage() }}
      </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import SkeletonBox from '../../Loading/SkeletonBox.vue'
import { formatIDR } from '@libs/util'
import sponsorData from '../../FillSurveyList/Test/sponsor'
import SurveyRunner from '../Component/SurveyRunner.vue'

export default {
  components: {
    SkeletonBox,
    SurveyRunner,
  },
  beforeRouteLeave(to, from, next) {
    if (this.$store.state.fill.isFilled != true) {
      const answer = window.confirm(
        'Apakah anda yakin meninggalkan halaman sebelum mengirim hasil pengisian ke Kudata?'
      )
      if (!answer) return false
    }
    next()
  },
  data() {
    return {
      canSubmit: false,
      isBuilderCompleted: false,
      isAgreeTnc: false,
      filledSurveyPageCount: 0,
      isReadMore: false,
      isHeaderVisible: false,
      surveyForm: {
        src: '',
        loaded: false,
      },
      tncLink:
        'https://docs.google.com/document/d/144YojRZ_jR-G460FEZqMip7WB5DPC7bOHARvTDem50Y/edit?usp=sharing',
    }
  },
  computed: {
    generateColumnsStyle() {
      const winnersLength = this.surveyWinners.length
      const isMobile = window.innerWidth <= 768

      let gridAutoFlow = 'column'
      let rowNum = 5

      if (winnersLength <= 5 || isMobile) {
        rowNum = 1
        gridAutoFlow = 'none'
      }
      return {
        display: 'grid',
        gridTemplateRows: `repeat(${rowNum}, auto)`,
        gridAutoFlow: gridAutoFlow,
        gap: '0 1.5rem',
		justifyContent : 'start'
      }
    },
    userFullName() {
      return localStorage.getItem('user-name')
    },
    surveyHostFullName() {
      return this.$store.state.fill.hostFullName
    },
    surveyHostPhoneNumber() {
      return this.$store.state.fill.hostPhoneNumber
    },
    surveyTitle() {
      return this.$store.state.fill.isGform
        ? this.$store.state.fill.detail.title
        : this.$store.state.fill.detail.build.title
    },
    surveyDescription() {
      return this.$store.state.fill.isGform
        ? this.$store.state.fill.detail.description
        : this.$store.state.fill.detail.build.description
    },
    surveyDetail() {
      return this.$store.state.fill.detail
    },
    sponsorDetail() {
      return sponsorData[this.$store.state.fill.detail.sponsor]
    },
	surveyWinners() {
      return this.$store.state.fill.winners
	},
    isSponsored() {
      return (
        this.$store.state.fill.detail.sponsor &&
        this.$store.state.fill.detail.sponsor != 'none'
      )
    },
    isGform() {
      return this.$store.state.fill.isGform
    },
    isFilled() {
      return this.$store.state.fill.isFilled
    },
    isReward() {
      return this.$store.state.fill.detail.reward > 0
    },
    isLongDescription() {
      return this.$store.state.fill.detail.description.length > 100
    },
    surveyBuilder() {
      return JSON.parse(JSON.stringify(this.$store.state.fill.detail)).build
    },
    isClosed() {
      return this.timeLeft < 0
    },
    timeLeft() {
      let now = moment()
      let end = moment(this.surveyDetail.endAt)
      return moment.duration(end.diff(now)).asMilliseconds()
    },
    fullName() {
      return localStorage.getItem('user-name').split(' ')[0]
    },
  },
  mounted() {
    this.$store.state.fill.detail.build = ''
    this.$store.state.fill.isLoading = false
    let currPath = window.location.href.split('/')
    this.$store.state.fill.id = currPath[currPath.length - 1]

    if (isNaN(+this.$store.state.fill.id)) {
      this.$store.state.fill.detail.formLink = this.$store.state.fill.id
      this.$store.dispatch('fill/GET_SURVEYJS_DETAIL')
      this.$store.state.fill.isGform = false
    } else {
      this.$store.dispatch('fill/GET_SURVEY_DETAIL')
      this.$store.state.fill.isGform = true
    }
  },
  methods: {
    goBack() {
      this.$router.push({ path: '/fill' })
    },
    formatIDR(val) {
      return formatIDR(val)
    },
    formatText(val) {
      val = val?.toLowerCase()
      return val
        ?.split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    },
    formatFillerCriteria(fillerCriteria) {
      if (!fillerCriteria) return ''
      let fillerCriteriaArr = fillerCriteria
        .split('\n')
        .filter((criteria) => criteria != '')
      return fillerCriteriaArr
    },
    generateButtonClass(canSubmit) {
      return canSubmit
        ? 'btn bg-white text-black submit w-full text-center font-bold py-3 px-11 rounded-lg'
        : 'btn bg-gray-400 submit disabled w-full text-center font-bold py-3 px-11 rounded-lg'
    },
    generateButtonText() {
      if (this.isClosed) return 'SUDAH DITUTUP'
      if (this.isFilled) return 'SUDAH DIISI'
      return 'Submit'
    },
    generateFillSuccessMessage() {
      if (this.isSponsored) {
        return 'Voucher kamu dapat dilihat pada laman ini setelah di-refresh! Kami juga akan mengirimkan informasi voucher ke emailmu! Segera gunakan voucher kamu ya karena kuota terbatas!'
      } else if (this.isReward) {
        return 'Jika kamu memenangkan hadiah, kamu akan dihubungi melalui email yang terdaftar. Semoga beruntung :)'
      }
      return ''
    },
    generateClosedMessage() {
      if (this.isWinner())
        return `Selamat, ${this.fullName}! Kamu memenangkan insentif survey ini! Tim Kudata akan menghubungimu maksimal 2x24 jam melalui email terdaftar`
      else
        return `Terima kasih telah mengisi survey ini, ${this.fullName}! Sayangnya kali ini, kamu belum berhasil mendapatkan insentif. Kamu bisa mengisi survey lainnya atau menunggu survey-survey berhadiah menarik baru  nantinya!`
    },
    generateWinner() {
      return this.winnerNames || 'Sedang menentukan...'
    },
    getVoucherCode() {
      return this.sponsorDetail.voucherCode
    },
    isWinner() {
      let userEmail = localStorage.getItem('user-email')
      if (this.winnerEmails.includes(userEmail)) return true
    },
    validateAll() {
      this.canSubmit =
        this.isAgreeTnc &&
        (!this.isGform ||
          this.filledSurveyPageCount > this.surveyDetail.totalPages.Int64) &&
        this.timeLeft !== 0 &&
        !this.isFilled &&
        (this.$store.state.fill.detail.isGform ||
          (this.isBuilderCompleted && !this.$store.state.fill.detail.isGform))
    },
    validateGForm() {
      this.surveyForm.loaded = true
      this.filledSurveyPageCount += 1
      this.validateAll()
    },
    builderCompleted() {
      this.isBuilderCompleted = true
      this.validateAll()
    },
    fillSurvey() {
      this.$gtag.event('fill-survey-submit', {
        event_category: 'fill-engagement',
        event_label: `Fill Survey ${this.$store.state.fill.id} Submit`,
      })
      // console.log(this.$store.state.fill.detail)
      this.$store.state.fill.isLoading = true
      this.$store.dispatch('fill/FILL_SURVEY')
      this.canSubmit = false
    },
    openWhatsApp() {
      const message = 'Halo!'
      const url = `https://api.whatsapp.com/send?phone=${
        this.surveyHostPhoneNumber
      }&text=${encodeURIComponent(message)}`
      window.open(url, '_blank')
    },
    displayHeaderToggle() {
      this.isHeaderVisible = !this.isHeaderVisible
    },
  },
}
</script>

<style lang="scss" scoped>
.close-message {
  background: rgb(46, 170, 100);
  padding: 15px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
}

.voucher-code {
  color: $white;
  background: $primary;
  padding: 5px 20px;
}

.sponsor-box {
  .sponsor-logo {
    width: 150px;
    height: auto;
  }
}

.container {
  @media (max-width: 768px) {
    flex-direction: column-reverse !important;
  }
}

.fill-left,
iframe {
  overscroll-behavior-y: content !important;
}

.fill-left {
  box-shadow: 0px 0px 1px 1px rgba(128, 124, 124, 0.3);
  border-radius: 15px;

  @media (max-width: 768px) {
    width: 100% !important;
  }
}

.fill-right {
  @media (max-width: 768px) {
    width: 100% !important;
  }

  .fill-right__top {
    width: 100%;
    padding: 20px;
    margin: 20px 0;
    border-radius: 10px;
    background: #fff;
    border: 1px dashed #e6e6e6;
  }

  .header {
    .title {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: justify;
    }

    .row {
      @media (max-width: 1280px) {
        flex-direction: column;
        div {
          margin: 5px 0;
        }
      }

      .reward-badge {
        background: $primary;
        color: $white;
      }

      .count-winner-badge {
        background: $secondary;
        color: $white;
      }

      .voucher-badge {
        background: $secondary;
        color: $white;
      }
    }

    .subtitle > span {
      color: $black;
    }

    .text-see-more-less {
      color: $secondary;
      cursor: pointer;
      font-size: 14px;
      font-weight: bold;
      margin: 10px 0;
    }

    .tnc {
      color: $grey;

      input {
        vertical-align: -2px;
        cursor: pointer;
      }

      .tnc-anchor {
        color: $secondary;
        cursor: pointer;
      }
    }

    .submit {
      background: $success;
      color: $white;
      letter-spacing: 2px;
    }

    .disabled {
      background: $grey !important;
      cursor: not-allowed;
    }
  }
}

.preline {
  white-space: pre-line !important;
}

.reward-badge {
  background: $secondary;
  color: $white;
}

.count-winner-badge {
  background: $primary;
  color: $white;
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.whatsapp:hover {
  cursor: pointer;
}

.font-roboto {
  font-family: 'Roboto', sans-serif;
}

.header-title {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  text-align: start;
  max-width: 100ch;
}
// header show animation
.header-detail-enter-from,
.header-detail-leave-to {
  transform: translateY(10px);
}
.header-detail-enter-to,
.header-detail-leave-from {
  transform: translateY(0);
}
.header-detail-enter-active,
.header-detail-leave-active {
  transition: all 150ms ease-in-out;
}
</style>
