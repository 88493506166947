<template>
  <div>
    <el-table class="table-filler w-96" :data="getListData()" stripe style="width: 100%">
      <el-table-column prop="id" label="ID" width="70" />
      <el-table-column prop="title" label="Judul Survey" width="200">
        <template #default="scope">
          <span class="text-lg">
            {{ scope.row.title == '' ? 'Untitled' : scope.row.title }}
          </span>

          <br>
          <span class="text-gray-400">{{ "Dibuat pada " + new Date(scope.row.createdAt).toDateString() }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="countFillers" label="Respon" width="80" />
      <el-table-column label="Status" width="200">
        <template #default="scope">
          <div v-if="scope.row.paymentStatus == 'waiting_payment'"
               style="background-color: transparent; color: orange; white-space: normal; word-wrap: break-word; overflow-wrap: break-word;"
               class="p-1 rounded flex justify-left items-center w-37 h-9">
            Menunggu Pembayaran
          </div>
          <div v-else>
            <div v-if="!hasEnded(scope.row.endAt)" 
                style="background-color: transparent; color: green; white-space: normal; word-wrap: break-word; overflow-wrap: break-word;"
                class="p-1 rounded flex justify-center items-center w-37 h-9">
              Sedang tayang
            </div>
            <div v-else 
                style="background-color: transparent; color: grey; white-space: normal; word-wrap: break-word; overflow-wrap: break-word;"
                class="p-1 rounded flex justify-center items-center w-37 h-9">
              Sudah selesai
            </div>
          </div>


        </template>
      </el-table-column>
      <el-table-column width="150" label="Action">
        <template #default="scope">

          <el-button v-if="!scope.row.isGform" :loading="scope.row.isLoadingAnalytics"
            @click="goToAnalytics(scope.row.formLink.String, scope.row)">Lihat Data</el-button>
            <unicon v-else name="eye" fill="gray" class="cursor-pointer" @click="goToGform(scope.row.formLink.String)"  />
          <!-- <el-button v-else @click="goToGform(scope.row.formLink.String)">
            Lihat Gform
          </el-button> -->
          <el-button v-if="!scope.row.isGform && !hasEnded(scope.row.endAt)" :loading="scope.row.isLoadingEdit"
            @click="goToEdit(scope.row.formLink.String, scope.row)">
            Edit

          </el-button>
          
          <unicon v-if="scope.row.paymentStatus == 'waiting_payment'" name="invoice" fill="gray" :loading="scope.row.isLoadingEdit" 
            class="cursor-pointer" @click="goToPayment(scope.row.id, scope.row)" />
           
          <!-- <el-button v-if="scope.row.paymentStatus == 'waiting_payment'" :loading="scope.row.isLoadingEdit"
            @click="goToPayment(scope.row.id, scope.row)">
            Bayar
          </el-button> -->
          <!-- <el-button :loading="scope.row.isLoadingEdit" @click="goToEdit(scope.row.formLink.String, scope.row)">
            Edit

          </el-button> -->

        </template>
      </el-table-column>
    </el-table>
    <el-pagination v-if="surveyTotal !== 0" class="pagination-top  pt-5" background layout="prev, pager, next"
      :current-page="pageNo" :page-size="pageSize" :total="surveyTotal" :pager-count="pagerCount"
      @update:current-page="onPageChange($event)" @update:page-size="onPageSizeChange()"></el-pagination>
  </div>
</template>

<script>
// import JsonExcel from "vue-json-excel";
import moment from 'moment'
import * as XLSX from 'xlsx'
import API_SERVICE from '../../../service/api/index'

export default {

  computed: {
    surveyTotal() {
      return this.$store.state.profile.createdCount
    },
    pageNo() {
      return this.$store.state.profile.filter.pageNo
    },
    pageSize() {
      return this.$store.state.profile.filter.pageSize
    },
    pagerCount() {
      return this.$store.state.profile.filter.pagerCount
    },
  },
  mounted() {
    this.$store.state.profile.filter.pageNo = 1;
    this.$store.state.create.listCreated?.forEach(e => {
      e.isLoadingAnalytics = e.isLoadingEdit = false;
    });

  },
  methods: {
    goTo(path) {
      this.$router.push({ path: path })
    },
    goToAnalytics(link, row) {
      row.isLoadingAnalytics = true;
      this.$router.push({ path: '/respond/' + link })
    },
    goToGform(url) {
      window.location.href = url
    },
    goToEdit(id, row) {
      row.isLoadingEdit = true;
      this.$router.push({ path: '/edit/' + id })
    },
    goToPayment(id, row) {
      row.isLoadingEdit = false;
      // this.$router.push({ path: '/payment/' + id })

      const url = 'https://api.kudata.id/api/payment/' + id

      window.open(url, '_blank', 'noreferrer');
    },
    getListData() {
      return this.$store.state.profile.listCreated
    },

    hasEnded(date) {
      // debugger
      // console.log(date)
      let now = moment()
      let end = moment(date)
      let timeLeft = moment.duration(end.diff(now)).asMilliseconds()
      return timeLeft < 0;
    },
    onPageChange(event) {
      this.currentPage = event
      this.$store.state.profile.filter.pageNo = parseInt(event)
      this.$store.dispatch('profile/GET_CREATED_SURVEYS')
      window.scrollTo(0, 0)
    },
    onPageSizeChange() {
      // DON'T REMOVE, IT PREVENTS PAGINATION BUG
    },
  },
}
</script>

<style lang="scss" scoped></style>
