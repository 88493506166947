const generateMeta = (url) => {
  if (url.includes('login')) {
    document.title = 'Kudata | Masuk'
    document.getElementsByName('description')[0].content =
      'Temukan topik survey yang menarik bagimu dan dapatkan hadiah yang pasti hanya pada platform Kudata!'
  } else if (url.includes('register')) {
    document.title = 'Kudata | Daftar'
    document.getElementsByName('description')[0].content =
      'Temukan topik survey yang menarik bagimu dan dapatkan hadiah yang pasti hanya pada platform Kudata!'
  } else if (url.includes('fill/')) {
    document.title = 'Kudata | Isi Survey'
    document.getElementsByName('description')[0].content =
      'Temukan topik survey yang menarik bagimu dan dapatkan hadiah yang pasti hanya pada platform Kudata!'
  } else if (url.includes('fill')) {
    document.title = 'Kudata | Isi Survey'
    document.getElementsByName('description')[0].content =
      'Temukan topik survey yang menarik bagimu dan dapatkan hadiah yang pasti hanya pada platform Kudata!'
  } else if (url.includes('create')) {
    document.title = 'Kudata | Buat Survey'
    document.getElementsByName('description')[0].content = 'Buat survey'
  } else {
    document.title = 'Kudata | Platform Survey Mahasiswa Indonesia'
    document.getElementsByName('description')[0].content =
      'Temukan topik survey yang menarik bagimu dan dapatkan hadiah yang pasti hanya pada platform Kudata!'
  }
}

export { generateMeta }
