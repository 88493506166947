<template>
  <div class="w-full">
    <div class="hero flex items-center px-7 lg:px-10 xl:px-48 py-10">
      <div class="w-full flex flex-col items-start justify-center md:w-1/2">
        <div class="row w-11/12">
          <h1 class="text-4xl sm:text-5xl font-bold">
            <span class="text-gradient">Platform Survey #1</span>
            untuk Mahasiswa Indonesia
          </h1>
        </div>

        <div class="row w-11/12">
          <p class="w-full py-5 text-justify text-lg sm:text-xl">
            Temukan topik survey yang menarik bagimu dan dapatkan hadiah yang
            pasti hanya pada platform Kudata!
          </p>
        </div>

        <!-- <div class="row w-11/12 flex flex-row py-2">
          <button
            class="
              btn-p
              rounded-lg
              text-base
              px-5
              py-3
              mr-8
              sm:px-11 sm:py-3 sm:text-xl sm:mr-12
            "
            @click="analytics('create')"
          >
            Buat Survey
          </button>
          <button
            class="
              btn-s
              rounded-lg
              text-base
              px-6
              py-2
              sm:px-12 sm:py-3 sm:text-xl
            "
            @click="analytics('fill')"
          >
            Isi Survey
          </button>
        </div> -->

        <a href="https://t.me/+Nhq6HMXWDfgyOGVl" target="_blank" tabindex="-1">
          <button
            class="btn-join-community text-sm px-4 py-2 mt-5 sm:px-8 sm:py-4 sm:text-xl"
          >
            <unicon
              class="mr-1 sm:mr-5"
              name="telegram-alt"
              fill="white"
            ></unicon>
            Gabung Komunitas Kudata
          </button>
        </a>

        <a
          href="https://www.instagram.com/kudata.id/"
          target="_blank"
          tabindex="-1"
        >
          <button
            class="btn-follow-instagram text-sm px-5 py-2 mt-5 sm:px-10 sm:py-4 sm:text-xl"
          >
            <unicon class="mr-1 sm:mr-5" name="instagram" fill="white"></unicon>
            Follow Instagram Kudata
          </button>
        </a>
      </div>
    </div>

    <div
      class="service flex flex-col items-center justify-center w-full px-12 lg:px-16 xl:px-48 xl:py-24 py-12"
    >
      <div class="flex flex-col items-start w-full">
        <span class="text-4xl font-bold">Kamu lagi butuh apa?</span>

        <span class="text-xl mt-2 mb-12">
          Kudata menyediakan segala keperluan surveymu
        </span>
      </div>

      <div class="card-container flex flex-col md:flex-row justify-between">
        <service-card
          image="home-service-1.png"
          title="Buat Survey"
          desc="
            Buat survey sesuai kebutuhanmu dan pilih sponsor yang menyediakan
            insentif untuk respondenmu!"
          action="Buat survey sekarang"
          @click="analytics('create')"
        ></service-card>

        <service-card
          image="home-service-2.png"
          title="Isi Survey"
          desc="
            Isi survey sesuai topik pilihanmu dan dapatkan hadiah voucher
            spesial dari sponsor!"
          action="Cari survey berhadiah menarik"
          @click="analytics('fill')"
        ></service-card>

        <service-card
          image="home-service-3.png"
          title="Jadi Sponsor"
          desc="
            Sponsori survey dan dapatkan eksposur luas terhadap mahasiswa
            Indonesia!"
          action="Kontak kami"
          @click="analytics('contact')"
        ></service-card>
      </div>
    </div>

    <div
      class="why flex flex-col items-center justify-center w-full px-12 lg:px-16 xl:px-48 xl:py-24 py-12 mb-12"
    >
      <div class="why-title text-3xl font-bold mb-5">
        Kenapa
        <span>Kudata</span>
        ?
      </div>

      <el-tabs type="border-card">
        <el-tab-pane label="Maker">
          <div class="card-container flex flex-col md:flex-row justify-between">
            <why-card
              image="home-why-maker-1.png"
              title="Biaya Gratis"
              desc="Buat dan iklankan surveymu tanpa biaya sepeserpun"
            ></why-card>

            <why-card
              image="home-why-maker-2.png"
              title="Responden Cepat"
              desc="Dapatkan responden berkualitas dengan cepat dan mudah"
            ></why-card>

            <why-card
              image="home-why-maker-3.1.png"
              title="Integrasi Google Form"
              desc="Gunakan Google Formmu yang sudah dibuat"
            ></why-card>
          </div>

          <p class="subtitle mt-2">* Maker = Pembuat survey</p>
        </el-tab-pane>

        <el-tab-pane label="Filler">
          <div class="card-container flex flex-col md:flex-row justify-between">
            <why-card
              image="home-why-filler-1.png"
              title="Insentif Terjamin"
              desc="Insentifmu dijamin oleh Kudata sebagai pihak ketiga"
            ></why-card>

            <why-card
              image="home-why-filler-2.png"
              title="Hadiah Menarik"
              desc="Dapatkan uang dan voucher dari pembuat survey maupun sponsor!"
            ></why-card>

            <why-card
              image="home-why-filler-3.png"
              title="Poin Gratis"
              desc="Kumpulkan poin mu untuk buat survey bebas insentif"
            ></why-card>
          </div>

          <p class="subtitle mt-2">* Filler = Pengisi survey</p>
        </el-tab-pane>

        <!-- <el-tab-pane label="Sponsor">
          <div class="card-container flex flex-col md:flex-row justify-between">
            <why-card
              image="home-service-1.png"
              title="Eksposur Luas"
              desc="
            Sponsori survey dan dapatkan eksposur luas terhadap mahasiswa
            Indonesia!"
            ></why-card>
            <why-card
              image="home-service-2.png"
              title="Aktivasi Mahasiswa"
              desc="
            Sponsori survey dan dapatkan eksposur luas terhadap mahasiswa
            Indonesia!"
            ></why-card>
            <why-card
              image="home-service-3.png"
              title="Biaya Gratis"
              desc="
            Sponsori survey dan dapatkan eksposur luas terhadap mahasiswa
            Indonesia!"
            ></why-card></div
        ></el-tab-pane> -->
      </el-tabs>
    </div>
  <div class="text-base md:text-3xl font-extrabold text-center w-full">
    Masih Ada Pertanyaan?
  </div>
    
   <div class="mt-7 w-full mb-52">
  
    <div class="padlet-embed" style="border:1px solid rgba(0,0,0,0.1);border-radius:2px;box-sizing:border-box;overflow:hidden;position:relative;width:100%;background:#F4F4F4">
      <p style="padding:0;margin:0">
      <iframe src="https://padlet.com/embed/jj3iyj811ar7qxk6" frameborder="0" allow="camera;microphone;geolocation" style="width:100%;height:608px;display:block;padding:0;margin:0"></iframe>
      </p>
      
    </div>
   </div>
    <div
      class="footer flex flex-col w-full items-center justify-center px-6 lg:px-16 xl:px-24 mt-54"
    >
      <div
        class="footer-content w-full flex flex-col items-center text-center pt-12"
      >
        <div class="text-base md:text-3xl font-extrabold">
          Tertarik untuk bergabung?
        </div>

        <div class="text-xs md:text-xl subtitle mb-7">
          Kudata siap membantumu kapanpun dan dimanapun
        </div>

        <button
          class="footer-button rounded-lg px-5 py-3 pb-40 sm:px-8 sm:py-3 sm:text-lg"
          @click="analytics('register')"
        >
          Mulai sekarang
        </button>
      </div>
    </div>
  <div>
  
 </div>
  </div>
</template>

<script>
import ServiceCard from '../Component/ServiceCard.vue'
import WhyCard from '../Component/WhyCard.vue'
export default {
  components: {
    ServiceCard,
    WhyCard,
  },
  data(){
    return {
      fAQList:[
        {
          'question':'What is Kudata?',
          'answer':'lorem ipsum dolor sit amet',
          'visibility':false
        },
         {
          'question':'What is Kudata?',
          'answer':'lorem ipsum dolor sit amet',
          'visibility':true
        }
      ]
    }
  },
 
  methods: {
    goTo(path) {
      this.$router.push({ path: path })
    },
    toggleAnswer(data){
      data.visibility = !data.visibility;
    },
    analytics(path) {
      if (path == 'fill') {
        this.$gtag.event('home-fill-click', {
          event_category: 'home-engagement',
          event_label: 'Fill Button Click at Dashboard',
        })
      }
      if (path == 'create') {
        this.$gtag.event('home-create-click', {
          event_category: 'home-engagement',
          event_label: 'Create Button Click at Dashboard',
        })
      }
      if (path == 'register') {
        this.$gtag.event('home-register-click', {
          event_category: 'home-engagement',
          event_label: 'Register Button Click at Dashboard',
        })
      }
      if (path == 'contact') {
        this.$gtag.event('home-contact-click', {
          event_category: 'home-engagement',
          event_label: 'Contact Button Click at Dashboard',
        })
        window.location = 'mailto:support@kudata.id'
      }
      if (path != 'contact') {
        this.goTo(path)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.hero {
  height: 105vh;
  margin-top: -60px;
  background-image: url('../../../assets/images/home-hero-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  h1 {
    color: $white;
  }
  p {
    color: $white;
  }
  .text-gradient {
    background: #feac5e; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to right,
      #4bc0c8,
      #c779d0,
      #feac5e
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #4bc0c8,
      #c779d0,
      #feac5e
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  button {
    min-width: 100px;
    color: $white;
    font-weight: bold;
    transition-duration: 0.3s;

    &:hover {
      transition-duration: 0.3s;
    }
  }
  .btn-p {
    background-color: $secondary;

    &:hover {
      background-color: rgba(93, 139, 215, 0.75);
    }
  }

  .btn-s {
    background-color: $success;

    &:hover {
      background-color: rgba(75, 181, 67, 0.75);
    }
  }

  .btn-join-community {
    border-radius: 15px;
    color: $grey-light;
    background: #2bb2d8;
    outline: 2px solid $grey-light;
    display: flex;
    flex-direction: row;
    align-items: center;

    &:hover {
      background: #67ceeb;
    }
  }

  .btn-follow-instagram {
    border-radius: 15px;
    color: $grey-light;
    background: #f09433;
    background: linear-gradient(
      45deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%
    );
    outline: 2px solid $grey-light;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
.service {
  background: linear-gradient(
    95.61deg,
    rgba(93, 139, 215, 0.6) -41.98%,
    rgba(93, 139, 215, 0) 78.07%
  );

  .card-container {
    @media (max-width: 992px) {
      display: flex;
      flex-direction: column;
    }
  }
}
.why {
  .why-title span {
    color: $primary;
  }

  .subtitle {
    font-size: 0.65rem;
  }
}
.footer {
  background-image: url('../../../assets/images/home-footer.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  .footer-content {
    height: 35vh;
    background: $grey-light;
    border-radius: 15px;
    position: relative;
    top: -80px;
    outline: 1px solid rgba(0, 0, 0, 0.05);
  }

  .footer-button {
    background: $primary;
    color: $white;
    transition: 0.2s;

    &:hover {
      background: $primary-light;
      transition: 0.2s;
    }
  }
}
</style>
