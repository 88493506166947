<template>
  <div class="container">
    <form class="content">
      <h3>Detail Survey</h3>
      <div class="link w-full">
        <p id="title">Link Google Form</p>
        <div class="flex w-full flex-wrap md:flex-nowrap md:justify-between gap-1">
          <input
            type="text"
            placeholder="Masukan link Google Form surveymu"
            v-model="$store.state.create.form.formLink"
          />
          <el-tooltip content="Preview Form" placement="top" auto-close="700">
            <el-button class="icon-button" @click="showIframePopup">
              <unicon name="eye" size="20"></unicon>
            </el-button>
          </el-tooltip>
          <el-button
            :loading="$store.state.create.isScrapping"
            type="primary"
			style="margin: 0; flex-grow: 1;"
            @click="importDataFromUrl"
            >Import URL</el-button
          >
        </div>
        <span v-if="isFormEmpty != 0" class="warning">{{
          $store.state.create.warning.formLink
        }}</span>
        <p id="gform">
          Contoh: https://docs.google.com/forms/d/e/ABCD/viewform?usp=sf_link
        </p>
      </div>
      <iframe-popup
        v-if="isPopupVisible"
        @close="hideIframePopup"
        :link="iframeLink"
      ></iframe-popup>
      <div class="judul">
        <p id="title">Judul</p>
        <div class="input">
          <input
            v-model="$store.state.create.form.title"
            type="text"
            maxlength="100"
            :disabled="$store.state.create.isScrapping"
            :placeholder="autoFillPlaceholder('Masukkan judul surveymu')"
          />
          <p>{{ $store.state.create.form.title.length }} / 100</p>
        </div>
        <span v-if="isTitleEmpty != 0" class="warning">{{
          $store.state.create.warning.title
        }}</span>
      </div>
      <div class="deskripsi">
        <div class="kr-text">
          <p id="title">Deskripsi Survey</p>
        </div>
        <div class="textarea">
          <textarea
            v-model="$store.state.create.form.description"
            type="text"
            name="ks"
            id="ks"
            cols="30"
            rows="3"
            autocomplete="off"
            maxlength="1000"
            :disabled="$store.state.create.isScrapping"
            :placeholder="
              autoFillPlaceholder('Masukkan deskripsi tentang surveymu')
            "
          ></textarea>
          <p>{{ $store.state.create.form.description.length }} / 1000</p>
        </div>
        <span v-if="isDescriptionEmpty != 0" class="warning">{{
          $store.state.create.warning.description
        }}</span>
      </div>
      <div class="kriteria-responden">
        <div class="kr-text">
          <p id="title">Kriteria Responden</p>
          <p>*Pisahkan dengan enter</p>
        </div>
        <div class="textarea">
          <textarea
            v-model="$store.state.create.form.fillerCriteria"
            type="text"
            name="ks"
            id="ks"
            cols="30"
            rows="3"
            autocomplete="off"
            placeholder="Contoh: 
Berumur 18 tahun  
Tinggal di Jakarta"
            maxlength="300"
          ></textarea>
          <p>{{ $store.state.create.form.fillerCriteria.length }} / 300</p>
        </div>
        <span v-if="isFilledCriteria != 0" class="warning">{{
          $store.state.create.warning.fillerCriteria
        }}</span>
      </div>
      <div class="wrapper">
        <div class="halaman-n-waktu">
          <div class="halaman">
            <p id="title">Jumlah Halaman Google Form</p>
            <div class="input">
              <input v-model="totalPages" type="number" placeholder="1" />
            </div>
            <span v-if="isPagesEmpty != 0" class="warning">{{
              $store.state.create.warning.totalPages
            }}</span>
          </div>
          <div class="waktu">
            <p id="title">Estimasi Waktu Pengisian (Menit)</p>
            <div class="input">
              <input v-model="estFillTime" type="number" placeholder="5" />
            </div>
            <span v-if="isEstFilledTimeEmpty != 0" class="warning">{{
              $store.state.create.warning.estFillTime
            }}</span>
          </div>
        </div>
        <div class="responden-n-durasi">
          <div class="responden">
            <p id="title">Target Jumlah Responden (Orang)</p>
            <div class="input">
              <input v-model="responsiInt" type="number" placeholder="10" />
            </div>
            <span v-if="isFilledRespondent != 0" class="warning">{{
              $store.state.create.warning.respondentTarget
            }}</span>
          </div>
          <div class="durasi">
            <p id="title">Durasi Survey (Hari)</p>
            <div class="input">
              <input v-model="durationInt" type="number" placeholder="5" />
            </div>
            <span v-if="isFilledDuration != 0" class="warning">{{
              $store.state.create.warning.duration
            }}</span>
          </div>
        </div>
      </div>
    </form>
    <div class="buttons">
      <button @click="goTo('create')">Sebelumnya</button>
      <button type="submit" @click="goToNextSlide">Selanjutnya</button>
    </div>
  </div>
</template>

<script>
import { formatIDR } from '@libs/util'
import IframePopup from './IframePopup.vue'
import { Unicon } from 'vue-unicons'

export default {
  name: 'SurveyDesc',
  components: { IframePopup, Unicon },
  props: {
    isSlideOne: Boolean,
  },
  data() {
    return {
      isPopupVisible: false, //Add popup visibility state
    }
  },
  computed: {
    isTitleEmpty() {
      return this.$store.state.create.warning.title.length
    },
    isEstFilledTimeEmpty() {
      return this.$store.state.create.warning.estFillTime.length
    },
    isPagesEmpty() {
      return this.$store.state.create.warning.totalPages.length
    },
    isDescriptionEmpty() {
      return this.$store.state.create.warning.description.length
    },
    isFilledCriteria() {
      return this.$store.state.create.warning.fillerCriteria.length
    },
    isFilledRespondent() {
      return this.$store.state.create.warning.respondentTarget.length
    },
    isFilledDuration() {
      return this.$store.state.create.warning.duration.length
    },
    isFormEmpty() {
      return this.$store.state.create.warning.formLink.length
    },
    durationInt: {
      get() {
        return this.$store.state.create.form.duration.toString()
      },
      set(val) {
        this.$store.state.create.form.duration = Number(val)
      },
    },
    estFillTime: {
      get() {
        return this.$store.state.create.form.estFillTime.toString()
      },
      set(val) {
        this.$store.state.create.form.estFillTime = Number(val)
      },
    },
    responsiInt: {
      get() {
        return this.$store.state.create.form.respondentTarget.toString()
      },
      set(val) {
        this.$store.state.create.form.respondentTarget = Number(val)
      },
    },
    totalPages: {
      get() {
        return this.$store.state.create.form.totalPages.toString()
      },
      set(val) {
        this.$store.state.create.form.totalPages = Number(val)
      },
    },
    iframeLink() {
      return this.$store.state.create.form.formLink
    },
  },
  methods: {
    goTo(path) {
      if (path === 'fill') window.location.href = '/fill'
      else this.$router.push({ path: path })
    },
    nextSlide(isSlideTwo) {
      this.$emit('nextSlide', isSlideTwo)
    },
    isFormTwoValid(isFormValid) {
      this.$emit('isFormValid', isFormValid)
    },
    isTitleValid() {
      return this.$store.state.create.form.title
    },
    isDescriptionValid() {
      return this.$store.state.create.form.description
    },
    isKriteriaValid() {
      return this.$store.state.create.form.fillerCriteria
    },
    isFormLinkValid() {
      return (
        this.$store.state.create.form.formLink.includes(
          'https://docs.google.com/forms/'
        ) && !this.$store.state.create.form.formLink.includes('/edit')
      )
    },
    isHalamanValid() {
      return this.$store.state.create.form.totalPages
    },
    isEstFillTimeValid() {
      return this.$store.state.create.form.estFillTime
    },
    isJumlahValid() {
      return this.$store.state.create.form.respondentTarget
    },
    isDurasiValid() {
      return this.$store.state.create.form.duration
    },

    validation() {
      let isFormValid = true
      if (!this.isTitleValid()) {
        this.$store.state.create.warning.title = 'Masukkan judul survey'
        isFormValid = false
      } else {
        this.$store.state.create.warning.title = ''
      }

      if (!this.isDescriptionValid()) {
        this.$store.state.create.warning.description =
          'Masukkan deskripsi survey'
        isFormValid = false
      } else {
        this.$store.state.create.warning.description = ''
      }

      if (!this.isKriteriaValid()) {
        this.$store.state.create.warning.fillerCriteria =
          'Masukkan kriteria responden'
        isFormValid = false
      } else {
        this.$store.state.create.warning.fillerCriteria = ''
      }

      if (!this.isFormLinkValid()) {
        this.$store.state.create.warning.formLink =
          'Format link Google Form tidak valid'
        isFormValid = false
      } else {
        this.$store.state.create.warning.formLink = ''
      }

      if (!this.isHalamanValid()) {
        this.$store.state.create.warning.totalPages =
          'Masukkan total halaman google form'
        isFormValid = false
      } else {
        this.$store.state.create.warning.totalPages = ''
      }

      if (!this.isEstFillTimeValid()) {
        this.$store.state.create.warning.estFillTime =
          'Masukkan estimasi waktu pengisian'
        isFormValid = false
      } else {
        this.$store.state.create.warning.estFillTime = ''
      }

      if (!this.isJumlahValid()) {
        this.$store.state.create.warning.respondentTarget =
          'Masukkan jumlah responden'
        isFormValid = false
      } else {
        this.$store.state.create.warning.respondentTarget = ''
      }

      if (!this.isDurasiValid()) {
        this.$store.state.create.warning.duration = 'Masukkan durasi survey'
        isFormValid = false
      } else {
        this.$store.state.create.warning.duration = ''
      }

      return isFormValid
    },
    goToNextSlide() {
      let isFormValid = this.validation()
      let isSlideTwo = this.isSlideTwo
      if (isFormValid) {
        this.nextSlide(isSlideTwo)
        this.isFormTwoValid(isFormValid)
      }
    },
    autoFillPlaceholder(placeholder) {
      return this.$store.state.create.isScrapping ? 'Importing...' : placeholder
    },
    importDataFromUrl() {
      if (!this.isFormLinkValid()) {
        this.$store.state.create.warning.formLink =
          'Format link Google Form tidak valid'
      } else {
        this.$store.state.create.warning.formLink = ''
        this.$store.dispatch('create/SCRAPPE_SURVEY_FORM')
      }
    },
    showIframePopup() {
      if (this.isFormLinkValid()) {
        this.isPopupVisible = true
		this.$store.state.create.warning.formLink = ''
		document.body.className = 'overflow-hidden'
      } else {
        this.$store.state.create.warning.formLink =
          'Format link Google Form tidak valid'
      }
    },
    hideIframePopup() {
      console.log('hideIframePopup')
      this.isPopupVisible = false
    },
  },
}
</script>

<style scoped>
.container {
  padding: 20px;
}
a {
  text-decoration: none;
}

input,
textarea {
  outline: none;
}
.content {
  width: 70%;
  min-width: 600px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 2em;
  background-color: #ffffff;
  border-radius: 16px;
  border: 1px solid rgba(52, 54, 56, 0.24);
  padding: 2em;
  margin-bottom: 20px;
}
h3 {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 1.2rem;
}
label {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 0.9rem;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='text'],
textarea[type='text'],
input[type='number'] {
  padding: 1em;
  border-radius: 4px;
  border: none;
  font-size: 0.9rem;
  background-color: #eaeaea;
  width: 100%;
}
.judul {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  width: 100%;
  min-width: 100px;
}
.input p {
  top: 15px;
}
.kriteria-responden,
.deskripsi {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}
#title {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  white-space: nowrap;
}
.kriteria-responden p:nth-of-type(2) {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-style: italic;
  color: rgba(0, 0, 0, 0.5);
  font-size: 0.8rem;
  white-space: nowrap;
}
.kr-text {
  display: flex;
  justify-content: space-between;
  gap: 0.25em;
  pointer-events: none;
  align-items: flex-end;
}
.textarea,
.input {
  position: relative;
}
.textarea p,
.input p {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.5);
}
.link {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}
#gform {
  word-wrap: break-word;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  font-size: 0.8rem;
}
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 2em;
}
.halaman-n-waktu,
.responden-n-durasi {
  display: flex;
  gap: 2em;
}
.halaman,
.waktu,
.responden,
.durasi {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}
.input-field,
.textarea-field {
  width: 100%;
}
.input-wrapper {
  display: flex;
  align-items: center;
}
.input-wrapper .input-field {
  flex-grow: 1;
}
.buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2em;
  padding-top: 2em;
  padding-bottom: 5em;
  margin: auto;
}
.buttons button,
.buttons .el-button {
  padding: 1em 2em;
  min-width: 50px;
  border: 0.5px solid #35158c;
  border-radius: 8px;
  background-color: #35158c;
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 1rem;
}

.buttons button:nth-child(1) {
  color: black;
  background-color: white;
}
.buttons .el-button {
  background-color: #35158c;
  color: #ffffff;
}
.buttons .el-button:hover {
  background-color: #2a0e60;
}
.input,
.textarea {
  margin-bottom: 1em;
}
input:hover,
button:hover {
  cursor: pointer;
}
.warning {
  font-size: 0.9rem;
  color: red;
}
.judul,
.deskripsi,
.kriteria-responden,
.link,
.wrapper {
  margin-bottom: 20px;
}
.icon-button:hover {
  background: none;
}
.icon-button {
  background: none;
  cursor: pointer;
  outline: none;
  border:none;
  /* margin-left: 8px; */
}
.icon-button unicon {
  color: #35158c;
}
textarea {
  position: relative;
}
.textarea-field {
  width: 100%;
  resize: none;
}
@media screen and (max-width: 1000px) {
  .content {
    padding: 2em 1em;
    width: 85%;
    min-width: 100px;
  }
  .halaman-n-waktu,
  .responden-n-durasi {
    flex-direction: column;
  }
  .halaman,
  .waktu,
  .responden,
  .durasi {
    width: 100%;
  }
}
</style>
