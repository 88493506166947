
import API_SERVICE from '../../../service/api/index'

const GET_FILLED_SURVEYS = (params, onSuccess, onError) => {
    const apiDetail = API_SERVICE.API_URL.USER_FILLED
    return API_SERVICE.GET({
      apiUrl: apiDetail.API,
      params,
      onSuccess,
      onError,
    })
  }

  const GET_CREATED_SURVEYS = (params, onSuccess, onError) => {
    const apiDetail = API_SERVICE.API_URL.USER_CREATED

    return API_SERVICE.GET({
      apiUrl: apiDetail.API,
      params,
      onSuccess,
      onError,
    })
  }
  const GET_SURVEYJS_DETAIL = (params, onSuccess, onError) => {
    const apiDetail = API_SERVICE.API_URL.SURVEYS_JS
    return API_SERVICE.GET({
      apiUrl: apiDetail.API,
      params,
      onSuccess,
      onError,
    })
  }
  const GET_RESPONSES = (params, onSuccess, onError) => {
    const apiDetail = API_SERVICE.API_URL.RESPONSES
    return API_SERVICE.GET({
      apiUrl: apiDetail.API,
      params,
      onSuccess,
      onError,
    })
  }
  const GET_DASHBOARD_PROFILE = (params, onSuccess, onError) => {
    const apiDetail = API_SERVICE.API_URL.DASHBOARD_PROFILE

    return API_SERVICE.GET({
      apiUrl: apiDetail.API,
      params,
      onSuccess,
      onError,
    })
  }
  export default { GET_RESPONSES,GET_SURVEYJS_DETAIL,GET_FILLED_SURVEYS, GET_CREATED_SURVEYS,GET_DASHBOARD_PROFILE }