<template>
  <div class="flex flex-col items-center justify-center pt-24 pb-36">
    <h1 class="py-5 text-3xl text-semibold">Perbarui Password</h1>
    <div class="w-3/4 py-3 md:w-1/3">
      <span class="flex items-center">Email </span>
      <el-input
        v-model="$store.state.forgotPassword.form.email"
        class="mt-2"
        placeholder="Masukkan email kamu"
        clearable
      >
      </el-input>
      <span class="h-3 text-xs warning">{{
        $store.state.forgotPassword.form.emailWarning
      }}</span>
    </div>
    <el-button
      :disabled="$store.state.forgotPassword.isCooldown"
      :class="{ 'opacity-40': $store.state.forgotPassword.isCooldown }"
      class="w-3/4 mt-5 md:w-1/3"
      type="primary"
      @click="sentEmail"
      >Kirim</el-button
    >
    <p
      v-show="$store.state.forgotPassword.isCooldown"
      class="mt-2 cooldown-text"
    >
      <span v-show="$store.state.forgotPassword.success"
        >Email Telah Dikirim,</span
      >
      Tunggu {{ $store.state.forgotPassword.cooldownTime }}s sebelum dapat
      mengirim kembali
    </p>
  </div>
</template>

<script>
import { isEmail } from '@libs/util'

export default {
  methods: {
    sentEmail() {
      this.validateEmail()
      if (!this.$store.state.forgotPassword.form.emailWarning) {
        this.$store.dispatch('forgotPassword/GENERATE_SECRET_EMAIL_URL')
      }
    },
    validateEmail() {
      if (!this.isEmailValid()) {
        this.$store.state.forgotPassword.form.emailWarning = 'Email tidak valid'
      } else {
        this.$store.state.forgotPassword.form.emailWarning = ''
      }
    },
    isEmailValid() {
      return (
        this.$store.state.forgotPassword.form.email.length > 0 &&
        isEmail(this.$store.state.forgotPassword.form.email)
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.anchor {
  color: $grey;
  padding-bottom: 2px;
  font-size: 0.9rem;
  cursor: pointer;
  &:hover {
    color: $blue-light;
    border-bottom: 1px solid $blue-light;
  }
}

.cooldown-text {
  color: $grey;
  font-size: 0.9rem;
}
</style>
