<template>
  <div class="modal" @click="onClose">
    <div class="modal-box" @click.stop>
      <div class="modal-row text-xs md:text-sm">
        <div class="modal-title"><span>Filter Survey</span></div>
        <div class="modal-close" @click="onClose">&#x2715;</div>
      </div>
      <div class="modal-content">
        <div class="modal-row__sort text-xs md:text-sm my-3">
          <div class="modal-row">
            <span class="modal-row__label">Urutkan berdasarkan:</span>
          </div>
          <div class="modal-row">
            <unicon name="sort-amount-down" fill="gray" />
            <v-select
              v-model="$store.state.fillSurveyList.filter.orderBy"
              :clearable="false"
              :options="$store.state.fillSurveyList.filterOption.orderBy"
            ></v-select>
          </div>
        </div>
        <div class="modal-row text-xs md:text-sm my-3">
          <el-switch
            v-model="filter.openOnly"
            active-text="Tunjukkan survey yang masih dibuka saja"
            active-color="#5d8bd7"
          ></el-switch>
        </div>
        <div class="modal-row text-xs md:text-sm my-5">
          <div class="divider"></div>
        </div>
        <div class="modal-row text-xs md:text-sm">
          <button class="btn-cancel" @click="onReset">Reset</button>
          <button class="btn-submit" @click="onApply">Apply</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    filter() {
      return this.$store.state.fillSurveyList.filter
    },
    filterOption() {
      return this.$store.state.fillSurveyList.filterOption
    },
  },
  methods: {
    closeDialog() {
      this.$store.state.fillSurveyList.isFilterModalOpen = false
    },
    resetPagination() {
      this.$store.state.fillSurveyList.filter.pageNo = '1'
    },
    formatReward(val) {
      return formatIDR(val)
    },
    onClose() {
      this.closeDialog()
    },
    onReset() {
      window.location.reload()
    },
    onApply() {
      this.resetPagination()
      this.$store.dispatch('fillSurveyList/GET_SURVEY_LIST')
      this.closeDialog()
    },
  },
}
</script>

<style lang="scss" scoped>
.modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 150px 0 200px 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
  transition: all 0.3s;

  .modal-box {
    width: 40%;
    height: 90%;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 15px;
    padding: 25px;
    transition: all 0.3s;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);

    @media (max-width: 640px) {
      width: 95%;
    }
  }

  .modal-title {
    margin-bottom: 20px;
    margin-right: auto;

    span {
      font-size: 1.5rem;
      font-weight: 900 !important;
    }
  }

  .modal-close {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 20px;
    cursor: pointer;
  }

  .modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  .modal-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0;

    .modal-row__label {
      color: $grey;
      font-size: 12px;
    }
  }

  .modal-row__sort {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    span {
      margin-bottom: 10px;
    }
  }

  .btn-cancel,
  .btn-submit {
    width: 40%;
    height: 35px;
    border-radius: 5px;
    border: none;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s;
  }

  .btn-cancel {
    margin-right: auto;
    outline: solid 1px $warning;
    color: $warning;
    &:hover {
      background-color: $warning;
      color: $white;
    }
  }

  .btn-submit {
    background: $success;
    color: $white;
    &:hover {
      background: $success-light;
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: rgb(235, 235, 235);
  }
}
</style>

<style lang="scss">
.modal {
  .v-select {
    width: 100%;
    margin-left: 10px;
  }

  .el-switch__label * {
    font-size: 12px !important;
  }

  .el-switch__label {
    color: $grey-2 !important;
  }

  .el-switch__label.is-active {
    color: $grey !important;
  }
}
</style>
