import API from '../Service/api'
import { parseISOTime } from '@libs/util'

const GET_SURVEY_DETAIL = (context) => {
    let params = {
        id: context.state.id,
    }
    API.GET_SURVEY_DETAIL(params)
        .then((res) => {
            context.state.hostFullName = res.data.hostFullName
            context.state.hostPhoneNumber = res.data.hostPhoneNumber
            context.state.isFilled = res.data.isFilled
            context.state.detail = res.data.survey
            context.state.detail.build = res.data.build
            if (context.state.detail.endAt) {
                context.state.detail.endAt = parseISOTime(context.state.detail.endAt)
            }

            context.state.formLoaded = true
                // context.state.formLink = context.state.formLink.String;
            context.state.isGform = true
            GET_SURVEY_WINNER(context)
        })
        .catch((err) => {
            console.error(err)
        })
}

const GET_SURVEYJS_DETAIL = (context) => {
    let params = {
        form_link: context.state.detail.formLink
    }
    API.GET_SURVEYJS_DETAIL(params)
        .then((res) => {
            context.state.hostFullName = res.data.hostFullName
            context.state.hostPhoneNumber = res.data.hostPhoneNumber
            context.state.isFilled = res.data.isFilled
            context.state.detail = res.data.survey
            context.state.detail.build = res.data.build
            context.state.detail.endAt = parseISOTime(context.state.detail.endAt)

            context.state.detail.build.completedHtml = (context.state.detail.build.completedHtml ? context.state.detail.build.completedHtml : '') + "<span> jangan lupa untuk click tombol submit dibawah untuk di data sebagai pemenang potensial</span>",

                context.state.formLoaded = true
                // context.state.formLink = context.state.formLink.String;
            context.state.isGform = false
            GET_SURVEY_WINNER(context)
        })
        .catch((err) => {
            console.error(err)
        })
}


const GET_SURVEY_WINNER = (context) => {
    let params = {
        id: context.state.id,
    }

    API.GET_SURVEY_WINNER(params)
        .then((res) => {
            const winnerNames = res.data.winner_names.split(',')
            const winnerIDs = res.data.winner_ids.split(',')
            context.state.winners = winnerIDs.map((id, index) => ({
                id: id,
                name: winnerNames[index],
            }))
        })
        .catch((err) => {
            console.error(err)
        })
}

const FILL_SURVEY = (context) => {
    let params = {
        survey_id: context.state.detail.id,
        respond: context.state.respond
    }
    API.FILL_SURVEY(params)
        .then((_) => {
            GET_ALL_FILLED_SURVEY(context)
        })
        .catch((err) => {
            console.error(err)
        })
}

const GET_ALL_FILLED_SURVEY = (context) => {
    context.state.loading = true
    API.GET_ALL_FILLED_SURVEY({}).then((res) => {
        localStorage.setItem('filled-survey', res.data.filled_survey_id)
        context.state.isFilled = true
        context.state.isLoading = false
        context.state.success = true
    })
}

export default { GET_SURVEY_DETAIL, GET_SURVEY_WINNER, FILL_SURVEY, GET_SURVEYJS_DETAIL }