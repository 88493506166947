import API from '../Service/api'
import { parseISOTime } from '@libs/util'

const GET_FILLED_SURVEYS = (context) => {
    let params = {
        page_no: parseInt(context.state.filter.pageNo),
        page_size: context.state.filter.pageSize,
      }
    API.GET_FILLED_SURVEYS(params)
      .then((res) => {
        context.state.listFilled = res.data.surveys
      })
      .catch((err) => {
        console.error(err)
      })
  }

  const GET_RESPONSES = (context) => {
    let params = {
      survey_id: context.state.detail.id,

    }
    API.GET_RESPONSES(params)
      .then((res) => {
        context.state.listReponses = res.data.responds

        context.state.onSuccess(res.data.responds)
      })
      .catch((err) => {
        console.error(err)
      })
  }
  
  const GET_SURVEYJS_DETAIL = (context) => {
    let params = {
      form_link: context.state.detail.formLink
    }
    API.GET_SURVEYJS_DETAIL(params)
      .then((res) => {
        context.state.detail = res.data.survey
        context.state.detail.build = res.data.build
        context.state.detail.endAt = parseISOTime(context.state.detail.endAt)
        
        GET_RESPONSES(context)
        // let now = moment()
        // let end = moment(context.state.detail.endAt)
        // let timeLeft = moment.duration(end.diff(now)).asMilliseconds()
        // if (res.data.survey.reward > 0 && timeLeft < 0) {
        //   GET_SURVEY_WINNER(context)
        // }
      })
      .catch((err) => {
        console.error(err)
      })
  }
  
  const GET_CREATED_SURVEYS = (context) => {
    let params = {
        page_no: parseInt(context.state.filter.pageNo),
        page_size: context.state.filter.pageSize,
      }
    API.GET_CREATED_SURVEYS(params)
      .then((res) => {
        context.state.listCreated = res.data.surveys
      })
      .catch((err) => {
        console.error(err)
      })
  }
  const GET_DASHBOARD_PROFILE = (context) => {
    
    API.GET_DASHBOARD_PROFILE({})
      .then((res) => {
        context.state.createdCount = res.data.created
        context.state.filledCount = res.data.filled
        context.state.pointCount = res.data.point
      })
      .catch((err) => {
        console.error(err)
      })
  }

  export default {
    GET_CREATED_SURVEYS,
    GET_FILLED_SURVEYS,
    GET_RESPONSES,
    GET_SURVEYJS_DETAIL,
    GET_DASHBOARD_PROFILE
  }