const defaultState = () => {
    return {
        loading: false,
        surveyList: [],
        surveyTotal: 0,
        isFilterModalOpen: false,
        isFilledAddData: false,
        filter: {
            pageNo: '1',
            pageSize: 6,
            pagerCount: 5,
            title: '',
            openOnly: true,
            orderBy: {
                label: 'Tanggal dibuat',
                code: 'created_at,desc',
            },
            sortBy2: {
                label: 'Menurun',
                code: 'desc'
            },
            orderBy2: {
                label: 'Tanggal dibuat',
                code: 'created_at'
            },
        },
        filterOption: {
            orderBy: [
                { label: 'Default', code: 'created_at,desc' },
                { label: 'Jumlah insentif (terbesar)', code: 'reward,desc' },
                { label: 'Jumlah insentif (terkecil)', code: 'reward,asc' },
                { label: 'Tanggal dibuat (terbaru)', code: 'created_at,desc' },
                { label: 'Tanggal dibuat (terlama)', code: 'created_at,asc' },
                { label: 'Tanggal berakhir (terdekat)', code: 'end_at,asc' },
                { label: 'Tanggal berakhir (terlama)', code: 'end_at,desc' },
            ],
            sortBy2: [
                { label: 'Menurun', code: 'desc' },
                { label: 'Menaik', code: 'asc' },
            ],
            orderBy2: [
                { label: 'Tanggal dibuat', code: 'created_at' },
                { label: 'Insentif', code: 'reward' },
                { label: 'Tenggat waktu', code: 'end_at' },
                { label: 'Responden', code: 'participant_needed' },
            ],
        },

        // TODO: Remove in the future, Additional User Data
        isAddtDataModalOpen: false,
        addtData: {
            isLoading: false,
            isUpdateSuccess: false,
            phoneNumber: '',
            occupation: 'Mahasiswa',
            paymentType: 'Bank',
            bankType: 'Bank BCA',
            paymentNumber: '',
        },
        addtDataError: {
            phoneNumber: '',
            paymentNumber: '',
        },
        addtDataOption: {
            occupation: [
                'Mahasiswa',
                'Pelajar SD/SMP/SMA',
                'Guru/Dosen',
                'Karyawan Swasta',
                'Pegawai Negeri Sipil',
                'Wiraswasta',
                'Pensiunan',
                'Lainnya',
                'Tidak Bekerja',
            ],
            paymentType: ['Bank', 'Gopay', 'OVO', 'ShopeePay', 'DANA'],
            bankType: [
                'Bank BCA',
                'Bank BNI',
                'Bank Mandiri',
                'Bank BRI',
                'BCA Digital',
                'Bank BNI Syariah',
                'Bank Mandiri Syariah',
                'Bank BRI Syariah',
                'Bank BCA Syariah',
                'Bank CIMB Niaga',
                'Bank OCBC NISP',
                'Bank Panin',
                'Bank Muamalat',
                'Bank Jago',
                'Digibank (DBS)',
                'Jenius (BTPN)',
                'HANA Bank (LINE Bank)',
                'Seabank',
            ],
        },
    }
}

export default defaultState