<template>
  <div class="flex flex-col items-center justify-center py-12">
    <h1 class="text-3xl text-semibold py-5">Buat Survey</h1>
    <div class="w-3/4 md:w-1/3 py-3">
      <span>Judul</span>
      <el-input
        v-model="$store.state.create.form.title"
        class="mt-2"
        placeholder="Masukkan judul surveymu"
        clearable
        maxlength="100"
        show-word-limit
      >
      </el-input>
      <span class="warning text-xs h-3">{{
        $store.state.create.warning.title
      }}</span>
    </div>
    <div class="w-3/4 md:w-1/3 py-3">
      <span class="flex items-center"> Deskripsi </span>
      <el-input
        v-model="$store.state.create.form.description"
        class="mt-2"
        :autosize="{ minRows: 3, maxRows: 5 }"
        type="textarea"
        placeholder="Masukkan deskripsi tentang surveymu"
        maxlength="1000"
        show-word-limit
      >
      </el-input>
      <span class="warning text-xs h-3">{{
        $store.state.create.warning.description
      }}</span>
    </div>
    <div class="w-3/4 md:w-1/3 py-3">
      <span class="flex items-center">
        Link Google Form
        <el-tooltip placement="top" tabindex="-1">
          <template #content>
            Gunakan format seperti
            https://docs.google.com/forms/d/e/ABCD/viewform?usp=sf_link
          </template>
          <img
            class="ml-1 w-5"
            src="@assets/images/icon_information.jpg"
            alt="Info"
          />
        </el-tooltip>
      </span>
      <el-input
        v-model="$store.state.create.form.formLink"
        class="mt-2 mb-1"
        placeholder="Masukkan link google form surveymu"
        clearable
        maxlength="200"
        show-word-limit
      >
      </el-input>
      <span class="example subtitle">
        Contoh: https://docs.google.com/forms/d/e/ABCD/viewform?usp=sf_link </span
      ><br />
      <span class="warning text-xs h-3">{{
        $store.state.create.warning.formLink
      }}</span>
    </div>
    <div class="flex flex-col w-3/4 md:w-1/3 py-3">
      <span class="flex items-center">
        Jumlah Halaman Google Form
        <el-tooltip placement="top" tabindex="-1">
          <template #content>
            Sesuaikan dengan jumlah halaman (section) Google Form. Maksimal 10
            halaman.
          </template>
          <img
            class="ml-1 w-5"
            src="@assets/images/icon_information.jpg"
            alt="Info"
          />
        </el-tooltip>
      </span>
      <el-input-number
        v-model="$store.state.create.form.totalPages"
        class="mt-2"
        :min="1"
        :max="10"
      >
      </el-input-number>
    </div>
    <div class="flex flex-col w-3/4 md:w-1/3 py-3">
      <span class="flex items-center">
        Durasi Survey (Hari)
        <el-tooltip placement="top" tabindex="-1">
          <template #content>
            Durasi survey akan dibuka. Maksimal 21 hari.
          </template>
          <img
            class="ml-1 w-5"
            src="@assets/images/icon_information.jpg"
            alt="Info"
          />
        </el-tooltip>
      </span>
      <el-input-number
        v-model="$store.state.create.form.duration"
        class="mt-2"
        :min="1"
        :max="21"
      >
      </el-input-number>
    </div>
    <div class="flex flex-col w-3/4 md:w-1/3 py-3">
      <span class="flex items-center">
        Target Jumlah Responden
        <el-tooltip placement="top" tabindex="-1">
          <template #content> Jumlah responden yang kamu harapkan. </template>
          <img
            class="ml-1 w-5"
            src="@assets/images/icon_information.jpg"
            alt="Info"
          /> </el-tooltip
      ></span>
      <el-input-number
        v-model="$store.state.create.form.respondentTarget"
        class="mt-2"
        :min="1"
        :step="10"
      >
      </el-input-number>
    </div>
    <div class="tnc subtitle flex flex-col items-start mt-5 w-3/4 md:w-1/3">
      <label class="text-xs md:text-sm">
        <input v-model="isOfferMoney" type="checkbox" />
        Saya ingin menawarkan insentif pada survey saya.
      </label>
    </div>
    <div
      v-if="isOfferMoney"
      class="money-box w-3/4 md:w-1/3 flex flex-col items-center mt-4 p-3"
    >
      <div class="flex flex-col w-full py-3">
        <span class="flex items-center">
          Total Jumlah Insentif
          <el-tooltip placement="top" tabindex="-1">
            <template #content>
              Total insentif untuk dibagi sesuai jumlah pemenang. Jangan gunakan
              tanda baca. Minimal Rp25.000.
            </template>
            <img
              class="ml-1 w-5"
              src="@assets/images/icon_information.jpg"
              alt="Info"
            />
          </el-tooltip>
        </span>
        <el-input
          v-model="$store.state.create.form.reward"
          class="mt-2"
          placeholder="Masukkan total hadiah"
          type="number"
          :min="25000"
          :step="10000"
        >
          <template #prepend>Rp</template>
        </el-input>
        <span class="warning text-xs h-3 mt-2">{{
          $store.state.create.warning.reward
        }}</span>
      </div>
      <div class="flex flex-col w-full py-3">
        <span class="flex items-center">
          Jumlah Pemenang
          <el-tooltip placement="top" tabindex="-1">
            <template #content>
              Jumlah orang yang akan memenangkan insentif. Minimal 1 orang.
              Maksimal 5 orang.
            </template>
            <img
              class="ml-1 w-5"
              src="@assets/images/icon_information.jpg"
              alt="Info"
            /> </el-tooltip
        ></span>
        <el-input-number
          v-model="$store.state.create.form.countWinner"
          class="mt-2"
          :min="1"
          :max="5"
        >
        </el-input-number>
      </div>
      <div class="flex flex-col w-full py-3">
        <span class="flex items-center">
          Bukti Pembayaran Insentif
          <el-tooltip placement="top" tabindex="-1">
            <template #content>
              Bukti Pembayaran Insentif yang Kamu Tawarkan ke Rekening BCA
              Digital 007222868349 a/n David Gautama
            </template>
            <img
              class="ml-1 w-5"
              src="@assets/images/icon_information.jpg"
              alt="Info"
            />
          </el-tooltip>
        </span>
        <span class="text-xs subtitle mt-1 mb-5">
          BCA Digital 007222868349 a/n David Gautama</span
        >
        <a :href="paymentLink" target="_blank" @click="isClickPayment = true">
          <el-button type="info" round> Unggah Bukti Pembayaran </el-button>
        </a>
        <span class="warning text-xs h-3 mt-2">{{
          $store.state.create.warning.payment
        }}</span>
      </div>
    </div>
    <div class="tnc subtitle flex flex-col items-start mt-5 w-3/4 md:w-1/3">
      <label class="text-xs md:text-sm mb-2">
        <input v-model="isAgreeTnc" type="checkbox" />
        Saya menyetujui
        <a class="tnc-anchor" :href="tncLink" target="_blank">
          syarat dan ketentuan
        </a>
        dari Kudata
      </label>
      <span class="warning text-xs h-3">{{
        $store.state.create.warning.tnc
      }}</span>
    </div>
    <div class="flex flex-col w-3/4 md:w-1/3 py-1">
      <span class="text-sm subtitle mt-2">
        Setelah terverifikasi, surveymu akan ditampilkan di Kudata maksimal
        dalam waktu 2x24 jam
      </span>
    </div>
    <el-button
      class="w-3/4 md:w-1/3 mt-7"
      type="primary"
      :loading="$store.state.create.isLoading"
      @click="create"
    >
      Buat
    </el-button>
    <el-dialog
      v-model="$store.state.create.isCreateSuccess"
      title="Berhasil"
      width="70%"
      :show-close="false"
    >
      <span>
        Survey mu telah berhasil diajukan! Setelah terverifikasi, surveymu akan
        diproses dan ditampilkan di Kudata maksimal dalam waktu 2x24 jam
      </span>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="success" @click="goTo('fill')"> Ok </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isAgreeTnc: false,
      isClickPayment: false,
      isOfferMoney: false,
      tncLink:
        'https://docs.google.com/document/d/144YojRZ_jR-G460FEZqMip7WB5DPC7bOHARvTDem50Y/edit?usp=sharing',
      paymentLink:
        'https://docs.google.com/forms/d/e/1FAIpQLSdGdCgVccwxpp8nrVae7IYa05ZiqiqwwRc5YtEbKeEm1hzdnQ/viewform',
    }
  },
  methods: {
    create() {
      let isFormValid = this.validateCreate()
      if (isFormValid) {
        this.$store.dispatch('create/CREATE_SURVEY_SHEET')
      }
    },
    validateCreate() {
      let isFormValid = true
      if (!this.isTitleValid()) {
        this.$store.state.create.warning.title = 'Masukkan judul survey'
        isFormValid = false
      } else {
        this.$store.state.create.warning.title = ''
      }
      if (!this.isDescriptionValid()) {
        isFormValid = false
        this.$store.state.create.warning.description =
          'Masukkan deskripsi survey'
      } else {
        this.$store.state.create.warning.description = ''
      }
      if (!this.isFormLinkValid()) {
        isFormValid = false
        this.$store.state.create.warning.formLink =
          'Format link Google Form tidak valid'
      } else {
        this.$store.state.create.warning.formLink = ''
      }
      if (!this.isRewardValid()) {
        isFormValid = false
        this.$store.state.create.warning.reward = 'Jumlah insentif tidak valid'
      } else {
        this.$store.state.create.warning.reward = ''
      }
      if (!this.isTnCValid()) {
        isFormValid = false
        this.$store.state.create.warning.tnc =
          'Harap setujui syarat dan ketentuan'
      } else {
        this.$store.state.create.warning.tnc = ''
      }
      if (!this.isPaymentValid()) {
        isFormValid = false
        this.$store.state.create.warning.payment =
          'Jangan lupa untuk mengunggah bukti pembayaran insentif ya!'
      } else {
        this.$store.state.create.warning.payment = ''
      }
      return isFormValid
    },
    isTitleValid() {
      return this.$store.state.create.form.title
    },
    isDescriptionValid() {
      return this.$store.state.create.form.description
    },
    isFormLinkValid() {
      return this.$store.state.create.form.formLink.includes(
        'https://docs.google.com/forms/'
      )
    },
    isRewardValid() {
      let reward = this.$store.state.create.form.reward
      return (
        !this.isOfferMoney ||
        (parseInt(reward) >= 25000 && !reward.toString().includes('.'))
      )
    },
    isTnCValid() {
      return this.isAgreeTnc
    },
    isPaymentValid() {
      return !this.isOfferMoney || this.isClickPayment
    },
    goTo(path) {
      if (path === 'fill') window.location.href = '/fill'
      else this.$router.push({ path: path })
    },
  },
}
</script>

<style lang="scss" scoped>
.anchor {
  color: $grey;
  padding-bottom: 2px;
  font-size: 0.9rem;
  cursor: pointer;
  &:hover {
    color: $blue-light;
    border-bottom: 1px solid $blue-light;
  }
}

.example {
  font-size: 0.75rem;
}

.tnc {
  color: $grey;

  input {
    vertical-align: -2px;
    cursor: pointer;
  }

  .tnc-anchor {
    color: $secondary;
    cursor: pointer;
  }
}

.el-button--info {
  background: $secondary !important;
  border-color: $secondary !important;
  transition: 0.2s;

  &:hover,
  &:active,
  &:focus {
    transition: 0.2s;
    background: $secondary-light !important;
    border-color: $secondary-light !important;
  }
}

.money-box {
  outline: 1px dashed rgb(189, 189, 189);
  border-radius: 10px;
}
</style>
