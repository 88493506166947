<template>
  <div>
  <div class="w-full pt-5 flex" style="background-color:#FFFFFF">
      <!-- go back button -->
      <button type="button" class="flex mx-10" @click="goBack">
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 16C0 11.7565 1.68571 7.68687 4.68629 4.68629C7.68687 1.68571 11.7565 0 16 0C18.1012 0 20.1817 0.413852 22.1229 1.21793C24.0641 2.022 25.828 3.20055 27.3137 4.68629C28.7994 6.17203 29.978 7.93586 30.7821 9.87706C31.5861 11.8183 32 13.8989 32 16C32 20.2435 30.3143 24.3131 27.3137 27.3137C24.3131 30.3143 20.2435 32 16 32C13.8989 32 11.8183 31.5861 9.87706 30.7821C7.93586 29.978 6.17203 28.7994 4.68629 27.3137C1.68571 24.3131 0 20.2435 0 16ZM25.6 14.4H12.8L18.4 8.8L16.128 6.528L6.656 16L16.128 25.472L18.4 23.2L12.8 17.6H25.6V14.4Z" fill="#9F7DFF" fill-opacity="0.5"/>
        </svg>
      </button>
      <!-- title and creator container -->
      <div class="container">
        <div class="p-1 text-start flex flex-row">
            <div class="text-indigo-700 font-bold text-5xl ">
              {{ getSurveyTitle() }}
            </div>
            
        </div>
        
      </div>
      <!-- Reward container -->
      <div class="container p-5">
       
          <div class="flex flex-row items-center mt-2">
            <p class="subtitle mr-3">Total responden</p>
          </div>

            <div class="flex flex-row items-center mt-1 mb-4 ">
              
              <div class="count-winner-badge rounded ml-2 px-2 py-1">
                <p>{{ getRespondens().length +" "}} Orang</p>
              </div>
              <el-button v-if="getRespondens().length>0" class="ml-12" :loading="$store.state.respond.isLoading" @click="downloadExcel()">
           Download Data
          
          </el-button>
            </div>

        </div>
      <!-- Duration Container -->
      <div class="container p-5 text-xl">
       <div v-if="!hasEnded()" class="bg-green-500 text-white p-3 rounded flex justify-center">
          Sedang tayang
          </div>
          <div v-else class="bg-gray-400 text-white p-3 rounded flex justify-center">
          Sudah selesai
          </div>
          
      </div>
     
    </div>
  <div id="surveyVizPanel"></div>
  </div>
</template>

<script>
  import "survey-analytics/survey.analytics.css";
  import "tabulator-tables/dist/css/tabulator.css";
  import "survey-analytics/survey.analytics.tabulator.css";
  import { Model } from "survey-core";
  import { VisualizationPanel } from 'survey-analytics';
  import moment from 'moment'
  import * as XLSX from 'xlsx'


  export default {
      mounted() {
          this.$store.state.respond.onSuccess = this.onSuccess
        
          this.$store.state.respond.detail.build = ''
          let currPath = window.location.href.split('/')
          this.$store.state.respond.detail.formLink = currPath[currPath.length - 1]
          this.$store.dispatch('respond/GET_SURVEYJS_DETAIL')
      },

      methods:{
         goBack() {
          this.$router.push({ path: '/profile' })
        },
        getSurveyTitle() {
          return this.$store.state.respond.detail.build.title??"Untitled";
        },
        getRespondens() {
          return this.$store.state.respond.listReponses;
        },
        getEndsAt() {
          return this.$store.state.respond.detail.endAt;
        },
        downloadExcel(){
          this.$store.state.respond.isLoading = true;
          let to_print = []
          this.$store.state.respond.listReponses.forEach(a=>{
            let to_add = {};
            a.respond_elements.forEach((e)=>{
              to_add[e.question]=e.answer;
              });
            to_print.push(to_add);
              
          });
        
          const data = XLSX.utils.json_to_sheet(to_print)
          const wb = XLSX.utils.book_new()
          XLSX.utils.book_append_sheet(wb, data, 'data')
          XLSX.writeFile(wb,`${this.$store.state.respond.detail.title}-${(new Date().toJSON().slice(0,10))}.xlsx`)
          this.$store.state.respond.isLoading = false;
        },
         hasEnded(){
          let now = moment()
          let end = moment(this.$store.state.respond.detail.endAt)
          let timeLeft = moment.duration(end.diff(now)).asMilliseconds()
          return timeLeft < 0;
        },
        onSuccess(dataResponses){
          
          let data = []
          let data_question = []
          // let itr = 0;
          
          this.$store.state.respond.detail.build.pages.
          flatMap(a=>a.elements)
          .forEach((e,idx)=>{
            // console.log(a.elements.length)
           
              data_question[idx] = JSON.parse(JSON.stringify(e));
                  // console.log(idx)
               if(data_question[idx].type=='imagepicker'){
                data_question[idx].choices = data_question[idx].choices.map(f=>f.flatMap(g=>({[g.Key]:g.Value}))).map(g=>{g[0]['imageLink']=g[1]['imageLink']; return g[0];}).filter(g=>g.imageLink!=null)
              
              }
              // itr++;
            
          })
          // console.log(data_question)
          dataResponses.forEach(a=>{
            let to_add = {};
            a.respond_elements.forEach((e,idx)=>{
              // console.log(data_question[idx],idx)
              // debugger
              if(data_question[idx].type=='checkbox' ||
              data_question[idx].type=='tagbox' || 
              data_question[idx].type=='ranking' 
              ){
                to_add[e.question]=e.answer.split(',').map(a=>a.trim());
              }else{
                to_add[e.question]=e.answer;
              }
              // console.log()
            });
            
            data.push(to_add);
              
          });
          
          // console.log(data)
          // console.log(data_question)
        const survey = new Model({'elements':data_question});
        // console.log(data_question)
        const vizPanel = new VisualizationPanel(
          survey.getAllQuestions(),
          data,
          {haveCommercialLicense: true}
          // vizPanelOptions
        );
        // vizPanel.showHeader = false;
        vizPanel.render("surveyVizPanel");
        },
        
      },
   };
</script>
<!-- <style scoped>
</style> -->
<style lang="scss" scoped></style>