import comingSoon from './ComingSoon/Store'
import create from './Create/Store'
import fill from './Fill/Store'
import edit from './Edit/Store'
import fillSurveyList from './FillSurveyList/Store'
import home from './Home/Store'
import login from './Login/Store'
import notfound from './NotFound/Store'
import profile from './Profile/Store'
import register from './Register/Store'
import respond from './Respond/Store'
import forgotPassword from './ForgotPassword/Store'
import resetPassword from './ResetPassword/Store'
import verifyEmail from './VerifyEmail/Store'

export default {
  comingSoon,
  create,
  fill,
  edit,
  fillSurveyList,
  home,
  login,
  notfound,
  profile,
  register,
  respond,
  forgotPassword,
  resetPassword,
  verifyEmail,
}
