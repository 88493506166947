const defaultState = () => {
  return {
	form : {
		newPassword : '',
		confirmationPassword : '',
		newPasswordWarning: '',
		confirmationPasswordWarning: '',
		resetPasswordWarning: '',
	},
	token:'',
	verified : false,
	loading : false,
	success: false,
  }
}

export default defaultState
