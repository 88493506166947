const defaultState = () => {
  return {
    form: {
      fullName: '',
      email: '',
      password: '',
      fullNameWarning: '',
      emailWarning: '',
      passwordWarning: '',
      tncWarning: '',
    },
	isEmailRegistered : false,
    loading: false,
    success: false,
    redirect: '',
	token :''
  }
}

export default defaultState
