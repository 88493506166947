
import API_SERVICE from '../../../service/api/index'

const GET_FILLED_SURVEYS = (params, onSuccess, onError) => {
    const apiDetail = API_SERVICE.API_URL.USER_FILLED
    return API_SERVICE.GET({
      apiUrl: apiDetail.API,
      params,
      onSuccess,
      onError,
    })
  }

  const GET_CREATED_SURVEYS = (params, onSuccess, onError) => {
    const apiDetail = API_SERVICE.API_URL.USER_CREATED

    return API_SERVICE.GET({
      apiUrl: apiDetail.API,
      params,
      onSuccess,
      onError,
    })
  }

  
  const GET_DASHBOARD_PROFILE = (params, onSuccess, onError) => {
    const apiDetail = API_SERVICE.API_URL.DASHBOARD_PROFILE

    return API_SERVICE.GET({
      apiUrl: apiDetail.API,
      params,
      onSuccess,
      onError,
    })
  }

  const GET_USER_INFO = (params, onSuccess, onError) => {
    const apiDetail = API_SERVICE.API_URL.USER_INFO
    return API_SERVICE.GET({
      apiUrl: apiDetail.API,
      params,
      onSuccess,
      onError,
    })
  }
  const UPDATE_USER = (params, onSuccess, onError) => {
    const apiDetail = API_SERVICE.API_URL.USERS
    return API_SERVICE.PUT({
      apiUrl: apiDetail.API,
      params,
      onSuccess,
      onError,
    })
  }
  export default { GET_FILLED_SURVEYS, GET_USER_INFO,UPDATE_USER, GET_CREATED_SURVEYS,GET_DASHBOARD_PROFILE }