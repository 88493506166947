import API_SERVICE from '../../../service/api/index'

const GET_SURVEY_LIST = (params, onSuccess, onError) => {
  const apiDetail = API_SERVICE.API_URL.SURVEYS
  return API_SERVICE.GET({
    apiUrl: apiDetail.API,
    params,
    onSuccess,
    onError,
  })
}

const GET_USER_INFO = (params, onSuccess, onError) => {
  const apiDetail = API_SERVICE.API_URL.USER_INFO
  return API_SERVICE.GET({
    apiUrl: apiDetail.API,
    params,
    onSuccess,
    onError,
  })
}

const UPDATE_USER = (params, onSuccess, onError) => {
  const apiDetail = API_SERVICE.API_URL.USERS
  return API_SERVICE.PUT({
    apiUrl: apiDetail.API,
    params,
    onSuccess,
    onError,
  })
}

export default { GET_SURVEY_LIST, GET_USER_INFO, UPDATE_USER }
