<template>
    <div class="iframe-popup">
        <div class="popup-overlay" @click="close"></div>
        <div class="popup-content">
            <button @click="close" class="close-button">
				<unicon name="times" size="20"></unicon>
			</button>
            <iframe class="w-full rounded-md h-full" :src="link" width="600" height="400" sandbox="allow-same-origin allow-scripts allow-forms" @load="onLoad" @error="onError"></iframe>
			<p class="loading" v-if="isLoading">Loading...</p>
            <p v-if="error" class="error">Failed to load content. Please check the URL.</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'IframePopup',
    props: {
        link: {
            type: String,
            required: true,
            validator(value) {
                return value.includes('https://docs.google.com/forms/') && !value.includes('/edit');
            }
        }
    },
    data() {
        return {
            isLoading: true,
            error: false
        };
    },
    methods: {
        close() {
			document.body.className = ''
            this.$emit('close');
        },
        onLoad() {
            this.isLoading = false;
        },
        onError() {
            this.isLoading = false;
            this.error = true;
            // Delay closing the popup to show the error message
            setTimeout(() => {
                this.close();
            }, 3000);
        }
    },
}
</script>

<style scoped>
.iframe-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
.popup-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
}
.popup-content {
    position: relative;
    background: #fff;
    padding: 1.125rem;
	padding-top: 2.2rem;
    border-radius: 8px;
    z-index: 1001;
	width: 70%;
	margin: auto;
	height: 70%;
}
.close-button {
    position: absolute;
    top: 0.35rem;
    right: 1.125rem;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
	
}
.error {
	position: absolute;
	top: 50%;
	left: 46%;
    color: red;
    font-size: 14px;
}

.loading {
	position: absolute;
	top: 50%;
	left: 46%;
}

@media screen and (max-width: 1000px) {
  .popup-content {
    padding: 2em 1em;
    width: 85%;
  }
}
</style>
