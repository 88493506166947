<template>
  <div class="flex flex-col items-center justify-center py-12">
    <h1 class="py-5 text-3xl text-semibold">Daftar</h1>
    <div class="w-3/4 py-3 md:w-1/3">
      <span>Nama Lengkap</span>
      <el-input
        v-model="$store.state.register.form.fullName"
        class="mt-2"
        placeholder="Masukkan nama lengkap kamu"
        clearable
        @keyup.enter="register"
      >
      </el-input>
      <span class="h-3 text-xs warning">{{
        $store.state.register.form.fullNameWarning
      }}</span>
    </div>
    <div class="w-3/4 py-3 md:w-1/3">
      <span class="flex items-center">
        Email
        <el-tooltip placement="top" tabindex="-1">
          <template #content>
            Pastikan email kamu valid karena akan <br />digunakan untuk
            pembayaran insentif
          </template>
          <img
            class="w-5 ml-1"
            src="@assets/images/icon_information.jpg"
            alt="Info"
          />
        </el-tooltip>
      </span>
      <el-input
        v-model="$store.state.register.form.email"
        class="mt-2"
        placeholder="Masukkan email kamu"
        clearable
        @keyup.enter="register"
      >
      </el-input>
      <span class="h-3 text-xs warning">{{
        $store.state.register.form.emailWarning
      }}</span>
    </div>
    <div class="w-3/4 py-3 md:w-1/3">
      <span class="flex items-center">
        Password
        <el-tooltip placement="top" tabindex="-1">
          <template #content> Minimal 8 karakter </template>
          <img
            class="w-5 ml-1"
            src="@assets/images/icon_information.jpg"
            alt="Info"
          />
        </el-tooltip>
      </span>
      <el-input
        v-model="$store.state.register.form.password"
        class="mt-2"
        placeholder="Masukkan password kamu (min. 8 karakter)"
        show-password
        @keyup.enter="register"
      >
      </el-input>
      <span class="h-3 text-xs warning">{{
        $store.state.register.form.passwordWarning
      }}</span>
    </div>
    <div class="flex flex-col items-start w-3/4 mt-2 tnc subtitle md:w-1/3">
      <label class="mb-2 text-xs md:text-sm">
        <input v-model="isAgreeTnc" type="checkbox" @change="validateAll" />
        Saya menyetujui
        <a class="tnc-anchor" :href="tncLink" target="_blank">
          syarat dan ketentuan
        </a>
        dari Kudata
      </label>
      <span class="h-3 text-xs warning">{{
        $store.state.register.form.tncWarning
      }}</span>
    </div>
    <el-button class="w-3/4 md:w-1/3 mt-7" type="primary" @click="register">
      Daftar
    </el-button>
    <el-divider
      content-position="center"
      class="w-3/4 text-sm text-center uppercase md:w-1/3 mt-7 "
    >
      Or Continue With
    </el-divider>
    <el-button
      type="sso"
      class="relative w-3/4 md:w-1/3"
      @click="registerWithGoogle"
    >
      <img class="w-4 ml-1" src="@assets/images/icon_google.png" alt="Google" />
      <span>Google</span>
    </el-button>
    <a class="anchor mt-7" @click="goTo('/login')">Sudah punya akun?</a>
    <el-dialog
      v-model="$store.state.register.success"
      title="Registrasi berhasil!"
      width="70%"
      :show-close="false"
    >
      <span>
        Selamat bergabung ke Kudata, {{ $store.state.register.form.fullName }}!
        :)
      </span>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            type="success"
            :loading="$store.state.register.loading"
            @click="processSuccess()"
          >
            Ok
          </el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      v-model="$store.state.register.isEmailRegistered"
      title="Email Sudah Terdaftar!"
	  class="w-3/4 md:w-1/3"
	  :width="dialogWidth"
    >
      <span
        >Oops! Email Anda sudah terdaftar. Silakan login untuk melanjutkan</span
      >
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="goTo('/login')"> Login </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { isEmail } from '@libs/util'
import { googleSdkLoaded } from 'vue3-google-login'

export default {
  data() {
    return {
      isAgreeTnc: false,
      tncLink:
        'https://docs.google.com/document/d/144YojRZ_jR-G460FEZqMip7WB5DPC7bOHARvTDem50Y/edit?usp=sharing',
		dialogWidth : '50%'
    }
  },
  mounted() {
    this.$store.state.register.redirect = this.$route.query.redirect || '/fill'
    if (localStorage.getItem('user-token' && localStorage.getItem('user-name'))) {
      this.$router.push({ name: '/fill' })
    }
	this.handleResize(); 
  },
  methods: {
    register() {
      this.validateRegister()
      if (
        !this.$store.state.register.form.fullNameWarning &&
        !this.$store.state.register.form.emailWarning &&
        !this.$store.state.register.form.passwordWarning &&
        !this.$store.state.register.form.tncWarning
      ) {
        this.$gtag.event('register-submit', {
          event_category: 'account-engagement',
          event_label: `Register Submit`,
        })
        this.$store.dispatch('register/REGISTER_USER')
      }
    },
    registerWithGoogle() {
      googleSdkLoaded((google) => {
        google.accounts.id.initialize({
          client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
          scope: 'email profile openid',
          callback: (response) => {
            this.$store.state.register.token = response.credential
            this.$store.dispatch('register/REGISTER_WITH_GOOGLE')
          },
        })
        const createFakeGoogleWrapper = () => {
          const googleLoginWrapper = document.createElement('div')
          googleLoginWrapper.style.display = 'none'
          document.body.appendChild(googleLoginWrapper)

          // Use GSI javascript api to render the button inside our wrapper
          google.accounts.id.renderButton(googleLoginWrapper, {
            type: 'icon',
            width: '200',
          })

          const googleLoginWrapperButton =
            googleLoginWrapper.querySelector('div[role=button]')

          return {
            click: () => {
              googleLoginWrapperButton.click()
            },
          }
        }
        const googleButtonWrapper = createFakeGoogleWrapper()
        googleButtonWrapper.click()
      })
    },
    processSuccess() {
      this.$store.dispatch('register/LOGIN_USER')
    },
    validateRegister() {
      if (!this.isFullNameValid()) {
        this.$store.state.register.form.fullNameWarning =
          'Masukkan nama lengkapmu'
      } else {
        this.$store.state.register.form.fullNameWarning = ''
      }
      if (!this.isEmailValid()) {
        this.$store.state.register.form.emailWarning = 'Email tidak valid'
      } else {
        this.$store.state.register.form.emailWarning = ''
      }
      if (!this.isPasswordValid()) {
        this.$store.state.register.form.passwordWarning =
          'Password minimal 8 karakter'
      } else {
        this.$store.state.register.form.passwordWarning = ''
      }
      if (!this.isTnCValid()) {
        this.$store.state.register.form.tncWarning =
          'Harap setujui syarat dan ketentuan'
      } else {
        this.$store.state.register.form.tncWarning = ''
      }
    },
    isFullNameValid() {
      return this.$store.state.register.form.fullName
    },
    isEmailValid() {
      return (
        this.$store.state.register.form.email.length > 0 &&
        isEmail(this.$store.state.register.form.email)
      )
    },
    isPasswordValid() {
      return this.$store.state.register.form.password.length >= 8
    },
    isTnCValid() {
      return this.isAgreeTnc
    },
    goTo(path) {
      this.$store.state.register.isEmailRegistered = false
      this.$router.push({ path: path })
    },
	handleResize() {
      if (window.innerWidth > 768) {
        this.dialogWidth = '33.3%';
      } else {
        this.dialogWidth = '75%';
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.anchor {
  color: $grey;
  padding-bottom: 2px;
  font-size: 0.9rem;
  cursor: pointer;
  &:hover {
    color: $blue-light;
    border-bottom: 1px solid $blue-light;
  }
}

.tnc {
  color: $grey;

  input {
    vertical-align: -2px;
    cursor: pointer;
  }

  .tnc-anchor {
    color: $secondary;
    cursor: pointer;
  }
}
</style>
