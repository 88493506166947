import VerifyEmail from '../Page/VerifyEmail'
import VerifyEmailFromUrl from '../Page/VerifyEmailFromUrl.vue'

const {
  constant: { MODULE_ROUTE_NAME },
} = require('../Service')

export default [
  {
    name: MODULE_ROUTE_NAME,
    path: `${MODULE_ROUTE_NAME}`,
    component: VerifyEmail,
  },
  {
    name: `${MODULE_ROUTE_NAME}/:token`,
    path: `${MODULE_ROUTE_NAME}/:token`,
    component: VerifyEmailFromUrl,
  },
]
