<template>
   <additional-user-data-modal
    v-if="isEditDataModalOpen"
  ></additional-user-data-modal>
  <el-dialog
      v-model="$store.state.profile.addtData.isUpdateSuccess"
      title="Data berhasil diperbarui!"
      width="50%"
      
      center
    >
       <template #footer>
      <span class="dialog-footer">
        <el-button  type="primary" @click="$store.state.profile.addtData.isUpdateSuccess = false">
          Lanjutkan
        </el-button>
      </span>
    </template>
    </el-dialog>
  <div class="w-full flex flex-col items-center">
    <div class="profile-banner w-5/6 md:w-3/4 flex flex-row justify-start">
      <img
        src="@assets/images/nav_profile.svg"
        alt="Profile"
        class="profile-image"
      />
      <div class="profile-banner__name flex flex-col justify-center">
        <span class="text-xl" style="line-height:.75em">
          Halo, <strong>{{ fullName }}</strong>
        </span>
          <!-- <unicon @click="openEditAdditionalData" name="pen" fill="white" class="cursor-pointer ml-2"></unicon> -->
        <span class="text-base mb-2">{{ email }}</span>

          <el-button type="secondary" style="display:flex; align-items:center; justify-content:center; padding:0; max-width:80%; min-height:32px; " @click="openEditAdditionalData">
              <span>Edit Profileku</span>
      </el-button>
      </div>
      <div class="profile-banner__info">
        <div class="profile-banner__info-item">
          <span class="profile-banner__info-item__title">Survey diisi</span>
          <span class="profile-banner__info-item__number">{{$store.state.profile.filledCount}}</span>
        </div>
        <div class="profile-banner__info-divider"></div>
        <div class="profile-banner__info-item">
          <span class="profile-banner__info-item__title">Survey dibuat</span>
          <span class="profile-banner__info-item__number">{{$store.state.profile.createdCount}}</span>
        </div>
        <div class="profile-banner__info-divider"></div>
        <div class="profile-banner__info-item">
          <span class="profile-banner__info-item__title">Jumlah poin</span>
          <span class="profile-banner__info-item__number">{{$store.state.profile.pointCount}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdditionalUserDataModal from '../Component/AdditionalUserDataModal.vue'
export default {
  components: {
    AdditionalUserDataModal,
  },
  computed: {
    fullName() {
      return localStorage.getItem('user-name')
    },
    email() {
      return localStorage.getItem('user-email')
    },
    isEditDataModalOpen() {
      return this.$store.state.profile.isAddtDataModalOpen
    },
  },
  methods: {
    openEditAdditionalData(){
      console.log(this.$store.state.profile.isAddtDataModalOpen)
      this.$store.state.profile.isAddtDataModalOpen= true
      console.log(this.$store.state.profile.isAddtDataModalOpen)

    },
    closeEditAdditionalData(){
      this.$store.state.profile.isAddtDataModalOpen= false
    }
  }
   
}
</script>

<style lang="scss" scoped>
.profile-banner {
  height: 150px;
  margin: 50px 0;
  padding: 30px 50px;
  border-radius: 10px;
  background-image: url('../../../assets/images/profile-banner-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  .profile-image {
    width: 80px;
    margin-right: 30px;

    @media (max-width: 768px) {
      display: none;
    }
  }

  .profile-banner__name {
    color: $white;
    margin-right: auto;
  }

  .profile-banner__info {
    width: 50%;
    background: $white;
    border-radius: 10px;
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .profile-banner__info-divider {
      width: 2px;
      height: 50px;
      background: $grey;
      margin: 0 30px;
    }

    .profile-banner__info-item {
      width: 30%;
      display: flex;
      flex-direction: column;

      .profile-banner__info-item__title {
        font-size: 15px;
        color: $grey;
      }

      .profile-banner__info-item__number {
        font-size: 20px;
        color: $black;
        font-weight: bold;
      }
    }

    @media (max-width: 768px) {
      display: none;
    }
  }
}
</style>
>
