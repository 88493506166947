import { createStore } from 'vuex'
import { store as modularStore } from '../../modules'

// Create a new store instance.
const store = createStore({
  modules: {
    ...modularStore,
  },
})

export default store
