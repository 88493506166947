import axios from 'axios'
import API_SERVICE from '../../service/api'
import { parseISOTime } from '../util'

const refreshUser = (params, onSuccess, onError) => {
  const apiDetail = API_SERVICE.API_URL.REFRESH
  return API_SERVICE.POST({
    apiUrl: apiDetail.API,
    params,
    onSuccess,
    onError,
  })
}

const getUserInfo = (onSuccess, onError) => {
  const apiDetail = API_SERVICE.API_URL.USER_INFO
  return API_SERVICE.GET({
    apiUrl: apiDetail.API,
    params: {},
    onSuccess,
    onError,
  })
}

async function generateNewAccessToken(refreshToken) {
  let params = {
    refresh_token: refreshToken,
  }
  refreshUser(params)
    .then((res) => {
      let newAccessToken = res.data
      localStorage.setItem('user-token', newAccessToken.access_token)
      localStorage.setItem(
        'user-token-exp',
        parseISOTime(newAccessToken.expired_at)
      )
      axios.defaults.headers.common['Authorization'] =
        `Bearer ${localStorage.getItem('user-token')}` || ''
    })
    .catch((err) => {
      localStorage.clear()
      window.location.href = window.location.host.includes('localhost')
        ? 'http://localhost:3000/login'
        : 'https://kudata.id/login'
      console.error(err)
    })
}

async function isUserAuthorized() {
	return getUserInfo().then(() => {
		return
	}).catch((err) =>  {
		localStorage.clear()
		window.location.href = window.location.host.includes('localhost')
        ? 'http://localhost:3000/login'
        : 'https://kudata.id/login'
		console.log(err);
	})
}

export { generateNewAccessToken, isUserAuthorized }
