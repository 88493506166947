<template>
  <navbar></navbar>
  <router-view v-slot="{ Component }">
    <transition name="route" mode="out-in">
      <component :is="Component"></component>
    </transition>
  </router-view>
  <Footer></Footer>
</template>

<script>
import Navbar from './layout/Navbar.vue'
import Footer from './layout/Footer.vue'

export default {
  name: 'App',
  components: {
    Navbar,
    Footer,
  },
  data() {
    return {
      fullyLoaded: false,
      title: 'Kudata | Platform Survey Mahasiswa Indonesia',
    }
  },

}
</script>

<style></style>
