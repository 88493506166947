const defaultState = () => {
  return {
    form: {
      email: '',
	  emailWarning : '',
    },
    success: false,
	isCooldown : false,
	cooldownTime: 30,
  }
}

export default defaultState
