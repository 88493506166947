export default {
  paidplaceholder0: 'newplaceholder0.png',
  paidplaceholder1: 'newplaceholder1.png',
  paidplaceholder2: 'newplaceholder2.png',
  paidplaceholder3: 'newplaceholder3.png',
  paidplaceholder4: 'newplaceholder4.png',
  placeholder0: 'placeholder0.jpg',
  placeholder1: 'placeholder1.jpg',
  placeholder2: 'placeholder2.jpg',
  placeholder3: 'placeholder3.jpg',
  placeholder4: 'placeholder4.png',
}
