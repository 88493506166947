import { createRouter, createWebHistory } from 'vue-router'
import { route as modularRoute } from '../../modules'
import { generateNewAccessToken, isUserAuthorized } from './auth'
import { generateMeta } from './meta'
import { trackRouter } from 'vue-gtag-next'

const routes = [...modularRoute]

const router = createRouter({
  history: createWebHistory(''),
  routes,
})

trackRouter(router)

router.beforeEach((to, from, next) => {
  let now = new Date()
  let accessToken = localStorage.getItem('user-token')
  let accessTokenExpire = new Date(localStorage.getItem('user-token-exp'))
  let refreshToken = localStorage.getItem('user-refresh')
  let refreshTokenExpire = new Date(localStorage.getItem('user-refresh-exp'))
  let isAccessTokenExpired = accessTokenExpire.getTime() < now.getTime()
  let isRefreshTokenExpired = refreshTokenExpire.getTime() < now.getTime()

  generateMeta(to.fullPath)
  document.querySelector('html').style.scrollBehavior = 'auto'
  setTimeout(() => window.scrollTo(0, 0), 5)
  setTimeout(
    () => (document.documentElement.style.scrollBehavior = 'smooth'),
    5
  )

  if (to.meta.requireAuth) {
    if (!accessToken) {
      next({
        path: '/register',
        query: {
          redirect: to.fullPath,
        },
      })
      return
    } else {
		isUserAuthorized();
	}
    if (isAccessTokenExpired && isRefreshTokenExpired) {
      localStorage.clear()
      window.location.href = window.location.host.includes('localhost')
        ? 'http://localhost:3000/login'
        : 'https://kudata.id/login'
      return
    } else if (isAccessTokenExpired && !isRefreshTokenExpired) {
      generateNewAccessToken(refreshToken)
    }
  } else {
    if (isAccessTokenExpired && !isRefreshTokenExpired) {
      generateNewAccessToken(refreshToken)
    }
  }
  next()
})

export default router
