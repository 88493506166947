<template>
  <div
    class="container grid grid-cols-3 w-full md:w-9/12 gap-2 gap-y-10 pt-5 px-5"
  >
    <div
      v-for="(data, idx) in surveyList"
      :key="'survey' + idx"
      class="w-full flex justify-center"
    >
      <survey-card
        v-if="data.sponsor == 'none'"
        :id="data.id"
        :title="data.title"
        :image="generateImage(data.id, data.reward)"
        :host-name="data.hostFullName"
        :reward="data.totalRewardByCountWinner"
        :count-winner="data.countWinner"
        :end-time="data.endAt"
        :is-filled="data.isFilled"
        :filler-criteria="data.fillerCriteria"
        :est-fill-time="data.estFillTime"
        :total-filled-surveys="data.totalFilledSurveys"
        :participant-needed="data.participantNeeded"
        @click="onSurveyClick(data)"
      ></survey-card>
      <survey-sponsor-card
        v-if="data.sponsor != 'none'"
        :id="data.id"
        :title="data.title"
        :image="generateImage(data.id, data.reward)"
        :host-name="data.hostFullName"
        :reward="data.reward"
        :sponsor="data.sponsor"
        :count-winner="data.countWinner"
        :end-time="data.endAt"
        :is-filled="data.isFilled"
        :filler-criteria="data.fillerCriteria"
        :est-fill-time="data.estFillTime"
        @click="onSurveyClick(data.id)"
      ></survey-sponsor-card>
    </div>
    <!-- <div
      v-for="idx in 8"
      :key="'survey' + idx"
      class="w-full flex justify-center"
    >
      <skeleton-box v-if="isLoading" width="18rem" height="15.5rem" />
    </div> -->
  </div>
</template>

<script>
import imageData from '../Test/image'
import SurveyCard from './SurveyCard.vue'
import SurveySponsorCard from './SurveySponsorCard.vue'
// import SkeletonBox from '../../Loading/SkeletonBox.vue'

export default {
  components: {
    SurveyCard,
    SurveySponsorCard,
    // SkeletonBox,
  },
  computed: {
    isLoading() {
      return this.$store.state.fillSurveyList.loading
    },
    surveyList() {
      return this.$store.state.fillSurveyList.surveyList
    },
    isAuth() {
      return (
        localStorage.getItem('user-token') && localStorage.getItem('user-email') && localStorage.getItem('user-name')
      )
    },

    isFilledAdditionalData() {
      return this.$store.state.fillSurveyList.isFilledAddData
    },
  },
  methods: {
    generateImage(id, reward) {
      // REMINDER- Need development here, store in DB
      if (imageData[id]) {
        return imageData[id]
      } else {
        let random = id % 5
        return imageData[`paidplaceholder${random}`]
      }
    },
    onSurveyClick(data) {
      this.$store.dispatch('fillSurveyList/GET_USER_INFO')
      if (this.isAuth) {
          if (this.isFilledAdditionalData == false) {
            this.$store.state.fillSurveyList.isAddtDataModalOpen = true
            return
          }
        
      }

        this.$gtag.event('fill-survey-click', {
          event_category: 'fill-engagement',
          event_label: `Fill Survey ${data.id} Click`,
        })
        console.log(data)
        if (data.isGform) {
          this.$router.push({ path: '/fill/' + data.id })
        } else {
          this.$router.push({ path: '/fill/' + data.formLink.String })
        }
      
     
    },
  },
}
</script>

<style scoped lang="scss">
.container {
  @media (max-width: 1300px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, minmax(300px, 1fr));
  }

  @media (max-width: 640px) {
    grid-template-columns: repeat(1, minmax(300px, 1fr));
  }
}
</style>
