const defaultState = () => {
    return {
        id: 0,
        hostFullName: '',
        isFilled: false,
        isLoading: false,
        isGform: true,
        formLoaded: false,
        success: false,
        respond: {},
        hostPhoneNumber: '',
        detail: {
            countWinner: 0,
            createdAt: '',
            description: '',
            endAt: '',
            formLink: '',
            totalPages: '',
            hostID: '',
            id: 0,
            image: '',
            sponsor: '',
            participantNeeded: 0,
            reward: 0,
            title: '',
            fillerCriteria: '',
            estFillTime: 0,
            totalFilledSurveys: 0,
            build: ''
        },
        winners: [],
    }
}

export default defaultState