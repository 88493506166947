<template>
    <div class="container">
        <div class="wrapper">
            <h3>Selamat! Survey kamu sebentar lagi tayang!</h3>
            <!-- SurveyRunner taro sini -->
            <p>Segera lakukan pembayaran dengan klik link berikut ini </p>
        </div>
        <div class="buttons">
            <button @click="$store.state.create.showShare = true">Share survey ke sosial media
                <unicon class="my-auto" name="share-alt" />
            </button>
            <button @click="goToPayment()">Bayar </button>
            <button @click="goTo('profile')">Lihat Profil</button>

        </div>
        <a href="">Ada pertanyaan?
            <unicon name="question-circle" />
        </a>
        <el-dialog v-model="$store.state.create.showShare" title="Share survey ke sosial media" width="70%"
            :show-close="true">
            <span class="text-base">

                <div class="flex justify-center">
                    <!-- <button type="button" data-mdb-ripple="true" data-mdb-ripple-color="light" class="inline-block p-3 mb-2 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out" style="background-color: #c13584;">
            <unicon name="instagram" fill="white"></unicon>
        </button> -->
                    <button type="button" data-mdb-ripple="true" data-mdb-ripple-color="light"
                        class="inline-block p-3 mb-2 ml-2 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out"
                        style="background-color: #c13584;">
                        <ShareNetwork network="Twitter" url="" :title="title" hashtags="#kudata">
                            <unicon name="twitter" fill="white"></unicon>
                        </ShareNetwork>
                    </button>
                    <button type="button" data-mdb-ripple="true" data-mdb-ripple-color="light"
                        class="inline-block p-3 mb-2 ml-2 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out"
                        style="background-color: #c13584;">
                        <ShareNetwork network="Telegram" url="" :title="title" hashtags="#kudata">
                            <unicon name="telegram-alt" fill="white"></unicon>
                        </ShareNetwork>
                    </button>
                    <button type="button" data-mdb-ripple="true" data-mdb-ripple-color="light"
                        class="inline-block p-3 mb-2 ml-2 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out"
                        style="background-color: #c13584;">
                        <ShareNetwork network="facebook" url="" :title="title" hashtags="#kudata">
                            <unicon name="facebook-f" fill="white"></unicon>
                        </ShareNetwork>
                    </button>
                    <button type="button" data-mdb-ripple="true" data-mdb-ripple-color="light"
                        class="inline-block p-3 mb-2 ml-2 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out"
                        style="background-color: #c13584;">
                        <ShareNetwork network="Line" url="" :title="title" hashtags="#kudata">
                            <unicon name="line" fill="white"></unicon>
                        </ShareNetwork>
                    </button>
                    <button type="button" data-mdb-ripple="true" data-mdb-ripple-color="light"
                        class="inline-block p-3 mb-2 ml-2 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out"
                        style="background-color: #c13584;">
                        <ShareNetwork network="Whatsapp" url="" :title="title" hashtags="#kudata">
                            <unicon name="whatsapp" fill="white"></unicon>
                        </ShareNetwork>
                    </button>
                </div>
                <input id="url" v-model="createdID" type="text" style="padding-top:1em" disabled>
            </span>
            <template #footer>
                <span class="dialog-footer">
                    <el-button on type="cancel" @click="$store.state.create.showShare = false"> Kembali </el-button>

                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>

export default {
    name: 'Finished',
    data() {
        return {
            shareText: 'Bagikan survey kudata mu!',
        }
    },
    computed: {
        createdID: {
            get() {
                return (window.location.host.includes('localhost')
                    ? 'http://localhost:3000/fill/'
                    : 'https://kudata.id/fill/') +
                    (this.$store.state.create.isUseGForm ? this.$store.state.create.surveyID : this.$store.state.create.form.formLink.String);
            }
        },
        title() {
            return `Isi survey ku di ${this.createdID} dan jangan lupa untuk buat survey mu di https://kudata.id/!`
        }
    },
    mounted() {
        window.onpopstate = () => {
            this.goTo('create');
        }
    },
    methods: {
        goTo(path) {
            if (path === 'fill') window.location.href = '/fill'
            else this.$router.push({ path: path })
        },
        goToPayment() {
            var left = (screen.width / 2) - (400 / 2);
            var top = (screen.height / 2) - (600 / 2);
            // this.$router.push({ path: '/payment/' + this.$store.state.create.surveyID })
            // window.location.href = '/payment/' + this.$store.state.create.surveyID
            window.open('https://api.kudata.id/api/payment/' + this.$store.state.create.surveyID, 'OY! Indonesia', 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=400, height=600, top=' + top + ', left=' + left);
        },
        //     goToTwitter() {
        //         window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(this.shareText)}`, '_blank');
        //     },
        //     goToTelegram() {
        //         window.open(`https://t.me/share/url?url=&text=${encodeURIComponent(this.shareText)}`, '_blank');
        //     }
    },

}

</script>

<style scoped>
a {
    text-decoration: underline;
}

h3 {
    font-size: 1.5rem;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 2em;
    margin: auto;
    width: 50%;
    min-width: 300px;
    padding: 1.5em;
    text-align: center;
    margin-top: 2em;
    font-family: 'Roboto', sans-serif;
}

.container a {
    display: flex;
    justify-content: center;
    gap: 0.5em;
}

.wrapper {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

#url {
    width: 100%;
    background-color: white;
    text-align: center;
}

.buttons {
    display: flex;
    gap: 1em;
    justify-content: center;
}

.buttons button {
    border-radius: 8px;
    padding: 0.5em 1em;
    border: 1px solid #35158C;
}

.buttons :nth-child(1) {
    display: flex;
    gap: 0.5em;
    align-items: center;
}

.buttons :nth-child(2) {
    background-color: #35158C;
    color: white;
}
</style>
