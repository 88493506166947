<template>
      <!-- <div class="btn">
      <button @click="create" id="create-button">Create Survey</button>
    </div> -->
    <div class="buttons">
      <button @click="create">{{btnMessage}}</button>
    </div>
    <div id="surveyCreator" />
    <!-- {{this.survey}} -->
</template>
  
  <script>
  import { SurveyCreator } from "survey-creator-knockout";
  import "survey-core/defaultV2.min.css";
  import "survey-creator-core/survey-creator-core.min.css";
  
  const creatorOptions = {
    showLogicTab: true,
    isAutoSave: true,
    haveCommercialLicense: true,
    showJSONEditorTab :false,
    questionTypes:[
      'radiogroup',
      'rating',
      'checkbox',
      'dropdown',
      'tagbox',
      'boolean',
      'imagepicker',
      'text',
      'comment'
    ]
  };
// const defaultJson = {
//     pages: [{
//         name: "Name",
//         elements: [{
//             name: "FirstName",
//             title: "Enter your first name:",
//             type: "text"
//         }, {
//             name: "LastName",
//             title: "Enter your last name:",
//             type: "text"
//         }]
//     }]
// };
  export default {
    name: "SurveyCreator",
    props:{ 
     
      isSlideOne: Boolean,
      btnMessage: {
        default:'Selanjutnya',
        type:String
      },
      //  nextAction: {
      //   default:this.create,
      //   type:Function
      // }
    },
    emits: ['isFormValid', 'nextSlide'],
    // data(){
    //   return {
    //     creator : new SurveyCreator(creatorOptions)
    //     };
    // },
    mounted() {
        const creator = new SurveyCreator(creatorOptions)

        creator.text = this.$store.state.create.form.build;
        
        creator.saveSurveyFunc = (saveNo, callback) => {
            this.$store.state.create.form.build = creator.text;
            // console.log(creator.text);
            callback(saveNo, true);
        };
        this.$store.state.create.saveSurveyFunc = (text) =>{
          creator.text = text;
        };
       
        creator.render("surveyCreator");
    },
   
    methods: {
        alertResults(sender) {
            const results = JSON.stringify(sender.data);
            alert(results);
        },

        create(){
          //  = JSON.stringify(this.surveyJson);
          // this.$emit("created", form)
          this.$emit("nextSlide", this.isSlideOne)
          this.$emit("isFormValid", true)
        },
    },
  }
  
  </script>
  <style scoped>


  .buttons {
    background-color: #FFFFFF;
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .buttons button {
    margin-right: 1em;
    background-color: #35158C;
    border-radius: 8px;
    padding: 0.5em 2em;
    color: white;
  }

  #surveyCreator {
    height: 100vh;
    width: 100vw;
  }
  </style>