// manage state
const mutations = {
  SET_COOLDOWN_TIME(state, time) {
    state.cooldownTime = time
  },
  SET_IS_COOLDOWN(state, isCooldown) {
    state.isCooldown = isCooldown
  },
}

export default mutations
