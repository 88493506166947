import API from '../Service/api'


const GET_FILLED_SURVEYS = (context) => {
    let params = {
        page_no: parseInt(context.state.filter.pageNo),
        page_size: context.state.filter.pageSize,
    }
    API.GET_FILLED_SURVEYS(params)
        .then((res) => {
            context.state.listFilled = res.data.surveys
        })
        .catch((err) => {
            console.error(err)
        })
}


const GET_CREATED_SURVEYS = (context) => {
    let params = {
        page_no: parseInt(context.state.filter.pageNo),
        page_size: context.state.filter.pageSize,
    }
    API.GET_CREATED_SURVEYS(params)
        .then((res) => {
            context.state.listCreated = res.data.surveys ? res.data.surveys : []
            context.state.listCreated.forEach(e => {
                e.isLoadingAnalytics = e.isLoadingEdit = false;
            });
        })
        .catch((err) => {
            console.error(err)
        })
}
const GET_DASHBOARD_PROFILE = (context) => {

    API.GET_DASHBOARD_PROFILE({})
        .then((res) => {
            context.state.createdCount = res.data.created
            context.state.filledCount = res.data.filled
            context.state.pointCount = res.data.point
        })
        .catch((err) => {
            console.error(err)
        })
}
const UPDATE_USER = (context) => {
    context.state.addtData.isLoading = true

    let params = {
        phone_number: context.state.addtData.phoneNumber,
        occupation: context.state.addtData.occupation,
        payment_type: context.state.addtData.paymentType,
        bank_type: context.state.addtData.bankType,
        payment_number: context.state.addtData.paymentNumber,
    }

    if (params.payment_type != 'Bank') {
        params.bank_type = ''
    }

    API.UPDATE_USER(params)
        .then((res) => {
            context.state.addtData.isUpdateSuccess = true
            context.state.isAddtDataModalOpen = false
            GET_USER_INFO(context);

        })
        .catch((err) => {
            console.error(err)
        })

    context.state.addtData.isLoading = false
}
const GET_USER_INFO = (context) => {
    try {
        API.GET_USER_INFO({})
            .then((res) => {
                context.state.addtData.phoneNumber = res.data.phoneNumber;
                context.state.addtData.occupation = res.data.occupation;
                context.state.addtData.paymentType = res.data.paymentType;
                context.state.addtData.bankType = res.data.bankType;
                context.state.addtData.paymentNumber = res.data.paymentNumber;
            })
            .catch((err) => {
                console.error(err)
            })
    } catch (err) {
        console.error(err)

    }

}
export default {
    GET_CREATED_SURVEYS,
    GET_FILLED_SURVEYS,
    GET_DASHBOARD_PROFILE,
    GET_USER_INFO,
    UPDATE_USER
}