import API from '../Service/api'

const VALIDATE_SECRET_EMAIL_URL = (context) => {
context.state.loading = true
  let params = {
    token: context.state.token,
  }

  API.VALIDATE_SECRET_EMAIL_URL(params)
    .then((res) => {
      if (res.status === 200) {
        context.state.verified = true
		context.state.loading = false
      }
    })
    .catch((err) => {
      context.state.verified = false
	  context.state.loading = false
    })
}

const RESET_PASSWORD = (context) => {
  let params = {
    token: context.state.token,
    new_password: context.state.form.confirmationPassword,
  }

  API.RESET_PASSWORD(params)
    .then((res) => {
      context.state.success = true
      context.state.form.resetPasswordWarning = ''
    })
    .catch((err) => {
      context.state.form.resetPasswordWarning =
        'Password baru tidak boleh password lama'
    })
}

export default { VALIDATE_SECRET_EMAIL_URL, RESET_PASSWORD }
