<template lang="">
    <SurveyCreator :is-slide-one="isSlideOne" @nextSlide="nextSlide" @isFormValid="isFormValid"/>
   
    <!-- <confirmation-dialog></confirmation-dialog> -->
    <!-- <el-dialog
      v-model="$store.state.create.isBuildSuccess"
      title="Berhasil"
      width="70%"
      :show-close="false"
    >
      <span class="text-base">
        Anda yakin untuk melanjutkan?
        
      </span>
      <template #footer>
        <span class="dialog-footer">
            <el-button type="cancel" @click="$store.state.create.isBuildSuccess = false"> Kembali </el-button>
            <el-button type="success" @click="submit"> Lanjutkan </el-button>
        </span>
      </template>
    </el-dialog> -->
</template>
<script>
import SurveyCreator from '../Component/SurveyCreator.vue';


export default {
  name: 'Build', 
 components: {
    SurveyCreator,
  },
  props: {
    isSlideOne: Boolean
  },
  emits: ['isFormValid', 'nextSlide'],
  
  data() {
   
  },

  methods:{ 
    isFormValid() {
      this.$emit('isFormValid', true)
    },
    
    nextSlide(isSlideOne) {
        this.$emit('nextSlide', isSlideOne)
    },
    goTo(path) {
      if (path === 'fill') window.location.href = '/fill'
      else this.$router.push({ path: path })
    },
    // submit(){
    //     this.$store.dispatch('create/CREATE_SURVEY_JS')
    //     this.goTo('fill')
    // },
  }
}
</script>
<style lang="">
    
</style>