<template>
  <div class="flex flex-col md:flex-row items-center justify-around p-10">
    <div class="flex flex-col items-end">
      <h1 class="text-5xl font-bold">Coming Soon</h1>
      <p class="my-3">
        Fitur ini masih dalam pengerjaan. Ngisi survey dulu yuk!
      </p>
      <el-button type="primary" class="w-full mt-3" @click="goTo('fill')">
        ISI SURVEY
      </el-button>
      <el-button class="w-full mt-3" @click="goTo('/')"> KEMBALI </el-button>
    </div>
    <img
      class="cs-image"
      src="@assets/images/coming_soon.svg"
      alt="Coming Soon"
    />
  </div>
</template>

<script>
export default {
  methods: {
    goTo(path) {
      this.$router.push({ path: path })
    },
  },
}
</script>

<style lang="scss" scoped>
h1 {
  color: #f9a826;
  margin-left: -3px;
}

.cs-image {
  width: 60%;
  min-width: 300px;
}
</style>
