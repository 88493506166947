import API from '../Service/api'
import { parseISOTime } from '../../../libs/util'

const REGISTER_USER = (context) => {
  context.state.loading = true
  let params = {
    full_name: context.state.form.fullName,
    email: context.state.form.email.toLowerCase(),
    password: context.state.form.password,
  }
  API.REGISTER_USER(params)
    .then(() => {
      context.state.success = true
    })
    .catch((err) => {
      if (!context.state.success) {
        context.state.form.emailWarning = 'Email sudah terdaftar'
        console.error(err)
      }
    })
  context.state.loading = false
}

const LOGIN_USER = (context) => {
  context.state.loading = true
  let params = {
    email: context.state.form.email,
    password: context.state.form.password,
  }
  API.LOGIN_USER(params).then((res) => {
    context.state.success = true
    HANDLE_LOGIN_USER(res.data)
    window.location.href = '/users/verify-email'
  })
  context.state.loading = false
}

const REGISTER_WITH_GOOGLE = (context) => {
  context.state.loading = true
  let params = {
    token: context.state.token,
  }
  API.REGISTER_WITH_GOOGLE(params)
    .then((res) => {
      HANDLE_LOGIN_SUCCESS(res.data)
      window.location.href = context.state.redirect
    })
    .catch((err) => {
      context.state.isEmailRegistered = true
      console.log(err)
    })
  context.state.loading = false
}

const HANDLE_LOGIN_SUCCESS = (res) => {
  localStorage.setItem('user-token', res.access_token)
  localStorage.setItem('user-token-exp', parseISOTime(res.expired_at))
  localStorage.setItem('user-refresh', res.refresh_token)
  localStorage.setItem('user-refresh-exp', parseISOTime(res.refresh_expired_at))
  localStorage.setItem('user-name', res.user.fullName)
  localStorage.setItem('user-email', res.user.email)
}

const HANDLE_LOGIN_USER = (res) => {
  localStorage.setItem('verif-load-first-time', true)
  localStorage.setItem('user-token', res.access_token)
  localStorage.setItem('user-email', res.user.email)
}

export default { REGISTER_USER, LOGIN_USER, REGISTER_WITH_GOOGLE }
