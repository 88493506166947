<template>
  <div class="w-full flex justify-center">
    <div class="card-container w-full h-auto">
      <div class="top-image">
        <img
          :src="require('@assets/survey_images/' + image)"
          class="image w-full h-20"
        />
        <div v-if="reward > 0" class="top-image-text">
          <span class="reward-type"> Uang Tunai </span>
          <br />
          <span class="reward">
            {{ formatIDR(reward) }}
          </span>
          <br />
          <span class="count-winner"> untuk {{ countWinner }} orang </span>
        </div>
        <div v-else class="top-image-text">
          <span class="reward-type"> Hadiah Langsung </span>
          <br />
          <span class="reward"> 1 Poin </span>
          <br />
          <span class="count-winner"> untuk setiap filler </span>
        </div>
      </div>
      <div class="top w-full">
        <span class="title">{{ formatText(title) }}</span>
        <p class="maker subtitle text-xs">
          oleh <span class="font-semibold"> {{ hostName }} </span>
        </p>
        <div class="filler-criteria">
          <span class="filler-criteria__title">Kriteria Responden</span><br />
          <span
            v-for="(criteria, index) in formatFillerCriteria(fillerCriteria)"
            :key="index"
            class="filler-criteria__content"
          >
            <span v-if="index < 3">{{ index + 1 }}. </span> {{ criteria }}<br />
          </span>
          <span v-if="!fillerCriteria">-</span>
        </div>
      </div>
      <div class="bottom">
        <vue-countdown
          v-if="!isClosed"
          v-slot="{ days, hours, minutes }"
          :time="timeLeft"
          class="subtitle time text-xs"
        >
          Berakhir dalam:&nbsp;
          <span class="time-count">
            {{ generateTimeLeft(days, hours, minutes) }}
          </span>
        </vue-countdown>
        <span v-else class="subtitle time text-xs">
         Sudah berakhir
        </span>
       
        <span class="time"></span>
        <img
          class="bust-img mr-2"
          src="@assets/icons/1f465.svg"
          alt="Coming Soon"
        />
        <span class="color-total-participant font-bold">
          {{ totalFilledSurveys }}/{{ participantNeeded }}
        </span>
      </div>
    </div>
    <div v-if="isEndingSoon()" class="badge-ending-soon">Segera Berakhir</div>
  </div>
</template>

<script>
import moment from 'moment'
import { formatIDR } from '@libs/util'

export default {
  props: {
    id: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: '',
    },
    image: {
      type: String,
      default: '',
    },
    hostName: {
      type: String,
      default: '',
    },
    endTime: {
      type: String,
      default: '',
    },
    reward: {
      type: Number,
      default: 0,
    },
    countWinner: {
      type: Number,
      default: 0,
    },
    fillerCriteria: {
      type: String,
      default: '',
    },
    estFillTime: {
      type: Number,
      default: 0,
    },
    isFilled: {
      type: Boolean,
      default: false,
    },
    totalFilledSurveys: {
      type: Number,
      default: 0,
    },
    participantNeeded: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    timeLeft() {
      let now = moment()
      let end = moment(this.endTime)
      return moment.duration(end.diff(now)).asMilliseconds()
    },
    isClosed() {
      return this.timeLeft < 0
    },
  },
  methods: {
    formatIDR(val) {
      return formatIDR(val) || ''
    },
    formatText(val) {
      val = val.toLowerCase()
      return val
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    },
    formatFillerCriteria(fillerCriteria) {
      if (!fillerCriteria) return ''

      if (fillerCriteria.length > 75) {
        fillerCriteria = fillerCriteria.slice(0, 75)
      }

      let fillerCriteriaArr = fillerCriteria
        .split('\n')
        .filter((criteria) => criteria != '')

      if (fillerCriteriaArr.length > 3) {
        fillerCriteriaArr = fillerCriteriaArr.slice(0, 3)
        fillerCriteriaArr[2] += '...'
      }
      return fillerCriteriaArr
    },
    generateTimeLeft(days, hours, minutes) {
      if (days) return days + ' Hari'
      if (hours) return hours + ' Jam'
      else return minutes + ' Menit'
    },
    generateStatusClass(disabled) {
      if (!this.isClosed && !this.isFilled) return 'status'
      if (this.isClosed) return 'status disabled'
      if (this.isFilled) return 'status disabled'
    },
    generateButtonText() {
      if (this.isFilled) return 'Sudah Diisi'
      if (this.isClosed) return 'Sudah Berakhir'
      else return 'Isi Survey'
    },
    isEndingSoon() {
      return this.timeLeft < 172800000 && this.timeLeft>=0
    },
  },
}
</script>

<style scoped lang="scss">
.badge-ending-soon {
  width: 50px;
  height: 50px;
  font-size: 9px;
  font-weight: bold;
  text-align: center;
  line-height: 15px;
  padding: 9px;
  border-radius: 100%;
  background: $warning;
  color: $white;
  margin: -10px -10px -30px -30px;
  z-index: 2;
}

.card-container {
  cursor: pointer;
  max-width: 290px;
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;

  .top-image {
    position: relative;
    text-align: center;

    .image {
      background: rgba(18, 52, 108, 0.9);
      height: 150px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .top-image-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-family: 'Inter', sans-serif;

      .reward-type {
        color: #fff;
        font-size: 0.9rem;
        font-weight: 700;
      }

      .reward {
        color: #fff;
        font-size: 1.75rem;
        font-weight: 500;
      }

      .count-winner {
        color: #fff;
        font-size: 0.85rem;
      }
    }
  }

  .subtitle {
    color: #999;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .top {
    width: 100%;
    padding: 16px;
    overflow: hidden;

    .title {
      height: 70px;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #3171d7;
      font-weight: bold;
      font-size: 15px;
    }

    .maker {
      margin-top: 10px;
      font-style: italic;
    }

    .filler-criteria {
      height: 80px;
      font-size: 13px;
      margin-top: 20px;

      .filler-criteria__title {
        font-weight: bold;
        color: $black;
      }

      .filler-criteria__content {
        margin-bottom: 4px;
        color: $grey;
      }
    }
  }
}

.reward-badge {
  background: $primary;
  color: $white;
}

.bottom {
  width: 100%;
  padding: 16px 16px;
  display: flex;
  align-items: center;
  background: #dfebff;

  .time {
    margin-right: auto;
  }

  .time-count {
    font-weight: 900;
    color: rgb(102, 102, 102);
  }

  .status {
    width: 40%;
    height: inherit;
    padding: 5px 5px;
    background: #5ecc50;
    color: $white;
    font-size: 13px;
    border-radius: 5px;
  }

  .disabled {
    background: #f0f6ff;
    color: #92baff;
    cursor: not-allowed;
  }
}

.image {
  object-fit: cover;
}

.color-total-participant {
  color: rgb(3 105 161);
}

.bust-img {
  width: 16px;
  height: 16px;
}
</style>
