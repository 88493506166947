<template>
  <div class="flex flex-col items-center justify-center pt-24 pb-36">
    <h1 class="py-5 text-3xl text-semibold">Masuk</h1>
    <div class="w-3/4 py-3 md:w-1/3">
      <span>Email</span>
      <el-input
        v-model="$store.state.login.form.email"
        class="mt-2"
        placeholder="Masukkan email kamu"
        clearable
        @keyup.enter="login"
      >
      </el-input>
    </div>
    <div class="w-3/4 py-3 md:w-1/3">
      <span>Password</span>
      <el-input
        v-model="$store.state.login.form.password"
        class="mt-2"
        placeholder="Masukkan password kamu"
        show-password
        @keyup.enter="login"
      >
      </el-input>
      <div class="mt-2">
        <a class="anchor" @click="goTo('/forgot-password')">Lupa Password?</a>
      </div>
    </div>
    <span class="text-xs warning">{{ $store.state.login.form.warning }}</span>
    <el-button
      class="w-3/4 mt-5 md:w-1/3"
      type="primary"
      :loading="$store.state.login.loading"
      @click="login"
    >
      Masuk
    </el-button>
    <el-divider
      content-position="center"
      class="w-3/4 text-sm text-center uppercase md:w-1/3 mt-7"
    >
      Or Continue With
    </el-divider>
    <el-button
      type="sso"
      class="relative w-3/4 md:w-1/3"
      @click="loginWithGoogle"
    >
      <img class="w-4 ml-1" src="@assets/images/icon_google.png" alt="Google" />
      <span>Google</span>
    </el-button>
    <a class="anchor mt-7" @click="goTo('/register')">Belum punya akun?</a>
  </div>
</template>

<script>
import { googleSdkLoaded } from 'vue3-google-login';
export default {
  mounted() {
    this.$store.state.login.redirect = this.$route.query.redirect || '/fill'
    if (localStorage.getItem('user-token') && localStorage.getItem('user-name')) {
      this.$router.push({ name: '/fill' })
    }
  },
  methods: {
    loginWithGoogle() {
      googleSdkLoaded((google) => {
        google.accounts.id.initialize({
          client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
          scope: 'email profile openid',
          callback: (response) => {
            this.$store.state.login.token = response.credential
            this.$store.dispatch('login/LOGIN_WITH_GOOGLE')
          },
        })
        const createFakeGoogleWrapper = () => {
          const googleLoginWrapper = document.createElement('div')
          googleLoginWrapper.style.display = 'none'
          document.body.appendChild(googleLoginWrapper)

          // Use GSI javascript api to render the button inside our wrapper
          google.accounts.id.renderButton(googleLoginWrapper, {
            type: 'icon',
            width: '200',
          })

          const googleLoginWrapperButton =
            googleLoginWrapper.querySelector('div[role=button]')

          return {
            click: () => {
              googleLoginWrapperButton.click()
            },
          }
        }
        const googleButtonWrapper = createFakeGoogleWrapper()
        googleButtonWrapper.click()
      })
    },
    login() {
      this.$gtag.event('login-submit', {
        event_category: 'account-engagement',
        event_label: `Login Submit`,
      })
      this.$store.dispatch('login/LOGIN_USER')
    },
    goTo(path) {
      this.$router.push({ path: path })
    },
  },
}
</script>

<style lang="scss" scoped>
.anchor {
  color: $grey;
  padding-bottom: 2px;
  font-size: 0.9rem;
  cursor: pointer;

  &:hover {
    color: $blue-light;
    border-bottom: 1px solid $blue-light;
  }
}
</style>
