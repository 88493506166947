const defaultState = () => {
  return {
    isLoading: false,
    isCreateSuccess: false,
    isBuildSuccess: false,
    isUseGForm: false,
	isScrapping: false,
    confirmDialogVisible: false,
    showShare: false,
    freeBuilderSurveyQuota: 0,
    freeGformSurveyQuota: 0,
    freeSurveyPoint: 0,
    surveyID: 0,
    createGform:false,
    onSuccess : () =>{},
    onError : () =>{},
    saveSurveyFunc:()=>{},
    form: {
      title: '',
      description: '',
      formLink: '',
      totalPages: 1,
      isFree:false,
      reward: 25000,
      countWinner: 1,
      respondentTarget: 10,
      duration: 5,
      fillerCriteria: '',
      estFillTime: 5,
      id:'',
      endAt:'',
      build: '{'+
      '"completedHTML":"<h3>Terima kasih telah mengisi survey! :)</h3>",'+
        '"pages": [{' +
        
          '"name": "Name",'+
          '"elements": [{'+
            '"name": "FirstName",'+
            '"title": "Enter your first name:",'+
            '"type": "text" }, '+
            '{ "name": "LastName", '+
            '"title": "Enter your last name:", '+
            '"type": "text" '+
            '}]'+
          '}]}',
    },
    warning: {
      title: '',
      description: '',
      formLink: '',
      totalPages: '',
      reward: '',
      payment: '',
      tnc: '',
      minRewardPerWinner: '',
      fillerCriteria:'',
      estFillTime: '',
      respondentTarget:'',
      duration:'',
    },
  }
}

export default defaultState
