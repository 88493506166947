import API from '../Service/api'
import { GoogleSpreadsheet } from 'google-spreadsheet'
import { formatIDR,parseISOTime } from '@libs/util'

import axios from 'axios'

const UPDATE_SURVEYJS_DETAIL = (context) => {
  let params = {
    ID: context.state.form.id,
    Build: JSON.parse(context.state.form.build),
  }
  API.UPDATE_SURVEYJS_DETAIL(params)
    .then((res) => {
      context.state.form.id = res.data.id
      context.state.form.formLink = res.data.formLink.String
      GET_SURVEYJS_DETAIL(context)
      // GET_RESPONSES(context)
      // let now = moment()
      // let end = moment(context.state.detail.endAt)
      // let timeLeft = moment.duration(end.diff(now)).asMilliseconds()
      // if (res.data.survey.reward > 0 && timeLeft < 0) {
      //   GET_SURVEY_WINNER(context)
      // }
    })
    .catch((err) => {
      console.error(err)
    })
}
const CREATE_SURVEY_JS = (context) => {
  context.state.isLoading = true
  let params = {
    title: context.state.form.title,
    description: context.state.form.description,
    build: JSON.parse(context.state.form.build),
    form_link: context.state.form.formLink.trim(),
    total_pages: null,
    image: 'none',
    sponsor: 'none',
    reward: parseInt(context.state.form.reward),
    count_winner: context.state.form.countWinner,
    participant_needed: context.state.form.respondentTarget,
    duration: context.state.form.duration,
    filler_criteria: context.state.form.fillerCriteria,
    est_fill_time: context.state.form.estFillTime,
  }

  params.filler_criteria = params.filler_criteria?.replace(/\n\n/g, '\n') || '-'

  API.CREATE_SURVEY_JS(params)
    .then((res) => {
      context.state.surveyID = res.data.id
      context.state.form = res.data
      if (params.reward == 0) {
        context.state.isCreateSuccess = true
      } else {
        CREATE_SURVEY_SHEET(context)
      }
      // context.state.isCreateSuccess = true
      // NOTIFY_TELEGRAM_GROUP(context)
      context.state.onSuccess('create-finished')
    })
    .catch((error) => {
      context.state.onError('URL already exists')
      // console.error(err)
    })
  context.state.isLoading = false
}
const CREATE_SURVEY = (context) => {
  context.state.isLoading = true
  console.log(context.state.form)
  let params = {
    title: context.state.form.title,
    description: context.state.form.description,
    form_link: context.state.form.formLink.trim(),
    total_pages: context.state.form.totalPages,
    image: 'none',
    sponsor: 'none',
    reward: parseInt(context.state.form.reward),
    count_winner: context.state.form.countWinner,
    participant_needed: context.state.form.respondentTarget,
    duration: context.state.form.duration,
    filler_criteria: context.state.form.fillerCriteria,
    est_fill_time: context.state.form.estFillTime,
  }

  params.filler_criteria = params.filler_criteria.replace(/\n\n/g, '\n') || '-'

  API.CREATE_SURVEY(params)
    .then((res) => {
      context.state.surveyID = res.data.id
      if (params.reward == 0) {
        context.state.isCreateSuccess = true
      } else {
        CREATE_SURVEY_SHEET(context)
      }
      context.state.onSuccess('create-finished');
    })
    .catch((err) => {
      console.error(err)
    })
  context.state.isLoading = false
}

const CREATE_SURVEY_SHEET = (context) => {
  context.state.isLoading = true
  let form = context.state.form
  let email = localStorage.getItem('user-email')
  let fullName = localStorage.getItem('user-name')
  let time = new Date()
    .toLocaleString('en-US', {
      timeZone: 'Asia/Jakarta',
    })
    .replace(',', '')
  let data = {
    CreatedAt: time,
    Email: email,
    SurveyID: context.state.surveyID,
    Name: fullName,
    Title: form.title,
    Description: form.description,
    GFormLink: form.formLink,
    TotalPages: form.totalPages,
    Reward: form.reward,
    CountWinner: form.countWinner,
    RespondentTarget: form.respondentTarget,
    Duration: form.duration,
  }
  ADD_DATA_TO_SHEET(context, data)
}

async function ADD_DATA_TO_SHEET(context, data) {
  const doc = new GoogleSpreadsheet(process.env.VUE_APP_MAKER_FORM_GSHEET_ID)
  await doc.useServiceAccountAuth(
    JSON.parse(process.env.VUE_APP_GCP_SERVICE_SECRET)
  )
  await doc.loadInfo()
  const sheet = doc.sheetsByIndex[0]
  await sheet
    .addRow(data)
    .then((_) => {
      if (process.env.NODE_ENV == 'development') {
        context.state.isCreateSuccess = true
      } else {
        NOTIFY_TELEGRAM_GROUP(context)
      }
    })
    .catch((err) => console.error('error while submitting survey: ' + err))
}

const GET_FREE_SURVEY_QUOTA = (context) => {
  API.GET_FREE_SURVEY_QUOTA({})
    .then((res) => {
      context.state.freeGformSurveyQuota = res.data.gform_quota
      context.state.freeBuilderSurveyQuota = res.data.builder_quota
      context.state.freeSurveyPoint = res.data.point
    })
    .catch((err) => {
      console.error(err)
    })
}

const NOTIFY_TELEGRAM_GROUP = (context) => {
  let url = `https://api.telegram.org/bot${process.env.VUE_APP_TELEGRAM_BOT_API_KEY}/sendMessage`
  let groupChatID = '-1001755295551'

  let query = {
    chat_id: groupChatID,
    text: GENERATE_TELEGRAM_GROUP_MESSAGE(context),
    parse_mode: 'HTML',
  }

  axios
    .post(url, query)
    .then((_) => {
      context.state.isCreateSuccess = true
    })
    .catch((err) => {
      context.state.isCreateSuccess = true
      console.error(err)
    })
}

const GENERATE_TELEGRAM_GROUP_MESSAGE = (context) => {
  return `<b>[${GENERATE_RANDOM_MESSAGE_HEADER()}]</b>
Judul: ${context.state.form.title}
Maker: ${localStorage.getItem('user-name')}
Total Insentif: ${formatIDR(context.state.form.reward)}
Durasi: ${context.state.form.duration} hari
Isi disini ya: https://kudata.id/fill/${
    context.state.isGform ? context.state.formLink : context.state.surveyID
  }

Terima kasih 😊
`
}

const GENERATE_RANDOM_MESSAGE_HEADER = () => {
  let options = [
    'Wah! Di Kudata ada Survey Baru!',
    'Ada Survey Baru Nih!',
    'Kuy Ngisi Survey Baru!',
    'Survey Baru Buatmu Hari ini!',
    'Survey Baru Hanya di Kudata!',
    'Yuk, Cek Survey Baru Ini!',
    'Wih! Ada Survey Baru Loh!',
  ]
  let random = Math.floor(Math.random() * options.length)
  return options[random]
}
const GET_SURVEYJS_DETAIL = (context) => {
  let params = {
    form_link: context.state.form.formLink
  }
  API.GET_SURVEYJS_DETAIL(params)
    .then((res) => {
      context.state.form = res.data.survey
      context.state.form.build = JSON.stringify(res.data.build)
      // context.state.form.endAt = parseISOTime(context.state.form.endAt)
      context.state.saveSurveyFunc(context.state.form.build)
      // GET_RESPONSES(context)
      // let now = moment()
      // let end = moment(context.state.detail.endAt)
      // let timeLeft = moment.duration(end.diff(now)).asMilliseconds()
      // if (res.data.survey.reward > 0 && timeLeft < 0) {
      //   GET_SURVEY_WINNER(context)
      // }
    })
    .catch((err) => {
      console.error(err)
    })
}
const SCRAPPE_SURVEY_FORM = (context) => {
  context.state.isScrapping = true
  context.state.form.title = ''
  context.state.form.description = ''
  let params = {
    url:context.state.form.formLink?.split('?')[0]
  }
  API.SCRAPPE_SURVEY_FORM(params)
    .then((res) => {
	  context.state.form.title = res.data.title
	  context.state.form.description = res.data.description
	  context.state.isScrapping = false
    })
    .catch((err) => {
      console.error(err)
	  context.state.warning.formLink = "URL yang kamu masukkan tidak valid atau rusak, coba lagi"
	  context.state.isScrapping = false
    })
}

export default {
  CREATE_SURVEY,
  CREATE_SURVEY_SHEET,
  UPDATE_SURVEYJS_DETAIL,
  GET_FREE_SURVEY_QUOTA,
  CREATE_SURVEY_JS,
  GET_SURVEYJS_DETAIL,
  SCRAPPE_SURVEY_FORM,
}
