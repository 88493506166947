import API_SERVICE from '../../../service/api/index'

const CREATE_SURVEY = (params, onSuccess, onError) => {
  const apiDetail = API_SERVICE.API_URL.SURVEYS
  return API_SERVICE.POST({
    apiUrl: apiDetail.API,
    params,
    onSuccess,
    onError,
  })
}
const CREATE_SURVEY_JS = (params, onSuccess, onError) => {
  const apiDetail = API_SERVICE.API_URL.SURVEYS_JS
  return API_SERVICE.POST({
    apiUrl: apiDetail.API,
    params,
    onSuccess,
    onError,
  })
}
const GET_SURVEYJS_DETAIL = (params, onSuccess, onError) => {
  const apiDetail = API_SERVICE.API_URL.SURVEYS_JS
  return API_SERVICE.GET({
    apiUrl: apiDetail.API,
    params,
    onSuccess,
    onError,
  })
}
const UPDATE_SURVEYJS_DETAIL = (params, onSuccess, onError) => {
  const apiDetail = API_SERVICE.API_URL.UPDATE_SURVEYS_JS
  return API_SERVICE.POST({
    apiUrl: apiDetail.API,
    params,
    onSuccess,
    onError,
  })
}

const GET_FREE_SURVEY_QUOTA = (params, onSuccess, onError) => {
  const apiDetail = API_SERVICE.API_URL.QUOTA
  return API_SERVICE.GET({
    apiUrl: apiDetail.API,
    params,
    onSuccess,
    onError,
  })
}

const SCRAPPE_SURVEY_FORM = (params, onSuccess, onError) => {
  const apiDetail = API_SERVICE.API_URL.SCRAPPE_SURVEY_FORM
  return API_SERVICE.POST({
    apiUrl: apiDetail.API,
    params,
    onSuccess,
    onError,
  })
}



export default { CREATE_SURVEY, GET_FREE_SURVEY_QUOTA,CREATE_SURVEY_JS,GET_SURVEYJS_DETAIL,UPDATE_SURVEYJS_DETAIL,SCRAPPE_SURVEY_FORM }
