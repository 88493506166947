<template>
    <div class="content">
        <div class="content-container">
            <div class="content-1">
            <h1>BUAT SURVEY MENARIK</h1>
            </div>
            <div class="content-2">
                <h4>Dapatkan responden lebih cepat dan terpercaya, selesaikan tugas akhir lebih cepat!</h4>
            </div>
        </div>
        
        <el-button class="button" :loading="this.$store.state.create.isLoading" @click="createGform"> 
            Buat survey sekarang 👋
        </el-button>
       
        <!-- <div class="button" @click="createGform">
            <a @click="createGform">Saya punya Google Form 👋</a>
        </div> -->
    </div>
  </template>
  <script>
  
  export default {
    name: 'LandingPage',
    mounted(){
        this.$store.state.create.isLoading = false
    },
    methods: {
    goTo(path) {
      if (path === 'fill') window.location.href = '/fill'
      else this.$router.push({ path: path })
    },
    createGform() {
        // console.log(this.$store.state.create.createGform)
        this.goTo('create-gform')
    },
    createSurveyJs() {
        this.$store.state.create.isLoading = true; //Set Loading button for goto createsurveyjs
        if(this.$store.state.create.form!=undefined){
            this.$store.state.create.form.build='{}'
        }
        
        
        // console.log(this.$store.state.create.createGform)
        
        this.goTo('create-details')
    }
  },
  }
  </script>
  
  <style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap');
  
   *{
    padding: 0;
    margin: 0;
   }
    button{
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size:medium;
        background-color: #35158C;
        color: #FFFFFF;
        padding: 0.5em 1.75em;
        border-radius: 8px;
    }
    .button{
        margin-top: 2em;
    }
    .button:hover{
         cursor:pointer;
    }
    .content{
        min-height: 700px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .content-1 {
        font-family: 'Roboto', sans-serif;
        font-weight: 300;
        font-size: 2rem;
    }
    .content-2 {
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 1.5rem;
        max-width: 60%;
        margin-top: 1.5em;
    }
    .content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .content-2 h4{
        text-align: center;
    }
    .links {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-top: 2em;
    }
    .links a{
        margin-top: 0.5em;
        text-decoration: none;
        color: #3B5989;
    }
  </style>
  
