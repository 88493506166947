<template>
  <div class="w-full flex flex-col items-center my-60">
    <h1 class="text-3xl font-bold text-center">404: Laman Tidak Ditemukan</h1>
    <el-button
      type="primary"
      class="w-10/12 md:w-1/3 lg:w-1/4 mt-3"
      @click="goTo('/')"
    >
      KEMBALI KE BERANDA
    </el-button>
  </div>
</template>

<script>
export default {
  methods: {
    goTo(path) {
      this.$router.push({ path: path })
    },
  },
}
</script>

<style lang="scss" scoped></style>
