import Register from '../Page/Register'

const {
  constant: { MODULE_ROUTE_NAME },
} = require('../Service')

export default [
  {
    name: MODULE_ROUTE_NAME,
    path: `${MODULE_ROUTE_NAME}`,
    component: Register,
  },
]
