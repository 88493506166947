
<template>
      <div class="container">
        <div class="survey-nav">
              <div class="survey-nav-title">
                <a @click="goTo('create')"> &#8592; Buat Survey</a>
                <h2>Survey Builder</h2>
              </div>
              <div class="survey-nav-2">
                  <div class="survey-step" 
                      :class="(isFilledOne || isSlideOne) ? 'bg' : ''">
                      <span class="curr" :class="isSlideOne ? 'currp' : ''"></span>
                      <p>1 Tambahkan pertanyaan</p>
                      <unicon class="mr-2" name="check-circle" :fill="isFilledOne ? 'green' : 'gray'" icon-style="monochrome"/>
                  </div>
                  <div class="survey-step" 
                      :class="(isFilledTwo || isSlideTwo) ? 'bg' : ''">
                    <span class="curr" :class="isSlideTwo ? 'currp' : ''"></span>
                      <p>2 Detail Survey</p>
                      <unicon class="mr-2" name="check-circle" :fill="isFilledTwo ? 'green' : 'gray'" icon-style="monochrome"/>
                  </div>
                  <div class="survey-step"  
                      :class="(isFilledThree || isSlideThree) ? 'bg' : ''">
                    <span class="curr" :class="isSlideThree ? 'currp' : ''"></span>
                      <p>3 Berikan insentif</p>
                      <unicon class="mr-2" name="check-circle" :fill="isFilledThree ? 'green' : 'gray'" icon-style="monochrome"/>
                  </div>
                  <div class="survey-step" 
                      :class="isSlideFour ? 'bg' : ''">
                    <span class="curr" :class="isSlideFour ? 'currp' : ''"></span>
                      <p>4 Periksa dan tayangkan</p>
                      <unicon class="mr-2" name="check-circle" fill="gray" icon-style="monochrome"/>
                  </div>
              </div>
      </div>
      <Build 
            v-if="isSlideOne"
            :is-slide-one="isSlideOne" 
            @nextSlide="nextSlide"
            @isFormValid="isFormOneValid"/>
      <DetailSurvey 
                 v-else-if="isSlideTwo" 
                 :is-slide-two="isSlideTwo" 
                 @nextSlide="nextSlide" 
                 @prevSlide="prevSlide" 
                 @isFormValid="isFormTwoValid"/>
      <BerikanInsentif 
                 v-else-if="isSlideThree" 
                 :is-slide-three="isSlideThree" 
                 @nextSlide="nextSlide" 
                 @prevSlide="prevSlide" 
                 @isFormValid="isFormThreeValid"/>
      <Tayangkan 
                 v-else-if="isSlideFour" 
                 :is-slide-four="isSlideFour" 
                 
                 @prevSlide="prevSlide"/>
    </div>
  </template>

  <script>

  import DetailSurvey from '../Component/DetailSurvey.vue'
  import BerikanInsentif from '../Component/BerikanInsentif.vue'
  import Tayangkan from '../Component/Tayangkan.vue'
  import Build from './Build.vue'
 
  export default {
    name: 'SurveyDetails',
    components: {
      DetailSurvey,
      Build,
      BerikanInsentif,
      Tayangkan,
    },
    data() {
      return {
        isSlideOne: true,
        isSlideTwo: false,
        isSlideThree: false,
        isSlideFour: false,
        isFilledOne: false,
        isFilledTwo: false,
        isFilledThree: false,
        // surveyJson: {},
      }
    },
    watch: {
      isSlideOne: 'scrollToTop',
      isSlideTwo: 'scrollToTop',
      isSlideThree: 'scrollToTop',
      isSlideFour: 'scrollToTop'
    },
    created() {
      
      this.$store.commit('create/resetState')
    },
    
    mounted() {
         
        // this.$store.commit('create/resetState',this.currState)
        this.$store.state.create.isLoading = false
        this.$store.state.create.isUseGForm = 
        this.$store.state.create.createGform = false
        
        this.$store.dispatch('create/GET_FREE_SURVEY_QUOTA')
    },
    
    methods: {
      goTo(path) {
        if (path === 'fill') window.location.href = '/fill'
        else this.$router.push({ path: path })
      },
      scrollToTop() {
        window.scrollTo(0, 0);
      },
      // create(form) {
      //   this.surveyJson = JSON.parse(form)
      // },
      nextSlide() {
        if(this.isSlideTwo) {
          this.isSlideTwo = !this.isSlideTwo
          this.isSlideThree = !this.isSlideThree
        }else if(this.isSlideThree) {
          this.isSlideThree = !this.isSlideThree
          this.isSlideFour = !this.isSlideFour
        }else if(this.isSlideOne) {
          this.isSlideOne = !this.isSlideOne
          this.isSlideTwo = !this.isSlideTwo
        }
      },
      prevSlide() {
        if(this.isSlideTwo) {
          this.isSlideTwo = !this.isSlideTwo
          this.isSlideOne = !this.isSlideOne
        }else if(this.isSlideThree) {
          this.isSlideThree = !this.isSlideThree
          this.isSlideTwo = !this.isSlideTwo
        }else if(this.isSlideFour) {
          this.isSlideFour = !this.isSlideFour
          this.isSlideThree = !this.isSlideThree
        }
      },
      isFormOneValid(isFormValid) {
        if(isFormValid) {
          this.isFilledOne = true
        }
      },
      isFormTwoValid(isFormValid) {
        if(isFormValid) {
          this.isFilledTwo = true
        }
      },
      isFormThreeValid(isFormValid) {
        if(isFormValid) {
          this.isFilledThree = true
        }
      },
    },
  }
  </script>
  
  <style scoped>
  
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Roboto:wght@300;400;500&display=swap');
      * {
        padding: 0;
        margin: 0;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        user-select: none;
        box-sizing: border-box;
      }
      a {
      text-decoration: none;
      }

      input, textarea {
        outline: none;
      }
      .container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: 2em;
          min-width: 100%;
      }
      .survey-nav-title {
        padding-top: 2em;
        padding-left: 2em;
        display: flex;
        flex-direction: column;
        gap: 0.5em;
      }
      .survey-nav-title a {
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        color: gray;
        width: 105px;
        cursor: pointer;
      }
      .survey-nav-title h2 {
        font-family: 'Inter', sans-serif;
        font-weight: 600;
        font-size: 1.3rem;
      }
      .survey-nav {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1em;
      }
      .survey-nav-2 {
        display: flex;
        justify-content: center;
        width: 90%;
        margin: 0 auto;
        margin-top: 2em;
      }
      .survey-step {
        display: flex;
        min-height: 50px;
        justify-content: space-between;
        flex-basis: 300px;
        align-items: center;
        gap: 1em;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        white-space: nowrap;
        cursor: pointer;
      }
      .survey-step p {
        margin-right: auto;
      }
      .bg {
        background-color: #FFFFFF;
      }
      .currp {
        background-color: #5D8BD7;
      }
      .curr {
        width: 5px;
        height: 100%;
      }

      @media screen and (max-width: 1000px) {
        .survey-nav-2 {
            flex-direction: column;
            min-width: none;
        }
        .survey-step {
            flex-basis: 0;
            min-width: 250px;
        }
        
      }

  </style>


