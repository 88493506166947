const defaultState = () => {
    return {
      listFilled:[],
      listCreated:[],
      // filter:{
      //   pageNo:1,
        //   pageSize:5
      // },
      listReponses:[],
      filter: {
        pageNo: '1',
        pageSize: 5,
        pagerCount: 5,
        title: '',
        openOnly: true,
        orderBy: {
          label: 'Default',
          code: 'created_at,desc',
        },
      },
      detail: {
        countWinner: 0,
        createdAt: '',
        description: '',
        endAt: '',
        formLink: '',
        totalPages: '',
        hostID: '',
        id: 0,
        image: '',
        sponsor: '',
        participantNeeded: 0,
        reward: 0,
        title: '',
        fillerCriteria: '',
        estFillTime: 0,
        build:''
      },
      createdCount:0,
      filledCount:0,
      pointCount:0,
      onSuccess: () => {},
      onError: () => {},
      isLoading:false,
    }
  }
  
  export default defaultState
  