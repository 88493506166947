import API_SERVICE from '../../../service/api/index'

const REGISTER_USER = (params, onSuccess, onError) => {
  const apiDetail = API_SERVICE.API_URL.USERS
  return API_SERVICE.POST({
    apiUrl: apiDetail.API,
    params,
    onSuccess,
    onError,
  })
}

const LOGIN_USER = (params, onSuccess, onError) => {
  const apiDetail = API_SERVICE.API_URL.LOGIN
  return API_SERVICE.POST({
    apiUrl: apiDetail.API,
    params,
    onSuccess,
    onError,
  })
}

const  REGISTER_WITH_GOOGLE  = (params, onSuccess, onError) => {
  const apiDetail = API_SERVICE.API_URL.REGISTER_WITH_GOOGLE 
  return API_SERVICE.POST({
    apiUrl: apiDetail.API,
    params,
    onSuccess,
    onError,
  })
}


export default { REGISTER_USER, LOGIN_USER,  REGISTER_WITH_GOOGLE }
