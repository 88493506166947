import comingSoon from './ComingSoon/Route'
import create from './Create/Route'
import fill from './Fill/Route'
import edit from './Edit/Route'
import fillSurveyList from './FillSurveyList/Route'
import home from './Home/Route'
import login from './Login/Route'
import notfound from './NotFound/Route'
import profile from './Profile/Route'
import register from './Register/Route'
import respond from './Respond/Route'
import forgotPassword from './ForgotPassword/Route'
import resetPassword from './ResetPassword/Route'
import verifyEmail from './VerifyEmail/Route'

export default [
  ...comingSoon,
  ...create,
  ...fill,
  ...edit,
  ...fillSurveyList,
  ...home,
  ...login,
  ...notfound,
  ...profile,
  ...register,
  ...respond,
  ...forgotPassword,
  ...resetPassword,
  ...verifyEmail,
]
