import API_SERVICE from '../../../service/api/index'

const LOGIN_USER = (params, onSuccess, onError) => {
  const apiDetail = API_SERVICE.API_URL.LOGIN
  return API_SERVICE.POST({
    apiUrl: apiDetail.API,
    params,
    onSuccess,
    onError,
  })
}

const LOGIN_WITH_GOOGLE = (params, onSuccess, onError) => {
	const apiDetail = API_SERVICE.API_URL.LOGIN_WITH_GOOGLE
	return API_SERVICE.POST({
	  apiUrl: apiDetail.API,
	  params,
	  onSuccess,
	  onError,
	})
  }

const GET_ALL_FILLED_SURVEY = (params, onSuccess, onError) => {
  const apiDetail = API_SERVICE.API_URL.FILLED_LIST
  return API_SERVICE.GET({
    apiUrl: apiDetail.API,
    params,
    onSuccess,
    onError,
  })
}

export default { LOGIN_USER, GET_ALL_FILLED_SURVEY, LOGIN_WITH_GOOGLE };