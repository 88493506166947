<template>
  <div :class="generateNavClass()">
    <div class="nav-left flex items-center inter w-3/4 px-5">
      <router-link class="nav-link w-1/4" to="/" tabindex="-1">
        <img
          class="logo"
          :src="require('@assets/images/' + generateNavLogo())"
        />
      </router-link>
      <router-link
        :class="currRoute === '/' ? navSelectedClass : navDefaultClass"
        to="/"
      >
        <span>Beranda</span>
      </router-link>
      <router-link
        :class="
          currRoute.includes('create') ? navSelectedClass : navDefaultClass
        "
        to="/create-gform"
      >
        <span>Buat Survey</span>
      </router-link>
      <router-link
        :class="currRoute.includes('fill') ? navSelectedClass : navDefaultClass"
        to="/fill"
      >
        <span>Isi Survey</span>
      </router-link>
      <a
        target="_blank"
        :class="navDefaultClass"
        href="https://kudata.notion.site/kudata/Olah-Dataku-by-Kudata-8fdf4a8809874dcf97ce2aba9c112afd"
      >
        <span>Olah Data</span>
      </a>

      <!-- <router-link
        v-if="isAuth"
        :class="
          currRoute.includes('profile') ? navSelectedClass : navDefaultClass
        "
        to="/profile"
      >
        <span>Profil</span>
        <span class="new-badge">New</span>
      </router-link> -->
    </div>
    <div class="nav-right items-center">
      <!-- <div class="search-box mr-5">
        <input type="text" class="search-input" placeholder="Cari survey..." />
      </div> -->
      <router-link v-if="!isAuth" to="/login" class="text-only-button-link">
        <button class="text-only-button mr-3 md:mr-8 font-bold" tabindex="-1">
          <span>Masuk</span>
        </button>
      </router-link>
      <router-link v-if="!isAuth" to="/register" class="button-link">
        <button class="button mr-3 md:mr-8" tabindex="-1">
          <span>Daftar</span>
        </button>
      </router-link>
      <div v-if="isAuth" class="text-welcome">
        Halo, <strong>{{ fullName }}</strong>
      </div>
      <el-dropdown v-if="isAuth" class="profile-image mr-5">
        <div class="flex flex-row items-center">
          <img
            src="@assets/images/nav_profile.svg"
            alt="Profile"
            class="profile-image"
          />
          <unicon
            name="angle-down"
            fill="black"
            class="profile-image__drop"
          ></unicon>
        </div>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item>
              <router-link to="/profile"> Dashboard </router-link>
            </el-dropdown-item>
          </el-dropdown-menu>
          <el-dropdown-menu>
            <el-dropdown-item @click="logout">Keluar</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <el-dropdown class="burger">
        <img
          :src="require('@assets/images/' + generateNavBurger())"
          alt="Burger"
        />
        <template #dropdown>
          <el-dropdown-menu>
            <router-link to="/profile">
              <el-dropdown-item>Dashboard</el-dropdown-item>
            </router-link>
            <router-link to="/">
              <el-dropdown-item>Beranda</el-dropdown-item>
            </router-link>
            <router-link to="/create-gform">
              <el-dropdown-item>Buat Survey</el-dropdown-item>
            </router-link>
            <router-link to="/fill">
              <el-dropdown-item>Isi Survey</el-dropdown-item>
            </router-link>
            <a
              target="_blank"
              href="https://kudata.notion.site/kudata/Olah-Dataku-by-Kudata-8fdf4a8809874dcf97ce2aba9c112afd"
            >
              <el-dropdown-item>Olah Data</el-dropdown-item>
            </a>

            <el-dropdown-item v-if="isAuth" @click="logout">
              Keluar
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      scrollPosition: null,
      navDefaultClass:
        'nav-link w-1/4 font-semibold text-lg flex justify-center',
      navSelectedClass: 'nav-link w-1/4 font-bold text-lg flex justify-center',
    }
  },
  computed: {
    currRoute() {
      return this.$route.path
    },
    isAuth() {
      return (
        localStorage.getItem('user-token') && localStorage.getItem('user-email') && localStorage.getItem('user-name')
      )
    },
    fullName() {
      return localStorage.getItem('user-name').split(' ')[0]
    },
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll)
  },
  methods: {
    goTo(path) {
      if (path === 'fill') window.location.href = '/fill'
      else this.$router.push({ path: path })
    },
    logout() {
      localStorage.clear()
      this.$router.go()
    },
    updateScroll() {
      this.scrollPosition = window.scrollY
    },
    isOnTopLandingPage() {
      return (
        this.$route.path == '/' &&
        (!this.scrollPosition || this.scrollPosition == 0)
      )
    },
    generateNavClass() {
      if (this.isOnTopLandingPage()) {
        return 'nav-transparent'
      } else {
        return 'nav'
      }
    },
    generateNavLogo() {
      if (this.isOnTopLandingPage()) {
        return 'kudata_logo_horizontal_white.png'
      } else {
        return 'kudata_logo_horizontal.png'
      }
    },
    generateNavBurger() {
      if (this.isOnTopLandingPage()) {
        return 'nav_burger_white.png'
      } else {
        return 'nav_burger.png'
      }
    },
  },
}
</script>

<style scoped lang="scss">
.nav-transparent {
  position: sticky;
  top: 0;
  height: 55px;
  background: -webkit-linear-gradient(rgba(0, 0, 0, 0.55), transparent);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 998;
  transition-duration: 0.25s;

  .nav-link {
    color: $white;
    font-weight: 100;
  }

  .text-only-button-link {
    color: $white;
    font-weight: 100;
  }

  .text-only-button {
    color: $white;
  }

  .text-welcome {
    color: $white;
  }
}
.nav {
  position: sticky;
  top: 0;
  height: 55px;
  background: $white;
  box-shadow: 0 1px 2px hsla(0, 0%, 0%, 0.1), 0 1px 4px hsla(0, 0%, 0%, 0.1),
    0 2px 8px hsla(0, 0%, 0%, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 998;
  transition-duration: 0.25s;

  .nav-link {
    color: #171123;
  }

  .text-only-button-link {
    color: $primary;
  }

  .text-only-button {
    color: $primary;
  }

  .text-welcome {
    color: #000000;
  }
}
.nav-link {
  text-decoration: none;
  cursor: pointer;

  @media (max-width: 900px) {
    span {
      display: none;
    }
  }
}
.nav-left,
.nav-right {
  display: flex;
}

.logo {
  max-width: 120px;
  min-width: 40px;
  cursor: pointer;

  @media (max-width: 900px) {
    width: 100px;
  }
}

.text-welcome {
  min-width: 100px;
  text-align: end;
  margin: 2.5px 20px 0 0;

  @media (max-width: 900px) {
    margin: 2.5px 0px 0 0;
  }
}

.profile-image {
  img {
    max-width: 40px;
    margin-right: 5px;
  }
  @media (max-width: 900px) {
    display: none;
  }
}

.text-only-button-link {
  text-decoration: none;
}
.button-link {
  text-decoration: none;
  color: #fcfeff;
}
.burger {
  cursor: pointer;
  display: none;
  img {
    margin-right: 15px;
    display: none;
  }

  @media (max-width: 900px) {
    display: block;

    img {
      display: block;
      min-width: 20px;
      max-width: 30px;
    }
  }
}
.text-only-button {
  @media (max-width: 640px) {
    padding: 6px 15px;
    font-size: 14px;
  }
}
.button {
  color: white;
  background: $primary;
  padding: 6px 15px;
  border: none;
  border-radius: 5px;
  white-space: nowrap;

  @media (max-width: 640px) {
    padding: 6px 15px;
    font-size: 14px;
  }
}
.new-badge {
  display: flex;
  align-items: center;
  height: 18px;
  margin: -3px 0 0 5px;
  padding: 3px 7px 0 7px;
  border-radius: 5px;
  font-size: 10px;
  background: $warning;
  opacity: 0.9;
  color: $white;
}
</style>

<style lang="scss">
.profile-image__drop {
  @media (max-width: 900px) {
    display: none !important;
  }
}
</style>
