<template>
    <div class="container">
            <div class="survey-box-2">
                <form class="survey-detail">
                    <h3>Detail Survey</h3>
                    <div class="kriteria-responden">
                        <div class="kr-text">
                            <p for="ks">Kriteria Responden</p>
                            <p>*Pisahkan dengan enter</p>
                        </div>
                        <div class="textarea">
                            <textarea id="ks" v-model="$store.state.create.form.fillerCriteria" type="text" name="ks" cols="30" rows="10" autocomplete="off" placeholder="Contoh:
Berumur 18 tahun
Tinggal di Jakarta" maxlength="300"></textarea>
                            <p>{{ $store.state.create.form.fillerCriteria.length }} / 300</p>
                        </div>
                        <span v-if="isFilledCriteria != 0" class="warning">{{ $store.state.create.warning.fillerCriteria }}</span>
                    </div>
                    <div class="other">
                        <div class="target-responden">
                            <label for="">Target Jumlah Responden (Orang)</label>
                            <div class="input">
                                <input v-model="responsiInt" type="number" placeholder="20">
                            </div>
                            <span v-if="isFilledRespondent != 0" class="warning">{{ $store.state.create.warning.respondentTarget }}</span>
                        </div>
                        <div class="durasi">
                            <label for="">Durasi Survey (Hari)</label>
                            <div class="input">
                                <input v-model="durationInt" type="number" placeholder="7">
                            </div>
                            <span v-if="isFilledDuration != 0" class="warning">{{ $store.state.create.warning.duration }}</span>
                        </div>
                    </div>
                </form>
            </div>
        <div class="buttons">
            <button @click="prevSlide">Sebelumnya</button>
            <button @click="goToNextSlide">Selanjutnya</button>
        </div>
    </div>
</template>

<script>

export default {
    name: 'DetailSurvey',
    props: {
        isSlideTwo: Boolean,
    },
    emits: ['nextSlide', 'prevSlide', 'isFormValid'],
    computed: {
        isFilledCriteria() {
            return this.$store.state.create.warning.fillerCriteria.length
        },
        isFilledRespondent() {
            return this.$store.state.create.warning.respondentTarget.length
        },
        isFilledDuration() {
            return this.$store.state.create.warning.duration.length
        },
        durationInt :{
            get() {
                return this.$store.state.create.form.duration.toString();
            },
            set(val) {
                this.$store.state.create.form.duration = Number(val)
            }
        },
        responsiInt:{
            get() {
                return this.$store.state.create.form.respondentTarget.toString();
            },
            set(val) {
                this.$store.state.create.form.respondentTarget = Number(val)
            }
        }
    },  
    methods: {
        nextSlide(isSlideTwo, isFormValid) {
            this.$emit('nextSlide', isSlideTwo, isFormValid)
        },
        prevSlide(isSlideTwo) {
            this.$emit('prevSlide', isSlideTwo)
        },
        isFormTwoValid(isFormValid) {
            this.$emit('isFormValid', isFormValid)
        },
        validation() {
            let isFormValid = true
            if(!this.isKriteriaValid()) {
                this.$store.state.create.warning.fillerCriteria = 'Masukkan kriteria responden'
                isFormValid = false
            }else {
                this.$store.state.create.warning.fillerCriteria = ''
            }

            if(!this.isJumlahValid()) {
                this.$store.state.create.warning.respondentTarget = 'Masukkan jumlah responden'
                isFormValid = false
            } else {
                this.$store.state.create.warning.respondentTarget = ''
            }

            if(!this.isDurasiValid()) {
                this.$store.state.create.warning.duration = 'Masukkan durasi survey'
                isFormValid = false
            }else {
                this.$store.state.create.warning.duration = ''
            }
            return isFormValid
        },
        goToNextSlide() {
            let isFormValid = this.validation()
            let isSlideTwo = this.isSlideTwo
            if(isFormValid) {
                this.nextSlide(isSlideTwo)
                this.isFormTwoValid(isFormValid)
            }
        },
        isKriteriaValid() {
            return this.$store.state.create.form.fillerCriteria
        },
        isJumlahValid() {
            return this.$store.state.create.form.respondentTarget
        },
        isDurasiValid() {
            return this.$store.state.create.form.duration
        },
    },
}

</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Roboto:wght@300;400;500&display=swap');
    
    a {
      text-decoration: none;
    }

    input, textarea {
        outline: none;
    }
    .survey-box-2 {
        background-color: #FFFFFF;
        border: 1px solid rgba(12, 30, 60, 0.24);
        border-radius: 16px;
        width: 70%;
        min-width: 800px;
        margin: auto;
    }
    .survey-box-2 h3 {
        padding-left: 1.7em;
        padding-top: 1.7em;
        font-family: 'Inter', sans-serif;
        font-weight: 600;
        font-size: 1.2rem;
    }
    .survey-detail {
        display: flex;
        flex-direction: column;
    }
    .kriteria-responden {
        display: flex;
        flex-direction: column;
        gap: 0.5em;
        padding: 2em;
    }
    .kr-text {
        display: flex;
        justify-content: space-between;
        pointer-events: none;
        align-items: flex-end;
    }
    .kriteria-responden p:nth-of-type(1) {
        font-family: 'Inter', sans-serif;
        font-weight: 500;
        white-space: nowrap;
    }
    .kriteria-responden p:nth-of-type(2) {
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        font-style: italic;
        color: rgba(0, 0, 0, 0.5);
        font-size: 0.8rem;
        white-space: nowrap;
    }

    .textarea {
        position: relative;
    }
    .textarea p {
        position: absolute;
        bottom: 10px;
        right: 10px;
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        font-size: 0.8rem;
        color: rgba(0, 0, 0, 0.5);
    }
    textarea {
        resize: vertical;
        border: none;
        background-color: #EAEAEA;
        width: 100%;
        min-width: 100px;
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        border-radius: 2px;
    }
    textarea[type='text'], input[type='text'], input[type='number']  {
        padding: 1em;
        font-size: 0.8rem;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    } 

    span {
        font-size: 0.9rem;
    }
    .other {
        display: flex;
        padding: 1em 2em 1em 2em;
        gap: 2em;
        width: 100%;
        min-width: 100px;
    }
    .target-responden, .durasi {
        display: flex;
        flex-direction: column;
        gap: 0.5em;
        width: 100%;
        min-width: 100px;
    }
    .target-responden label, .durasi label {
        white-space: nowrap;
    }
    .other input {
        width: 100%;
        border: none;
        background-color: #EAEAEA;
        min-height: 32px;
        border-radius: 2px;
    }
    .other label {
        font-family: 'Inter', sans-serif;
        font-weight: 500;
        font-size: 1rem;
        width: 100%;
    }

    .buttons{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 2em;
        padding-top: 2em;
        padding-bottom: 5em;
        margin: auto;
    }
    button {
        padding: 1em 2em;
        min-width: 50px;
        border: 0.5px solid #35158C;
        border-radius: 8px;
        background-color: #35158C;
        color: #FFFFFF;
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        font-size: 1rem;
    }
    
    button:nth-child(1){
        color: black;
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        background-color: white;
    }
    input:hover, button:hover {
        cursor: pointer;
    }

    @media screen and (max-width: 1000px){
        .survey-box-2 {
            width: 85%;
            min-width: 100px;
        }
        .other {
            flex-direction: column;
        }
        .input input {
            min-width: 100px;
        }
        .kriteria-responden {
            padding: 1em;
        }
        .survey-box-2 h3 {
            padding-left: 0.8em;
            padding-top: 1.5em;
        }
        .other {
            padding: 1em;
        }
        .target-responden label, .durasi label {
            white-space: normal;
        }
    }

    @media screen and (max-width: 400px) {
        .kr-text {
            flex-direction: column;
            align-items: flex-start;
        }
        .kr-text :nth-child(2) {
            align-self: flex-end;
        }
    }

</style>
