const defaultState = () => {
  return {
    listFilled:[],
    listCreated:[],
    isLoadingAnalytics:false,
    isLoadingEdit:false,
    // filter:{
    //   pageNo:1,
	  //   pageSize:5
    // },
    downloadExcel : () =>{},
    listResponses:[],
    filter: {
      pageNo: '1',
      pageSize: 5,
      pagerCount: 5,
      title: '',
      openOnly: true,
      orderBy: {
        label: 'Default',
        code: 'created_at,desc',
      },
    },
    detail: {
      countWinner: 0,
      createdAt: '',
      description: '',
      endAt: '',
      formLink: '',
      totalPages: '',
      hostID: '',
      id: 0,
      image: '',
      sponsor: '',
      participantNeeded: 0,
      reward: 0,
      title: '',
      fillerCriteria: '',
      estFillTime: 0,
      build:''
    },
    createdCount:0,
    filledCount:0,
    pointCount:0,
    isAddtDataModalOpen: false,
    addtData: {
      isLoading: false,
      isUpdateSuccess: false,
      phoneNumber: '',
      occupation: 'Mahasiswa',
      paymentType: 'Bank',
      bankType: 'Bank BCA',
      paymentNumber: '',
    },
    addtDataError: {
      phoneNumber: '',
      paymentNumber: '',
    },
    addtDataOption: {
      occupation: [
        'Mahasiswa',
        'Pelajar SD/SMP/SMA',
        'Guru/Dosen',
        'Karyawan Swasta',
        'Pegawai Negeri Sipil',
        'Wiraswasta',
        'Pensiunan',
        'Lainnya',
        'Tidak Bekerja',
      ],
      paymentType: ['Bank', 'Gopay', 'OVO', 'ShopeePay', 'DANA'],
      bankType: [
        'Bank BCA',
        'Bank BNI',
        'Bank Mandiri',
        'Bank BRI',
        'BCA Digital',
        'Bank BNI Syariah',
        'Bank Mandiri Syariah',
        'Bank BRI Syariah',
        'Bank BCA Syariah',
        'Bank CIMB Niaga',
        'Bank OCBC NISP',
        'Bank Panin',
        'Bank Muamalat',
        'Bank Jago',
        'Digibank (DBS)',
        'Jenius (BTPN)',
        'HANA Bank (LINE Bank)',
        'Seabank',
      ],
    },
  }
}

export default defaultState
