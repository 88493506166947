import axios from 'axios'
import * as URLS from './urls'

let API_URL = URLS
const BASE_URL =
  process.env.NODE_ENV != 'development'
    ? 'https://api.kudata.id/api'
    : 'https://api.kudata.id/api'


const ADD_AUTH_HEADER = () => {
    axios.defaults.headers.common['Authorization'] =
        `Bearer ${localStorage.getItem('user-token')}` || ''
}

const EXTRACT_RESPONSE = (response) => {
    let res = PROCESS_API_RESPONSE(response)
    if (res.status == 200 || res.status == 201) {
        return res.data
    } else {
        return res
    }
}

const PROCESS_API_RESPONSE = (response) => {
    return new Promise((resolve, reject) => {
        response.then((response) => {
            if (response) {
                if (response.status == 200 || response.status == 201) {
                    resolve(response)
                }
            } else {
                reject(response)
            }
        })
    })
}

// START AXIOS PUT
const PUT = ({ apiUrl, params }) => {
        ADD_AUTH_HEADER()
        let data = JSON.parse(JSON.stringify(params))
        const response = axios
            .put(BASE_URL + apiUrl, data)
            .catch((err) => console.log(err))
        return EXTRACT_RESPONSE(response)
    }
    // END AXIOS PUT

// START AXIOS GET
const GET = ({ apiUrl, params }) => {
        ADD_AUTH_HEADER()
        let data = JSON.parse(JSON.stringify(params))
        data = { params: {...data } }
        const response = axios
            .get(BASE_URL + apiUrl, data)
            .catch((err) => console.log(err))
        return EXTRACT_RESPONSE(response)
    }
    // END AXIOS GET

// START AXIOS POST
const POST = ({ apiUrl, params }) => {
        ADD_AUTH_HEADER()
        let data = JSON.parse(JSON.stringify(params))
        let response = axios
            .post(BASE_URL + apiUrl, data)
            .catch((err) => console.log(err))
        return EXTRACT_RESPONSE(response)
    }
    // END AXIOS POST

// START AXIOS DELETE
const DELETE = ({ apiUrl, params }) => {
        ADD_AUTH_HEADER()
        let data = JSON.parse(JSON.stringify(params))
        let response = axios
            .delete(BASE_URL + apiUrl, data)
            .catch((err) => console.error(err))
        return EXTRACT_RESPONSE(response)
    }
    // END AXIOS DELETE

const API = {
    BASE_URL,
    API_URL,
    PROCESS_API_RESPONSE,
    PUT,
    GET,
    POST,
    DELETE,
}

export default API
