import API from '../Service/api'

const RESEND_EMAIL_VERIFICATION = (context) => {
  const cooldownTime = 30 // 30 seconds cooldown time
  const startTime = Date.now() // current timestamp
  const endTime = startTime + cooldownTime * 1000 // end time in milliseconds

  context.commit('SET_COOLDOWN_TIME', cooldownTime)
  context.commit('SET_IS_COOLDOWN', true)

  localStorage.setItem('cooldownTime', cooldownTime)
  localStorage.setItem('startTime', startTime)
  localStorage.setItem('endTime', endTime)
  localStorage.setItem('isCooldown', true)

  COUNTDOWN_TIMER(context)
  let params = {
    email:
      context.state.form.email.toLowerCase() === ''
        ? localStorage.getItem('user-email')
        : context.state.form.email.toLowerCase(),
  }
  API.RESEND_EMAIL_VERIFICATION(params)
    .then(() => {
      return
    })
    .catch((err) => {
      console.error(err)
    })
}

export const COUNTDOWN_TIMER = (context) => {
  // in here context.state.cooldownTime will set 1) after user click the button
  // 2) and if mutattion set the state
  if (context.state.cooldownTime > 0) {
    setTimeout(() => {
      const currentTime = Date.now()
      const endTime = parseInt(localStorage.getItem('endTime'), 10)
      const remainingTime = Math.max(
        Math.floor((endTime - currentTime) / 1000),
        0
      )

      // always set the time on mutation and local
      context.commit('SET_COOLDOWN_TIME', remainingTime)
      localStorage.setItem('cooldownTime', remainingTime)

      // always check the remaining time (endtime) because if the endtime is over
      // delete the to localstorage and set SET_IS_COOLDOWN mutation to false
      // if the end time is not over, just continue to count
      if (remainingTime > 0) {
        COUNTDOWN_TIMER(context)
      } else {
        context.commit('SET_IS_COOLDOWN', false)
        localStorage.removeItem('cooldownTime')
        localStorage.removeItem('startTime')
        localStorage.removeItem('endTime')
        localStorage.removeItem('isCooldown')
      }
    }, 1000)
  }
}

const VERIFY_EMAIL_FROM_URL = (context) => {
  context.state.loading = true
  let params = {
    token: context.state.token,
  }
  API.VERIFY_EMAIL_FROM_URL(params)
    .then((res) => {
      context.state.isSuccess = true
      context.state.loading = false
    })
    .catch((err) => {
      context.state.isSuccess = false
      context.state.loading = false
      console.error(err)
    })
  REDIRECT_TIMER(context)
}

const REDIRECT_TIMER = (context) => {
  if (context.state.redirectTime > 0) {
    setTimeout(() => {
      context.state.redirectTime -= 1
      REDIRECT_TIMER(context)
    }, 1000)
  } else {
    if (context.state.isSuccess) {
      localStorage.clear()
      window.location.href = '/login'
    } else {
      window.location.href = '/users/verify-email'
    }
  }
}

const CONTINUE_COUNTDOWN = (context) => {
  const currentTime = Date.now()
  const endTime = parseInt(localStorage.getItem('endTime'), 10)
  // get remaining time data
  const remainingTime = Math.max(Math.floor((endTime - currentTime) / 1000), 0)

  if (remainingTime > 0) {
    COUNTDOWN_TIMER(context)
  }
}

export default {
  RESEND_EMAIL_VERIFICATION,
  VERIFY_EMAIL_FROM_URL,
  CONTINUE_COUNTDOWN,
}
