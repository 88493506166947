import API from '../Service/api'

const GET_SURVEY_LIST = (context) => {
    context.state.loading = true

    let params = {
        page_no: parseInt(context.state.filter.pageNo),
        page_size: context.state.filter.pageSize,
        title: context.state.filter.title,
        open_only: context.state.filter.openOnly,
        order_by: context.state.filter.orderBy2.code,
        sort_by: context.state.filter.sortBy2.code,
    }

    API.GET_SURVEY_LIST(params)
        .then((res) => {
            context.state.surveyList = res.data.surveys
            context.state.surveyTotal = res.data.total_count
            GET_SURVEY_FILLED_STATUS(context)
            context.state.loading = false
        })
        .catch((err) => {
            console.error(err)
        })
}

const GET_SURVEY_FILLED_STATUS = (context) => {
    let filledSurvey = localStorage.getItem('filled-survey')
    if (filledSurvey) {
        filledSurvey = filledSurvey.split(',')
        let filledSurveyID = filledSurvey.map((id) => parseInt(id))
        context.state.surveyList.forEach((survey) => {
            if (filledSurveyID.includes(survey.id)) {
                survey.isFilled = true
            } else {
                survey.isFilled = false
            }
        })
    }
}

const RESET_FILTER = (context) => {
    context.state.filter = {
        pageNo: '1',
        pageSize: 8,
        pagerCount: 5,
        title: '',
        openOnly: false,
        orderBy: '',
    }
}

// TODO: Temporary solution
const GET_USER_INFO = (context) => {
    API.GET_USER_INFO({})
        .then((res) => {
            if (res.data.occupation != '' && res.data.paymentNumber != '') {
                context.state.isFilledAddData = true
            } else {
                context.state.isAddtDataModalOpen = true
                context.state.isFilledAddData = false
            }
        })
        .catch((err) => {
			console.error(err)
        })
}

const UPDATE_USER = (context) => {
    context.state.addtData.isLoading = true

    let params = {
        phone_number: context.state.addtData.phoneNumber,
        occupation: context.state.addtData.occupation,
        payment_type: context.state.addtData.paymentType,
        bank_type: context.state.addtData.bankType,
        payment_number: context.state.addtData.paymentNumber,
    }

    if (params.payment_type != 'Bank') {
        params.bank_type = ''
    }

    API.UPDATE_USER(params)
        .then((res) => {
            context.state.addtData.isUpdateSuccess = true
            context.state.isAddtDataModalOpen = false
            context.state.isFilledAddData = true
            GET_USER_INFO(context);
        })
        .catch((err) => {
            console.error(err)
			localStorage.clear();
			window.location.href = '/login'
        })

    context.state.addtData.isLoading = false
}

export default { GET_SURVEY_LIST, RESET_FILTER, GET_USER_INFO, UPDATE_USER }