import API from '../Service/api'
import { parseISOTime } from '../../../libs/util'

const LOGIN_USER = (context) => {
  context.state.loading = true
  let params = {
    email: context.state.form.email.toLowerCase(),
    password: context.state.form.password,
  }
  API.LOGIN_USER(params)
    .then((res) => {
      HANDLE_LOGIN_USER(res.data)
      GET_ALL_FILLED_SURVEY(context, res.data)
    })
    .catch(() => {
      context.state.form.warning =
        'Email / password kamu salah atau tidak terdaftar. Coba lagi'
    })
  context.state.loading = false
}

const LOGIN_WITH_GOOGLE = (context) => {
  context.state.loading = true
  let params = {
    token: context.state.token,
  }
  API.LOGIN_WITH_GOOGLE(params).then((res) => {
    HANDLE_LOGIN_SUCCESS(res.data)
    window.location.href = context.state.redirect
  })
  context.state.loading = false
}

const GET_ALL_FILLED_SURVEY = (context, loginRes) => {
  context.state.loading = true
  API.GET_ALL_FILLED_SURVEY({})
    .then((res) => {
      context.state.success = true
      HANDLE_LOGIN_SUCCESS(loginRes)
      localStorage.setItem('filled-survey', res.data.filled_survey_id)
      window.location.href = context.state.redirect
    })
    .catch((err) => {
      window.location.href = '/users/verify-email'
      console.log(err)
    })
}

const HANDLE_LOGIN_SUCCESS = (res) => {
  localStorage.setItem('user-token', res.access_token)
  localStorage.setItem('user-token-exp', parseISOTime(res.expired_at))
  localStorage.setItem('user-refresh', res.refresh_token)
  localStorage.setItem('user-refresh-exp', parseISOTime(res.refresh_expired_at))
  localStorage.setItem('user-name', res.user.fullName)
  localStorage.setItem('user-email', res.user.email)
}

const HANDLE_LOGIN_USER = (res) => {
  localStorage.setItem('verif-load-first-time', true)
  localStorage.setItem('user-token', res.access_token)
  localStorage.setItem('user-email', res.user.email)
}

export default { LOGIN_USER, LOGIN_WITH_GOOGLE }
