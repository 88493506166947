import Profile from '../Page/Profile'

const {
  constant: { MODULE_ROUTE_NAME },
} = require('../Service')

export default [
  {
    name: MODULE_ROUTE_NAME,
    path: `${MODULE_ROUTE_NAME}`,
    component: Profile,
    meta: { requireAuth: true },
  },
]
