<template>
  <div class="card flex flex-col">
    <div class="w-full flex items-center justify-center">
      <img
        class="card-img"
        :src="require('@assets/images/' + image)"
        alt="service-img"
      />
    </div>
    <div class="w-full card-title text-center">
      {{ title }}
    </div>
    <div class="w-full card-desc subtitle text-center">
      {{ desc }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    desc: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.card-container {
  @media (max-width: 992px) {
    display: flex;
    flex-direction: column;
  }

  .card {
    background: #e6f0ff;
    border-radius: 15px;
    width: 30%;
    padding: 32px;

    @media (max-width: 992px) {
      flex-direction: column;
      width: 100%;
      margin: 16px 0;
    }

    .card-img {
      width: 60px;
      height: 60px;
    }
    .card-title {
      width: 100%;
      font-size: 24px;
      font-weight: bold;
      margin: 15px 0;
    }
    .card-desc {
      width: 100%;
      font-size: 16px;
      height: 30%;
    }
  }
}
</style>
