<template>
    <div class="container">
        <form class="content">
            <h3>Berikan Insentif</h3>
            <div class="insentif">
                <label class="radio">
                    <input type="radio" value="true" name="insentif" id="insentif" v-model="checked"
                        :checked="checked == 'true'">
                    Saya ingin menawarkan insentif pada survey saya
                </label>
                <div class="penghargaan">
                    <unicon class="mr-1" name="exclamation-circle" fill="royalblue" icon-style="monochrome" />
                    <p>Sebagai penghargaan untuk responden yang telah mengisi surveymu, berikan insentif dengan E-Wallet</p>
                </div>
                <div v-show="isChecked == 'true'" class="berikanInsentif">
                    <div class="insentif-desc">
                        <div class="total-jml-insentif">
                            <p>Total Jumlah Insentif</p>
                            <el-input v-model="$store.state.create.form.reward" placeholder="Masukkan total hadiah"
                                type="number" :min="25000" :step="10000">
                                <template #prepend>Rp</template>
                            </el-input>
                        </div>
                        <div class="jumlahPemenang">
                            <p>Jumlah Pemenang</p>
                            <el-input-number v-model="$store.state.create.form.countWinner" :min="1" :max="5">
                            </el-input-number>
                            <span class="pemenang">
                                Insentif: {{ formatIDR(getRewardPerPerson()) }}/pemenang
                                <span class="warning">
                                    {{ $store.state.create.warning.minRewardPerWinner }}
                                </span>
                            </span>
                        </div>
                        <!-- <div class="flex flex-col w-full py-3">
                            <span class="flex items-center">
                            Bukti Pembayaran Insentif
                            <el-tooltip placement="top" tabindex="-1">
                                <template #content>
                                Bukti Pembayaran Insentif yang Kamu Tawarkan ke Rekening BCA
                                Digital 007222868349 a/n David Gautama
                                </template>
                                <img
                                class="ml-1 w-5"
                                src="@assets/images/icon_information.jpg"
                                alt="Info"
                                />
                            </el-tooltip>
                            </span>
                            <span class="text-xs subtitle mt-1 mb-5">
                                BCA Digital 007222868349 a/n David Gautama</span
                            >
                            <a :href="paymentLink" target="_blank" @click="isClickPayment = true">
                                <el-button type="info" round > Unggah Bukti Pembayaran</el-button>
                            </a>
                            <span v-if="isPaymentEmpty != 0" class="warning pm text-xs h-3 mt-2">{{
                                $store.state.create.warning.payment
                            }}</span>
                        </div> -->
                    </div>
                </div>
                <!-- <div class="link">
                    <label for="gform">Link Google Form </label>
                    <input type="text" placeholder="Masukan link Google Form surveymu" v-model="$store.state.create.form.formLink" :readonly="!this.checked">
                    <span v-if="isFormEmpty != 0" class="warning">{{ $store.state.create.warning.formLink }}</span>
                    <p id="gform">Contoh: https://docs.google.com/forms/d/e/ABCD/viewform?usp=sf_link</p>
                </div> -->
            </div>
            <div class="insentif">
                <label class="radio" :class="[freeSurveyQuota === 0 ? 'not-free' : '']">
                    <input type="radio" name="insentif" id="insentif" value="false" :disabled="freeSurveyQuota === 0"
                        v-model="checked" :checked="checked == false">
                    Lanjut buat survey gratis
                </label>
                <div class="penghargaan">
                    <unicon class="mr-1" name="exclamation-circle" fill="royalblue" icon-style="monochrome" />
                    <p>Kamu tetap dapat menayangkan survey tanpa insentif dengan mengumpulkan minimal {{ getFreeQuotaUnit }}
                        poin dengan mengisi survey di Kudata</p>
                </div>
                <div class="kuota-n-poin">
                    <div class="kuota">
                        <label for="kuota">Kuota Survey Gratis</label>
                        <input type="text" v-model="freeSurveyQuota" placeholder="2" readonly>
                    </div>
                    <div class="poin">
                        <label for="poin">Poin Kamu</label>
                        <input type="text" v-model="freeSurveyPoint" readonly>
                    </div>
                </div>
            </div>
        </form>
        <div class="buttons">
            <button @click="prevSlide">Sebelumnya</button>
            <button type="submit" @click="goToNextSlide">Selanjutnya</button>
        </div>
    </div>
</template>

<script>

import { formatIDR } from '@libs/util'

export default {
    name: 'BerikanInsentif',
    props: {
        isSlideThree: Boolean,
    },
    data() {
        return {
            checked: 'true',
            isClickPayment: false,
            paymentLink: 'https://docs.google.com/forms/d/e/1FAIpQLSdGdCgVccwxpp8nrVae7IYa05ZiqiqwwRc5YtEbKeEm1hzdnQ/viewform'
        }
    },

    emits: ['nextSlide', 'prevSlide', 'isFormValid'],
    computed: {
        freeSurveyQuota() {
            return this.$store.state.create.isUseGForm ? this.$store.state.create.freeGformSurveyQuota : this.$store.state.create.freeBuilderSurveyQuota
        },
        freeSurveyPoint() {
            return this.$store.state.create.freeSurveyPoint
        },
        isChecked() {
            return this.checked
        },
        isPaymentEmpty() {
            return this.$store.state.create.warning.payment.length
        },
        getFreeQuotaUnit() {
            return this.$store.state.create.isUseGForm ? 4 : 2
        },

        // isFormEmpty() {
        //     return this.$store.state.create.warning.formLink.length
        // }

    },
    mounted() {
        this.$store.state.create.form.reward = 25000;
    },
    methods: {
        formatIDR(val) {
            return formatIDR(val)
        },
        nextSlide(isSlideThree, isFormValid) {
            this.$emit('nextSlide', isSlideThree, isFormValid)
        },
        prevSlide(isSlideThree) {
            this.$emit('prevSlide', isSlideThree)
        },
        isFormThreeValid(isFormValid) {
            this.$emit('isFormValid', isFormValid)
        },
        // isFormLinkValid() {
        //     return (
        //         this.$store.state.create.form.formLink.includes(
        //         'https://docs.google.com/forms/'
        //         ) && !this.$store.state.create.form.formLink.includes('/edit')
        //     )
        // },
        isPaymentValid() {
            return this.isClickPayment
        },
        isMinRewardPerWinnerValid() {
            return this.getRewardPerPerson() >= 25000
        },
        validation() {
            let isFormValid = true
            // if (!this.isFormLinkValid()) {
            //     isFormValid = false
            //     this.$store.state.create.warning.formLink = 'Format link Google Form tidak valid'
            // }else {
            //     this.$store.state.create.warning.formLink = ''
            // }

            // if (!this.isPaymentValid() && this.checked == 'true') {
            //     isFormValid = false
            //     this.$store.state.create.warning.payment =
            //         'Jangan lupa untuk mengunggah bukti pembayaran insentif ya!'
            // } else {
            //     this.$store.state.create.warning.payment = ''
            // }

            if (!this.isMinRewardPerWinnerValid() && this.checked == 'true') {
                isFormValid = false
                this.$store.state.create.warning.minRewardPerWinner =
                    '(min. Rp.25.000/pemenang)'
            } else {
                this.$store.state.create.warning.minRewardPerWinner = ''
            }
            return isFormValid
        },
        goToNextSlide() {
            let isFormValid = this.validation()
            let isSlideThree = this.isSlideThree
            if (isFormValid) {

                this.$store.state.create.form.isFree = this.checked == 'false'

                this.nextSlide(isSlideThree, isFormValid)
                this.isFormThreeValid(isFormValid)
            }
        },
        getRewardPerPerson() {
            let reward = this.$store.state.create.form.reward
            let countWinner = this.$store.state.create.form.countWinner
            return Math.floor(reward / countWinner)
        },
    },
}
</script>

<style scoped>
a {
    text-decoration: none;
}

input,
textarea {
    outline: none;
}

.content {
    width: 70%;
    min-width: 600px;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 2em;
    background-color: #FFFFFF;
    border-radius: 16px;
    border: 1px solid rgba(52, 54, 56, 0.24);
    padding: 2em;
}

.insentif,
h3 {
    width: 100%;
    margin: auto;
}

h3 {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 1.2rem;
}

.insentif,
.penghargaan {
    padding: 1em;
}

.insentif {
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    background-color: #EFF7FF;
    border-radius: 4px;
}

.radio {
    display: flex;
    align-items: center;
    gap: 0.5em;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
}

.berikanInsentif {
    border: 1px dashed #bdbdbd;
    border-radius: 10px;
    background-color: #F8FAFC;
}

.insentif-desc {
    width: 95%;
    padding: 1em;
    display: flex;
    flex-direction: column;
    gap: 1.5em;
}

.insentif-desc button {
    background-color: #5d8bd7;
}

.insentif-desc button:hover {
    background-color: #84aae6;
    transition: .2s;
}

.jumlahPemenang,
.total-jml-insentif {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}

.pemenang {
    font-size: 0.9rem;
    color: #777;
}

.not-free {
    color: rgba(0, 0, 0, 0.5);
}

input[type='checkbox'] {
    box-sizing: border-box;
    background: white;
    border: none;
    width: 16px;
    height: 16px;
    border-radius: 4px;
}

.penghargaan {
    display: flex;
    background-color: #DFEBFF;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    overflow: hidden;
    font-size: 1rem;
    align-items: center;
    gap: 0.5em;
}

.link {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

label {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 0.9rem;
}

input[type='text'] {
    padding: 1em;
    border-radius: 2px;
    border: none;
    font-size: 0.8rem;
}

.atau {
    margin: auto;
    width: 100%;
    height: 10px;
    border-bottom: 1px solid gray;
    text-align: center;
}

.atau span {
    font-size: 1rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: gray;
    background-color: #FFFFFF;
    padding: 0 0.5em;
}

#gform {
    word-wrap: break-word;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    font-size: 0.8rem;
}

.kuota-n-poin {
    display: flex;
    gap: 1em;
    width: 100%;
}

.kuota,
.poin {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    width: 50%;
    min-width: 100px;
}

.kuota input,
.poin input {
    color: rgba(0, 0, 0, 0.5);
}

.buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2em;
    padding-top: 2em;
    padding-bottom: 5em;
    margin: auto;
}

.buttons button {
    padding: 1em 2em;
    min-width: 50px;
    border: 0.5px solid #35158C;
    border-radius: 8px;
    background-color: #35158C;
    color: #FFFFFF;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 1rem;
}

.buttons button:nth-child(1) {
    color: black;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    background-color: white;
}

input:hover,
button:hover {
    cursor: pointer;
}

.warning {
    font-size: 0.9rem;
}

@media screen and (max-width: 730px) {
    .kuota-n-poin {
        flex-direction: column;
    }

    .kuota,
    .poin {
        width: 100%;
    }

    .content {
        padding: 2em 1em;
        width: 85%;
        min-width: 100px;
    }

    .pm {
        padding-bottom: 2em;
    }
}
</style>