<template>
  <div
    class="flex relative flex-col items-center justify-center mt-24 mb-36 mx-6"
  >
    <img
      class="absolute top-0"
      src="@assets/images/quirky-letter-with-a-checkmark.svg"
      alt="quirky-letter-with-a-checkmark"
    />
    <div
      class="relative border bg-white rounded-md p-6 z-50 mt-48 font-inter text-center"
      style="max-width: 420px"
    >
      <h1 class="font-semibold text-2xl">Silakan Verifikasi Email anda</h1>
      <p class="helptext mt-8">
        Anda hampir selesai! Kami telah mengirim email ke
      </p>
      <h2 class="text-xl font-semibold">
        {{ $store.state.verifyEmail.form.email }}
      </h2>
      <p class="helptext my-6">
        Klik tautan dalam email tersebut untuk menyelesaikan pendaftaran. Jika
        tidak melihatnya, Anda mungkin perlu
        <span class="font-semibold">memeriksa folder spam.</span>
      </p>
      <p class="helptext">Masih tidak menemukan emailnya? Tenang saja.</p>
      <el-button
        :loading="$store.state.verifyEmail.isCooldown"
        class="w-full my-4"
        type="primary"
        @click="resendEmailVerification"
        >Kirim Kode
        <span v-if="$store.state.verifyEmail.isCooldown"
          >- {{ formattedTime }}</span
        ></el-button
      >
      <a class="font-medium text-sm" href="/">Butuh bantuan? Hubungi Kami</a>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    formattedTime() {
      return this.formatTime(this.$store.state.verifyEmail.cooldownTime)
    },
  },
  mounted() {
    // check the localstorage, for the first time
    const verifLoadFirstTime =
      localStorage.getItem('verif-load-first-time') === 'true'
    console.log(verifLoadFirstTime)
    if (verifLoadFirstTime) {
      this.$store.dispatch('verifyEmail/RESEND_EMAIL_VERIFICATION')
      localStorage.setItem('verif-load-first-time', false)
    }

    // get data cooldowntime,endtime, iscooldown form local
    const cooldownTime = parseInt(localStorage.getItem('cooldownTime'), 10)
    const endTime = parseInt(localStorage.getItem('endTime'), 10)
    const isCooldown = localStorage.getItem('isCooldown') === 'true'

    // if iscooldown is tru and have a time
    if (isCooldown && cooldownTime > 0) {
      // get remaining time for endtime
      const remainingTime = Math.max(
        Math.floor((endTime - Date.now()) / 1000),
        0
      )
      // mechanism for managing data state
      // should to set remainign time (endtime) and set cooldown time to true
      // after refresh page
      this.$store.commit('verifyEmail/SET_COOLDOWN_TIME', remainingTime)
      this.$store.commit('verifyEmail/SET_IS_COOLDOWN', remainingTime > 0)

      // if remaining time still on. continue to count
      if (remainingTime > 0) {
        this.$store.dispatch('verifyEmail/CONTINUE_COUNTDOWN')
      }
    }
  },
  methods: {
    resendEmailVerification() {
      this.$store.dispatch('verifyEmail/RESEND_EMAIL_VERIFICATION')
    },
    formatTime(seconds) {
      const minutes = Math.floor(seconds / 60)
      const remainingSeconds = seconds % 60
      return `${String(minutes).padStart(2, '0')}:${String(
        remainingSeconds
      ).padStart(2, '0')}`
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../Style//main.scss';
</style>
