<template>
  <div class="modal">
    <div class="modal-box" @click.stop>
      <div class="modal-row text-xs md:text-sm">
        <div class="modal-title">
          <span>Perbaruan Data Pengguna</span>
        </div>
      </div>
      
      <div class="modal-content">
        <!-- START PHONE NUMBER -->
        <div class="modal-row__input text-xs md:text-sm my-3">
          <div class="modal-row">
            <span class="modal-row__label"> Nomor HP </span>
            <span class="modal-row__label-error">
              {{ $store.state.profile.addtDataError.phoneNumber }}
            </span>
          </div>
          <div class="modal-row">
            <unicon name="phone" fill="gray"></unicon>
            <el-input
              v-model="$store.state.profile.addtData.phoneNumber"
              placeholder="Contoh: 08123456789"
              class="ml-3"
            >
            </el-input>
          </div>
        </div>
        <!-- END PHONE NUMBER -->

        <div class="modal-row__input text-xs md:text-sm my-3">
          <div class="modal-row">
            <span class="modal-row__label">Pekerjaan</span>
          </div>
          <div class="modal-row">
            <unicon name="briefcase-alt" fill="gray"></unicon>
            <v-select
              v-model="$store.state.profile.addtData.occupation"
              :clearable="false"
              :options="$store.state.profile.addtDataOption.occupation"
            ></v-select>
          </div>
        </div>
        <div class="modal-row text-xs md:text-sm mt-5">
          <div><span>Data Penerimaan Insentif</span></div>
        </div>
        <div class="modal-row__input text-xs md:text-sm my-3">
          <div class="modal-row">
            <span class="modal-row__label">Metode Pembayaran</span>
          </div>
          <div class="modal-row">
            <unicon name="money-bill" fill="gray"></unicon>
            <v-select
              v-model="$store.state.profile.addtData.paymentType"
              :clearable="false"
              :options="$store.state.profile.addtDataOption.paymentType"
            ></v-select>
          </div>
        </div>
        <div
          v-if="isPaymentTypeBank()"
          class="modal-row__input text-xs md:text-sm my-3"
        >
          <div class="modal-row">
            <span class="modal-row__label">Jenis Bank</span>
          </div>
          <div class="modal-row">
            <unicon name="transaction" fill="gray"></unicon>
            <v-select
              v-model="$store.state.profile.addtData.bankType"
              :clearable="false"
              :options="$store.state.profile.addtDataOption.bankType"
            ></v-select>
          </div>
        </div>
        <div class="modal-row__input text-xs md:text-sm my-3">
          <div class="modal-row">
            <span class="modal-row__label">
              {{ generatePaymentNumberText() }}
            </span>
            <span class="modal-row__label-error">
              {{ $store.state.profile.addtDataError.paymentNumber }}
            </span>
          </div>
          <div class="modal-row">
            <unicon name="dialpad-alt" fill="gray"></unicon>
            <el-input
              v-model="$store.state.profile.addtData.paymentNumber"
              class="ml-3"
            >
            </el-input>
          </div>
        </div>
        <div class="modal-row text-xs md:text-sm my-5">
          <div class="divider"></div>
        </div>
        <div class="modal-row text-xs md:text-sm">
          <button class="btn-submit" @click="onSubmit">Submit</button>
          </div>
          <div class="text-xs md:text-sm my-3 mx-auto">
          <button class="btn-cancel" @click="onClose">Cancel</button>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    filter() {
      return this.$store.state.profile.filter
    },
    filterOption() {
      return this.$store.state.profile.filterOption
    },
  },
  mounted(){
    this.$store.dispatch('profile/GET_USER_INFO')
  },
  methods: {
    closeDialog() {
      this.$store.state.profile.isAddtDataModalOpen = false
    },
    onClose() {
      this.closeDialog()
    },
    onReset() {
      window.location.reload()
    },
    onSubmit() {
      if (this.isFormValid()) {
        this.$store.dispatch('profile/UPDATE_USER')
        this.$store.dispatch('profile/GET_USER_INFO')

      }
    },
    isFormValid() {
      let valid = true
      if (!this.isPhoneNumberValid()) {
        valid = false
        this.$store.state.profile.addtDataError.phoneNumber =
          'Nomor HP tidak valid'
      } else {
        this.$store.state.profile.addtDataError.phoneNumber = ''
      }
      if (!this.isPaymentNumberValid()) {
        valid = false
        this.$store.state.profile.addtDataError.paymentNumber =
          'Nomor pembayaran tidak valid'
      } else {
        this.$store.state.profile.addtDataError.paymentNumber = ''
      }
      return valid
    },
    isPaymentTypeBank() {
      return this.$store.state.profile.addtData.paymentType === 'Bank'
    },
    isPhoneNumberValid() {
      return (
        this.$store.state.profile.addtData.phoneNumber?.includes('08') &&
        (this.$store.state.profile.addtData.phoneNumber.length > 10 ||
          this.$store.state.profile.addtData.phoneNumber.length < 17)
      )
    },
    isPaymentNumberValid() {
      return this.$store.state.profile.addtData.paymentNumber.length > 5
    },
    generatePaymentNumberText() {
      if (this.isPaymentTypeBank()) {
        return 'Nomor Rekening Bank'
      } else {
        return 'Nomor ' + this.$store.state.profile.addtData.paymentType
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 25px 0 0 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
  transition: all 0.3s;

  .modal-box {
    width: 40%;
    height: 97%;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 10px;
    padding: 25px;
    transition: all 0.3s;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
    overflow: scroll;

    @media (max-width: 640px) {
      width: 95%;
    }
  }

  .modal-title {
    margin-bottom: 5px;
    margin-right: auto;

    span {
      font-size: 1.5rem;
      font-weight: 900 !important;
    }
  }

  .modal-subtitle__red {
    margin-bottom: 15px;

    span {
      font-size: 0.7rem;
      font-style: italic;
      color: $warning;
    }
  }

  .modal-close {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 20px;
    cursor: pointer;
  }

  .modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  .modal-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0;

    .modal-row__label {
      color: $grey;
      font-size: 12px;
    }

    .modal-row__label-error {
      color: $warning;
      font-size: 12px;
      margin-left: 10px;
    }
  }

  .modal-row__input {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    span {
      margin-bottom: 10px;
    }
  }

  .modal-row__error {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    span {
      color: $warning;
      font-size: 0.7rem;
    }
  }

  .btn-submit {
    width: 100%;
    height: 35px;
    border-radius: 5px;
    border: none;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s;
    background: $success;
    color: $white;
    &:hover {
      background: $success-light;
    }
  }

  .divider {
    width: 100%;
    height: 0.5px;
    background-color: rgb(235, 235, 235);
  }
}
</style>

<style lang="scss">
.modal {
  .v-select {
    width: 100%;
    margin-left: 10px;
  }
}
</style>
