import API_SERVICE from '../../../service/api/index'

const GET_SURVEY_DETAIL = (params, onSuccess, onError) => {
  const apiDetail = API_SERVICE.API_URL.SURVEYS
  const apiUrl = `${apiDetail.API}/${params.id}`
  return API_SERVICE.GET({
    apiUrl: apiUrl,
    params: {},
    onSuccess,
    onError,
  })
}
const GET_SURVEYJS_DETAIL = (params, onSuccess, onError) => {
  const apiDetail = API_SERVICE.API_URL.SURVEYS_JS
  return API_SERVICE.GET({
    apiUrl: apiDetail.API,
    params,
    onSuccess,
    onError,
  })
}
const GET_SURVEY_WINNER = (params, onSuccess, onError) => {
  const apiDetail = API_SERVICE.API_URL.WINNERS
  const apiUrl = `${apiDetail.API}/${params.id}`
  return API_SERVICE.GET({
    apiUrl: apiUrl,
    params: {},
    onSuccess,
    onError,
  })
}

const FILL_SURVEY = (params, onSuccess, onError) => {
  const apiDetail = API_SERVICE.API_URL.FILLERS
  return API_SERVICE.POST({
    apiUrl: apiDetail.API,
    params,
    onSuccess,
    onError,
  })
}

const GET_ALL_FILLED_SURVEY = (params, onSuccess, onError) => {
  const apiDetail = API_SERVICE.API_URL.FILLED_LIST
  return API_SERVICE.GET({
    apiUrl: apiDetail.API,
    params,
    onSuccess,
    onError,
  })
}

export default {
  GET_SURVEY_DETAIL,
  GET_SURVEY_WINNER,
  FILL_SURVEY,
  GET_SURVEYJS_DETAIL,
  GET_ALL_FILLED_SURVEY,
}
