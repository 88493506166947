export const LOGIN = {
  API: '/users/login',
}

export const LOGIN_WITH_GOOGLE = {
  API: '/users/login-google',
}

export const REGISTER_WITH_GOOGLE = {
  API: '/users/register-google',
}

export const REFRESH = {
  API: '/users/refresh',
}

export const QUOTA = {
  API: '/users/free-survey-quota',
}

export const USERS = {
  API: '/users',
}

export const USER_INFO = {
  API: '/users/me',
}

export const SURVEYS = {
  API: '/surveys',
}

export const SURVEYS_JS = {
  API: '/surveys-js',
}

export const FILLERS = {
  API: '/fillers',
}

export const RESPONSES = {
  API: '/responses',
}

export const FILLED_LIST = {
  API: '/fillers/all',
}

export const USER_FILLED = {
  API: '/users/filled',
}

export const USER_CREATED = {
  API: '/users/created',
}

export const WINNERS = {
  API: '/winners',
}
export const DASHBOARD_PROFILE = {
  API:'/users/profile'
}

export const UPDATE_SURVEYS_JS = {
  API:'/surveys-js/updated'
}

export const GENERATE_SECRET_EMAIL_URL = {
  API:'/users/generate-secret'
}

export const VALIDATE_SECRET_EMAIL_URL = {
	API:'/users/validate-secret-url'
  }

export const RESET_PASSWORD = {
	API:'/users/reset-password'
  }

export const SCRAPPE_SURVEY_FORM = {
	API:'/surveys/scrapper'
}

export const RESEND_EMAIL_VERIFICATION = {
	API: '/users/resend-email-verification'
}

export const VERIFY_EMAIL_FROM_URL = {
	API: '/users/verify-email'
}