import jQuery from 'jquery'
import lodashCloneDeep from 'lodash/cloneDeep'

const getTextValue = function (text) {
  if (text === undefined || text === null || String(text).trim() === '') {
    return '-'
  }
  return String(text).trim()
}

const getCurrencyValue = function (text, currency) {
  if (text === undefined || text === null) {
    text = 0
  }
  if (currency === undefined || text === null) {
    currency = ''
  } else {
    currency = currency + ' '
  }
  text = Math.round(text * 100) / 100
  let splitted = text.toString().split('.')
  let decimal = ''
  if (splitted.length > 1) {
    decimal = '.' + splitted[1]
    text = splitted[0]
  }
  return (
    currency + text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + decimal
  )
}

const isEmpty = function (object) {
  return (
    object === undefined ||
    object === null ||
    object === {} ||
    object === [] ||
    object === '' ||
    object.length === 0
  )
}

const isNotEmpty = function (object) {
  return !isEmpty(object)
}

const cloneObject = function (object) {
  if (object === undefined || object === null) {
    return {}
  }
  return JSON.parse(JSON.stringify(object))
}

const scrollTop = function () {
  window.$ = jQuery
  $('html,body').animate(
    {
      scrollTop: 0,
    },
    300
  )
}

const scrollToElement = function (element, marginTop) {
  let headerHeight = 50 + (marginTop === undefined ? 0 : marginTop)
  window.$ = jQuery
  $('html,body').animate(
    {
      scrollTop: $(element).offset().top - headerHeight,
    },
    300
  )
}

const toTitleCase = function (str) {
  if (str !== null) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
  } else {
    return ''
  }
}

const getDistanceText = function (distanceInMeters) {
  if (!distanceInMeters) {
    distanceInMeters = 0
  }
  distanceInMeters = Math.round(distanceInMeters)
  if (distanceInMeters < 1000) {
    return distanceInMeters + ' m'
  }
  let mainNumber = parseInt(distanceInMeters / 1000)
  let restNumber = (distanceInMeters % 1000) / 1000
  restNumber = Math.round(restNumber * 100) / 100
  return mainNumber + restNumber + ' km'
}

const getStraightDistance = function (orgLat, orgLng, dstLat, dstLng) {
  let R = 6371 // Radius of the earth in km
  let dLat = (dstLat - orgLat) * (Math.PI / 180)
  let dLon = (dstLng - orgLng) * (Math.PI / 180)
  let a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(orgLat * (Math.PI / 180)) *
      Math.cos(dstLat * (Math.PI / 180)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  let d = R * c * 1000 // Distance in meters
  return d
}

const getDarkerColor = (col) => {
  const amt = -35
  let usePound = false

  if (col[0] == '#') {
    col = col.slice(1)
    usePound = true
  }

  var num = parseInt(col, 16)

  var r = (num >> 16) + amt
  if (r > 255) r = 255
  else if (r < 0) r = 0

  var b = ((num >> 8) & 0x00ff) + amt
  if (b > 255) b = 255
  else if (b < 0) b = 0

  var g = (num & 0x0000ff) + amt
  if (g > 255) g = 255
  else if (g < 0) g = 0

  var result = (g | (b << 8) | (r << 16)).toString(16)
  var totalChar = result.length
  var zeroPadding = 6 - totalChar
  result = '0'.repeat(zeroPadding) + result

  return (usePound ? '#' : '') + result
}

const getCustomDarkerColor = function (col, amt) {
  let usePound = false

  if (col[0] == '#') {
    col = col.slice(1)
    usePound = true
  }

  var num = parseInt(col, 16)

  var r = (num >> 16) + amt
  if (r > 255) r = 255
  else if (r < 0) r = 0

  var b = ((num >> 8) & 0x00ff) + amt
  if (b > 255) b = 255
  else if (b < 0) b = 0

  var g = (num & 0x0000ff) + amt
  if (g > 255) g = 255
  else if (g < 0) g = 0

  var result = (g | (b << 8) | (r << 16)).toString(16)
  var totalChar = result.length
  var zeroPadding = 6 - totalChar
  result = '0'.repeat(zeroPadding) + result

  return (usePound ? '#' : '') + result
}

const removeAllElementsOfArray = function (array) {
  if (Array.isArray(array)) {
    array.splice(0, array.length)
  }
}

const objectValueToString = function (object) {
  delete object.totalPage
  delete object.totalRecord

  Object.keys(object).map((key) => {
    if (!object[key] || object[key].length == 0) {
      delete object[key]
    } else {
      object[key] = String(object[key])
    }
  })

  let data = Object.assign({}, object)
  return data
}

const commaSeparatedJoin = function (attribute) {
  if (attribute && attribute.length > 0) {
    return attribute
      .map((item) => {
        return item.code
      })
      .join()
  } else {
    return null
  }
}

const formatNumber = (value, decimal) => {
  if (typeof value == 'number') {
    return value.toFixed(decimal).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  } else {
    return '-'
  }
}

const displayNumber = (value, decimal) => {
  if (typeof value == 'number') {
    return value
      .toFixed(parseInt(value) === value ? 0 : 2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  } else {
    return '0'
  }
}

const cloneWithStringValues = (object, excludeBlank) => {
  let newObject = {}
  for (let key in object) {
    if (excludeBlank && !object[key]) {
      continue
    }
    newObject[key] = String(object[key])
  }
  return newObject
}

const secondToHms = (second, format) => {
  second = Number(second)
  format = format || 'hms'
  let h = Math.floor(second / 3600)
  let m = Math.floor((second % 3600) / 60)
  let s = Math.floor((second % 3600) % 60)

  let hDisplay = h > 0 ? h + ' hr ' : ''
  let mDisplay = m > 0 ? m + ' m ' : ''
  let sDisplay = s > 0 ? s + ' s ' : ''
  let display = ''

  if (second > 0) {
    if (format == 'hms') display = hDisplay + mDisplay + sDisplay
    else if (format == 'hm') display = hDisplay + mDisplay
    else if (format == 'h') display = hDisplay
  } else {
    display = second
  }

  return display
}

const cloneDeep = (data) => {
  return lodashCloneDeep(data)
}

const withThousandSeparator = (data) => {
  if (!data) {
    return data
  } else {
    return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  }
}

const formatIDR = (data) => {
  if (!data) {
    return data
  } else {
    return 'Rp' + withThousandSeparator(data)
  }
}

const isEmail = function (email) {
  const expr =
    /(^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/
  return expr.test(email)
}

const isValidJSONString = (str) => {
  if (typeof str !== 'string' || !isNaN(str)) {
    return false
  }
  try {
    return JSON.parse(str)
  } catch (e) {
    return false
  }
}

const parseISOTime = (s) => {
  s = `${s.slice(0, 21)} GMT+0000`
  // Get the parts of the timestamp
  let [y, m, d, H, M, S, ms, offset] = s.match(/\d+|a-z+|[+-]\d{4}$/g)
  // Get the offset sign
  let offSign = offset.substr(0, 1)
  // Convert offset to minutes
  let offMins =
    (offset.substr(1, 2) * 60 + +offset.substr(3, 2)) *
    (offSign == '+' ? 1 : -1)
  // Create a Date, adjusted by the offset
  return new Date(Date.UTC(y, m - 1, d, H, M - offMins, S, ms))
}

export {
  isEmail,
  getStraightDistance,
  getTextValue,
  getCurrencyValue,
  isEmpty,
  isNotEmpty,
  withThousandSeparator,
  formatIDR,
  scrollTop,
  scrollToElement,
  cloneObject,
  toTitleCase,
  getDistanceText,
  getDarkerColor,
  getCustomDarkerColor,
  objectValueToString,
  commaSeparatedJoin,
  formatNumber,
  displayNumber,
  removeAllElementsOfArray,
  cloneWithStringValues,
  secondToHms,
  cloneDeep,
  isValidJSONString,
  parseISOTime,
}
